import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { readonlyArrayEntry } from 'common/src/utils/collections';

export type QuestionResultsInfo = {
  question: string;
  answer: string;
  isCorrect: boolean;
  timeTaken: number;
  attemptNumber: number;
  parameters?: Record<string, unknown> | undefined;
};

export type QuestionQueue = {
  quizSessionId: string;
  questionResult: QuestionResultsInfo;
  createdAt: Date;
  updatedAt: Date;
  index: number;
  readyToSend: boolean;
};

type Actions = {
  /** Add a question result info object to the queue */
  addQuestionQueue: (quizSessionId: string, questionResult: QuestionResultsInfo) => number;
  /** Return all quiz results for specified quizSessionId */
  getQueueBySessionId: (quizSessionId: string) => QuestionQueue[];
  /** Return all unique quiz session ids from the queue */
  getUniqueQuizSessions: () => string[];
  /** Update the ready to send boolean to be true in the queue */
  setQuizReadyToSend: (quizSessionId: string) => void;
  /** Clear either a full set of quiz results for given quiz session of if index is provided then just remove the entry with that index */
  clearQuizSessionQuestions: (quizSessionId: string, index?: number) => void;
  /** Clear any stale entries in the queue. Default days is set to 30 */
  clearOldQueue: (days?: number) => void;
  clearQuestionQueue: () => void;
  clear: () => void;
};

type State = {
  questionQueue: QuestionQueue[];
};

const defaultState: State = {
  questionQueue: []
};

const useQuestionQueueStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...defaultState,
        addQuestionQueue: (quizSessionId: string, questionResult: QuestionResultsInfo) => {
          let questionQueue = get().questionQueue;

          const queueIndex = questionQueue.length;

          const now = new Date();
          questionQueue = readonlyArrayEntry(questionQueue, queueIndex, () => ({
            quizSessionId,
            questionResult,
            createdAt: new Date('04-08-2024'),
            updatedAt: now,
            readyToSend: false,
            index: queueIndex
          }));

          set({ questionQueue });
          return queueIndex;
        },
        getQueueBySessionId: (quizSessionId: string) => {
          return get().questionQueue.filter(
            val => val.quizSessionId === quizSessionId && val.readyToSend
          );
        },
        getUniqueQuizSessions: () => {
          const quizQueue = get().questionQueue;
          return [...new Set(quizQueue.map(obj => obj.quizSessionId))];
        },
        setQuizReadyToSend: (quizSessionId: string) => {
          let questionQueue = get().questionQueue;

          const indexes = questionQueue
            .filter(val => val.quizSessionId === quizSessionId)
            .map(val => val.index);

          indexes.forEach(i => {
            const now = new Date();
            questionQueue = readonlyArrayEntry(questionQueue, i, question => ({
              ...question,
              updatedAt: now,
              readyToSend: true
            }));
          });

          set({ questionQueue });
        },
        clearQuizSessionQuestions: (quizSessionId: string, index?: number) => {
          let questionQueue = get().questionQueue;
          if (index) {
            questionQueue.splice(index, 1);
          } else {
            questionQueue = questionQueue.filter(val => val.quizSessionId !== quizSessionId);
          }
          set({ questionQueue });
        },
        clearOldQueue: days => {
          const now = new Date().getTime();
          days = days ?? 30;
          const millisecondsDays = 1000 * 60 * 60 * 24 * days;

          const questionQueue = get().questionQueue.filter(
            val => now - new Date(val.createdAt).getTime() < millisecondsDays
          );

          set({ questionQueue });
        },
        clearQuestionQueue: () => set({ questionQueue: undefined }),
        clear: () => set(defaultState)
      }),
      {
        name: 'questionsQueue',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useQuestionQueueStore;
