import { View } from 'react-native';
import { filledArray, range } from '../../../utils/collections';
import Table from '../../molecules/Table';
import { AssetSvg } from '../../../assets/svg';

const counterSvg = {
  ones: {
    counter: <AssetSvg name="Place_value/1" width={70} height={70} />,
    grey: <AssetSvg name="Place_value/1_grey" width={70} height={70} />
  },
  tenths: {
    counter: <AssetSvg name="Place_value/0.1" width={70} height={70} />,
    grey: <AssetSvg name="Place_value/0.1_grey" width={70} height={70} />
  },
  tenths_frac: {
    counter: <AssetSvg name="Place_value/1-10" width={70} height={70} />,
    grey: <AssetSvg name="Place_value/0.1_grey" width={70} height={70} />
  },
  hundreds: {
    counter: <AssetSvg name="Place_value/100" width={70} height={70} />,
    grey: <AssetSvg name="Place_value/GreyCounter" width={70} height={70} />
  },
  counter: {
    counter: <AssetSvg name="CounterRed" width={70} height={70} />,
    grey: <AssetSvg name="Place_value/GreyCounter" width={70} height={70} />
  }
};

export type PlaceValue = 'ones' | 'tenths' | 'tenths_frac' | 'hundreds' | 'counter';

/** Create the counters in the frame */
const tenFrameWithCounters = (
  counters: number,
  counterType: PlaceValue,
  emptyGreys: boolean
): JSX.Element[][] => {
  const flatTable = filledArray(
    <View style={{ width: 70, height: 70, paddingTop: 2 }}>{counterSvg[counterType].counter}</View>,
    counters
  ).concat(
    filledArray(
      emptyGreys ? (
        <View style={{ width: 70, height: 70, paddingTop: 2 }}>{counterSvg[counterType].grey}</View>
      ) : (
        <View style={{ width: 70, height: 70 }} />
      ),
      10 - counters
    )
  );

  return [flatTable.slice(0, 5), flatTable.slice(5, 10)];
};

/** Find how many frames and counters go in each frame */
const tenFrames = (counters: number): number[] =>
  range(1, Math.ceil(counters / 10)).map((_, i) => {
    const diff = 10 * (i + 1) - counters;
    if (diff <= 0) {
      return 10;
    } else {
      return 10 - diff;
    }
  });

type Props = {
  /** Number of counters that should be in the ten frame */
  numberOfCounters: number;
  /** Place value type of counters */
  placeValue: PlaceValue;
  /** If the place value grey counters should be used in empty cells, defaults as empty cells */
  emptyGreys?: boolean;
  /** Optional flex direction */
  flexDirection?: 'column' | 'row';
};

/**
 * Returns a table of a counters in a ten frame.
 */
const TenFrames = ({
  numberOfCounters,
  placeValue,
  emptyGreys = false,
  flexDirection = 'column'
}: Props): JSX.Element => {
  return (
    <View style={{ flexDirection: flexDirection, gap: 20 }}>
      {tenFrames(numberOfCounters).map((frame, i) => (
        <Table key={i} items={tenFrameWithCounters(frame, placeValue, emptyGreys)} />
      ))}
    </View>
  );
};

export default TenFrames;
