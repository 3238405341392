import { Image } from 'expo-image';
import Text from 'common/src/components/typography/Text';
import { colors } from 'common/src/theme/colors';
import { View } from 'react-native';
import { StudentQuestion } from '../utils/studentData';
import { ProgressBar } from './ProgressBar';
import { QuizDetails } from './QuizDetails';

/**
 * Quiz Tile to display information about an assigned quiz
 */
export const QuizTile = ({ item }: { item: StudentQuestion }) => {
  // Some of the test data returns progress > 100% so I have capped it at 100
  // I have raised with back end and they think its just poor data and would not expect this to happen in prod
  const progress = Math.min((item.totalQuestionsAnswered / item.numberOfQuestions) * 100, 100);

  return (
    <>
      <View
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <View style={{ padding: 20, paddingBottom: 0 }}>
          <Image
            source={require('pupil-app/assets/svg/AppLogo.svg')}
            style={{ width: 200, height: 200 }}
          />
        </View>
        <QuizDetails
          inProgress={progress > 0}
          quizStars={item.totalStars}
          totalStars={item.numberOfQuestions * 3}
          isInfinite={item.randomiseQuestionParameters}
        />
        <ProgressBar progress={progress} />
      </View>

      <Text
        variant="WRN400"
        style={{
          color: colors.white,
          fontSize: 22,
          fontWeight: '700',
          lineHeight: 33,
          paddingTop: 12
        }}
      >
        {item.name}
      </Text>
    </>
  );
};
