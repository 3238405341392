import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';
import { Name } from './names';
import { SvgName } from '../assets/svg';

export const objectNames = ['Apple', 'Cookie', 'Egg', 'Flower', 'Pencil'] as const;

export const objectSchema = z.enum(objectNames);

export function getRandomObject() {
  return getRandomFromArray(objectNames);
}

export type ObjectName = (typeof objectNames)[number];

export const isObject = (x: string): x is ObjectName => objectNames.includes(x as ObjectName);

export const objectAsWord = (
  object: ObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Apple':
      return isPlural ? translate.objects.Apples() : translate.objects.Apple();
    case 'Cookie':
      return isPlural ? translate.objects.Cookies() : translate.objects.Cookie();
    case 'Egg':
      return isPlural ? translate.objects.Eggs() : translate.objects.Egg();
    case 'Flower':
      return isPlural ? translate.objects.Flowers() : translate.objects.Flower();
    case 'Pencil':
      return isPlural ? translate.objects.Pencils() : translate.objects.Pencil();
  }
};

export const objectPrepositionWord = (
  object: ObjectName,
  translate: TranslationFunctions,
  movementPreposition = false
) => {
  switch (object) {
    case 'Apple':
    case 'Egg':
    case 'Flower':
    case 'Pencil':
      return movementPreposition ? translate.prepositions.into() : translate.prepositions.in();
    case 'Cookie':
      return movementPreposition ? translate.prepositions.onto() : translate.prepositions.on();
  }
};

export const containerOfObject = (
  object: ObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Apple':
      return isPlural ? translate.objects.Bags() : translate.objects.Bag();
    case 'Cookie':
      return isPlural ? translate.objects.Plates() : translate.objects.Plate();
    case 'Egg':
      return isPlural ? translate.objects.Boxes() : translate.objects.Box();
    case 'Flower':
      return isPlural ? translate.objects.Vases() : translate.objects.Vase();
    case 'Pencil':
      return isPlural ? translate.objects.Pots() : translate.objects.Pot();
  }
};

export const objectSentenceMaker = (
  name: Name,
  object: ObjectName,
  translate: TranslationFunctions,
  numGroups: number,
  numObjPerGroup: number
): [string, string, string] => {
  switch (object) {
    case 'Apple':
      return [
        translate.answerSentences.characterHasNumBags({ name, numGroups }),
        translate.answerSentences.characterPutsNumApples({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Apples())
      ];
    case 'Cookie':
      return [
        translate.answerSentences.characterHasNumPlates({ name, numGroups }),
        translate.answerSentences.characterPutsNumCookies({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Cookies())
      ];
    case 'Egg':
      return [
        translate.answerSentences.characterHasNumBoxes({ name, numGroups }),
        translate.answerSentences.characterPutsNumEggs({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Eggs())
      ];
    case 'Flower':
      return [
        translate.answerSentences.characterHasNumVases({ name, numGroups }),
        translate.answerSentences.characterPutsNumFlowers({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Flowers())
      ];
    case 'Pencil':
      return [
        translate.answerSentences.characterHasNumPots({ name, numGroups }),
        translate.answerSentences.characterPutsNumPencils({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Pencils())
      ];
  }
};

export const shopObjectNames = [
  'Bunches of flowers',
  'Boxes of eggs',
  'Cartons of milk',
  'Tins of beans'
] as const;

export const shopObjectSchema = z.enum(shopObjectNames);

export function getRandomShopObject() {
  return getRandomFromArray(shopObjectNames);
}

export type ShopObjectName = (typeof shopObjectNames)[number];

export const isShopObject = (x: string): x is ShopObjectName =>
  shopObjectNames.includes(x as ShopObjectName);

export const shopObjectAsWord = (object: ShopObjectName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Bunches of flowers':
      return translate.objects.bunchesOfFlowers();
    case 'Boxes of eggs':
      return translate.objects.boxesOfEggs();
    case 'Cartons of milk':
      return translate.objects.cartonsOfMilk();
    case 'Tins of beans':
      return translate.objects.tinsOfBeans();
  }
};

export const expensiveObjectNames = [
  'Bike',
  'Games console',
  'Laptop',
  'Phone',
  'Rucksack',
  'Scooter',
  'Television'
] as const;

export const expensiveObjectSchema = z.enum(expensiveObjectNames);

export function getRandomExpensiveObject() {
  return getRandomFromArray(expensiveObjectNames);
}

export type ExpensiveObjectName = (typeof expensiveObjectNames)[number];

export const isExpensiveObject = (x: string): x is ExpensiveObjectName =>
  expensiveObjectNames.includes(x as ExpensiveObjectName);

export const expensiveObjectAsWord = (
  object: ExpensiveObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Bike':
      return isPlural ? translate.objects.Bikes() : translate.objects.Bike();
    case 'Games console':
      return isPlural ? translate.objects.GamesConsoles() : translate.objects.GamesConsole();
    case 'Laptop':
      return isPlural ? translate.objects.Laptops() : translate.objects.Laptop();
    case 'Phone':
      return isPlural ? translate.objects.Phones() : translate.objects.Phone();
    case 'Rucksack':
      return isPlural ? translate.objects.Rucksacks() : translate.objects.Rucksack();
    case 'Scooter':
      return isPlural ? translate.objects.Scooters() : translate.objects.Scooter();
    case 'Television':
      return isPlural ? translate.objects.Televisions() : translate.objects.Television();
  }
};

export const lessThanOnePoundObjectNames = ['Apple', 'Banana', 'Pear'] as const;

export const lessThanOnePoundObjectSchema = z.enum(lessThanOnePoundObjectNames);

export function getRandomLessThanOnePoundObject() {
  return getRandomFromArray(lessThanOnePoundObjectNames);
}

export type lessThanOnePoundObjectName = (typeof lessThanOnePoundObjectNames)[number];

export const cheapObjectNames = [
  'Balloon',
  'Book',
  'BreadLoaf',
  'CrayonBox',
  'FizzyDrink',
  'JuiceBottle',
  'LemonadeBottle',
  'Mug',
  'PencilCase',
  'Kettle'
] as const;

export const cheapObjectSchema = z.enum(cheapObjectNames);

export function getRandomCheapObject() {
  return getRandomFromArray(cheapObjectNames);
}

export function getRandomUniqueCheapObject(quantity: number) {
  return getRandomSubArrayFromArray([...cheapObjectNames] as const, quantity);
}

export type CheapObjectName = (typeof cheapObjectNames)[number];

export const isCheapObject = (x: string): x is CheapObjectName =>
  cheapObjectNames.includes(x as CheapObjectName);

export const cheapObjectAsWord = (
  object: CheapObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Book':
      return isPlural ? translate.objects.Books() : translate.objects.Book();
    case 'Balloon':
      return isPlural ? translate.objects.Balloons() : translate.objects.Balloon();
    case 'Kettle':
      return isPlural ? translate.objects.Kettles() : translate.objects.Kettle();
    case 'JuiceBottle':
      return isPlural ? translate.objects.JuiceBottles() : translate.objects.JuiceBottle();
    case 'Mug':
      return isPlural ? translate.objects.Mugs() : translate.objects.Mug();
    case 'CrayonBox':
      return isPlural ? translate.objects.CrayonBoxes() : translate.objects.CrayonBox();
    case 'BreadLoaf':
      return isPlural ? translate.objects.Bread() : translate.objects.Bread();
    case 'PencilCase':
      return isPlural ? translate.objects.PencilCases() : translate.objects.PencilCase();
    case 'LemonadeBottle':
      return isPlural ? translate.objects.Lemonades() : translate.objects.Lemonade();
    case 'FizzyDrink':
      return isPlural ? translate.objects.FizzyDrinks() : translate.objects.FizzyDrink();
  }
};

export const cheapObjectWithArticle = (
  object: CheapObjectName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'Book':
      return translate.objects.aBook();
    case 'Balloon':
      return translate.objects.aBalloon();
    case 'Kettle':
      return translate.objects.aKettle();
    case 'JuiceBottle':
      return translate.objects.aJuiceBottle();
    case 'Mug':
      return translate.objects.aMug();
    case 'CrayonBox':
      return translate.objects.aCrayonBox();
    case 'BreadLoaf':
      return translate.objects.aBread();
    case 'PencilCase':
      return translate.objects.aPencilCase();
    case 'LemonadeBottle':
      return translate.objects.aLemonade();
    case 'FizzyDrink':
      return translate.objects.aFizzyDrink();
  }
};

export const foodFractionObjectNames = ['Cake', 'Pie', 'Pizza'] as const;

export const foodFractionObjectSchema = z.enum(foodFractionObjectNames);

export function getRandomFoodFractionObject() {
  return getRandomFromArray(foodFractionObjectNames);
}

export type FoodFractionObjectName = (typeof foodFractionObjectNames)[number];

export const isFoodFractionObject = (x: string): x is FoodFractionObjectName =>
  foodFractionObjectNames.includes(x as FoodFractionObjectName);

export const foodFractionObjectAsWord = (
  object: FoodFractionObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Cake':
      return isPlural ? translate.objects.Cakes() : translate.objects.Cake();
    case 'Pie':
      return isPlural ? translate.objects.Pies() : translate.objects.Pie();
    case 'Pizza':
      return isPlural ? translate.objects.Pizzas() : translate.objects.Pizza();
  }
};

export const vehicleObjectNames = ['Car', 'Bus', 'Lorry', 'Train', 'Coach'] as const;

export const vehicleObjectSchema = z.enum(vehicleObjectNames);

export function getRandomvehicleObjectObject() {
  return getRandomFromArray(vehicleObjectNames);
}

export type vehicleObjectName = (typeof vehicleObjectNames)[number];

export const domesticAnimalsObjectNames = ['Cat', 'Dog', 'Bird', 'Hamster'] as const;

export const domesticAnimalsObjectSchema = z.enum(domesticAnimalsObjectNames);
export function getRandomdomesticAnimalsObject() {
  return getRandomFromArray(domesticAnimalsObjectNames);
}
export type domesticAnimalsObjectName = (typeof domesticAnimalsObjectNames)[number];

export const measureObjectNames = [
  'lollipop',
  'pencil',
  'stick',
  'drinking_straw',
  'toy_car',
  'crayon'
] as const;

export type MeasureObjectName = (typeof measureObjectNames)[number];

export const measureObjectWithArticle = (
  object: MeasureObjectName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'drinking_straw':
      return translate.objects.theStraw();
    case 'lollipop':
      return translate.objects.theLollipop();
    case 'pencil':
      return translate.objects.thePencil();
    case 'stick':
      return translate.objects.theStick();
    case 'toy_car':
      return translate.objects.theToyCar();
    case 'crayon':
      return translate.objects.theCrayon();
  }
};

export const scaleObjectNames = ['orange', 'apple'] as const;
export type ScaleObjectName = (typeof scaleObjectNames)[number];
export const scaleObjectsSchema = z.enum(scaleObjectNames);
export type ScaleObjectInfo = {
  name: SvgName;
  width: number;
  rotation: number;
  weight: number;
  topOffset: number;
  leftOffset?: number;
};

export const scaleObjects: Record<ScaleObjectName, ScaleObjectInfo> = {
  orange: {
    name: 'Array_objects/Orange',
    width: 80,
    rotation: 0,
    weight: 3,
    topOffset: 35,
    leftOffset: 20
  },
  apple: {
    name: 'Array_objects/AppleGreen',
    width: 80,
    rotation: 0,
    weight: 4,
    topOffset: 20
  }
};

export function getRandomUniqueScaleObjectNames(numberOfScaleObjectNames: number) {
  return getRandomSubArrayFromArray(scaleObjectNames, numberOfScaleObjectNames);
}

export const scaleObjectAsWord = (
  scaleObjectName: ScaleObjectName,
  translate: TranslationFunctions,
  plural = false
) => {
  switch (scaleObjectName) {
    case 'orange':
      return plural ? translate.weightObjects.oranges() : translate.weightObjects.orange();
    case 'apple':
      return plural ? translate.weightObjects.apples() : translate.weightObjects.apple();
  }
};

export const heavyObjectNames = ['Laptop', 'Rucksack', 'BookRed'] as const;

export const heavyObjectSchema = z.enum(heavyObjectNames);

export function getRandomHeavyObject() {
  return getRandomFromArray(heavyObjectNames);
}

export function getRandomUniqueHeavyObject(quantity: number) {
  return getRandomSubArrayFromArray(heavyObjectNames, quantity);
}

export type HeavyObjectName = (typeof heavyObjectNames)[number];

export const heavyObjectAsWord = (object: HeavyObjectName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Laptop':
      return translate.objects.Laptop();
    case 'Rucksack':
      return translate.objects.Rucksack();
    case 'BookRed':
      return translate.objects.Book();
  }
};
