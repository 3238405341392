import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

/** Our internal model of a Question. */
export type QuestionInfo = {
  id: string;
  uid: string;
  /** Stringified question parameters */
  parameters?: string;
  displayOrder: number;
};

/** Our internal model of a Quiz Session. */
export type QuizSessionInfo = {
  bearerToken?: string;
  id: string;
  name: string;
  learningGroupShareCode: string;
  quizVersionShareShareCode: string;
  randomiseQuestionParameters: boolean;
  questions: QuestionInfo[];
  /** Default: false */
  quizSounds?: boolean;
  /** Default: false */
  isLegacyQuiz?: boolean;
};

type State = {
  quizSession: QuizSessionInfo | undefined;
};

type Actions = {
  setQuizSession: (quizSession: QuizSessionInfo) => void;
  clearQuizSession: () => void;
  clear: () => void;
};

const defaultState: State = {
  quizSession: undefined
};

const useQuizSessionStore = create<State & Actions>()(
  devtools(
    persist(
      set => ({
        ...defaultState,
        setQuizSession: (quizSession: QuizSessionInfo) => set({ quizSession }),
        clearQuizSession: () => set({ quizSession: undefined }),
        clear: () => set(defaultState)
      }),
      {
        name: 'quizSession',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useQuizSessionStore;
