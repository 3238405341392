import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

type SchoolInfo = {
  code: string;
  name: string;
  hasInfinityPlus: boolean;
};

type AuthTokens = {
  token: string;
  refreshToken: string;
};

type Profile = {
  firstName: string;
  lastName: string;
  classStudentId: number;
};

type LoggedInUser = {
  authTokens: AuthTokens;
  profile: Profile;
};

type State = {
  school: SchoolInfo | undefined;
  loggedInUser: LoggedInUser | undefined;
  lastActive: number | undefined;
};

type Actions = {
  setSchool: (school: SchoolInfo) => void;
  clearSchool: () => void;
  setLoggedInUser: (loggedInUser: LoggedInUser) => void;
  clearLoggedInUser: () => void;
  setActivity: () => void;
  clear: () => void;
};

const defaultState: State = {
  school: undefined,
  loggedInUser: undefined,
  lastActive: undefined
};

const useLoginStore = create<State & Actions>()(
  devtools(
    persist(
      set => ({
        ...defaultState,
        setSchool: (school: SchoolInfo) => set({ school }),
        clearSchool: () => set({ school: undefined }),
        setLoggedInUser: (loggedInUser: LoggedInUser) => {
          set({ loggedInUser, lastActive: Date.now() });
        },
        clearLoggedInUser: () => set({ lastActive: undefined, loggedInUser: undefined }),
        setActivity: () => set({ lastActive: Date.now() }),
        clear: () => set(defaultState)
      }),
      {
        name: 'login',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useLoginStore;
