import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import {
  lessThanOnePoundObjectSchema,
  getRandomLessThanOnePoundObject,
  getRandomCheapObject,
  cheapObjectSchema
} from 'common/src/utils/objects';
import {
  getCheapObjectSvgName,
  getLessThanOnePoundObjectSvgName
} from 'common/src/utils/objectsImages';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from 'common/src/components/molecules/ContentBox';
import { penceToPoundsAndPence } from 'common/src/utils/money';
import { AssetSvg } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atw',
  description: 'atw',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z.object({
    number: z.number().int().min(10).max(90).multipleOf(10),
    object: lessThanOnePoundObjectSchema
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10, 90, 10);

    const object = getRandomLessThanOnePoundObject();

    return { number, object };
  },
  Component: props => {
    const {
      question: { number, object },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = 100 - number;

    const svg = getLessThanOnePoundObjectSvgName(object);

    return (
      <QF1ContentAndSentence
        sentence={'<ans />p'}
        title={translate.instructions.howMuchChangeWouldYouGetFromAXPoundCoin(1)}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AssetSvg
              name={svg}
              height={displayMode === 'digital' ? (dimens.height / 2.5) * 2 : dimens.height}
            />
            <ContentBox
              containerStyle={{
                paddingHorizontal: 25,
                paddingVertical: 0,
                justifyContent: 'flex-end',
                right: 50,
                transform: [{ rotate: '-8deg' }]
              }}
            >
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }} variant="WRN400">
                {translate.units.numberOfPence(number)}
              </Text>
            </ContentBox>
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'atx',
  description: 'atx',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z.object({
    pounds: z.number().int().min(1).max(9),
    pence: z.number().int().min(10).max(90).multipleOf(10),
    object: cheapObjectSchema
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(1, 9);
    const pence = randomIntegerInclusiveStep(10, 90, 10);

    const object = getRandomCheapObject();

    return { pounds, pence, object };
  },
  Component: props => {
    const {
      question: { pounds, pence, object },
      translate,
      displayMode
    } = props;

    // Answers
    const answer1 = 10 - pounds - 1;
    const answer2 = 100 - pence;

    const svg = getCheapObjectSvgName(object);

    return (
      <QF1ContentAndSentence
        sentence={'£<ans /> and <ans />p'}
        title={translate.instructions.howMuchChangeWouldYouGetFromAXPoundNote(10)}
        testCorrect={[answer1.toString(), answer2.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AssetSvg
              name={svg}
              height={displayMode === 'digital' ? (dimens.height / 2.5) * 2 : dimens.height}
            />
            <ContentBox
              containerStyle={{
                paddingHorizontal: 25,
                paddingVertical: 0,
                justifyContent: 'flex-end',
                right: 50,
                transform: [{ rotate: '-8deg' }]
              }}
            >
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }} variant="WRN400">
                {translate.answerSentences.xPoundsAndYPence(pounds, pence)}
              </Text>
            </ContentBox>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aty',
  description: 'aty',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z
    .object({
      pounds: z.number().int().min(1).max(3),
      pence: z.number().int().min(5).max(95),
      name: nameSchema
    })
    .refine(
      val => val.pence.toString()[val.pence.toString().length - 1].includes('5'),
      'The ones digit of pence must be a 5'
    ),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(1, 3);
    const pence = randomIntegerInclusiveStep(5, 95, 10);
    const name = getRandomName();

    return { pounds, pence, name };
  },
  Component: props => {
    const {
      question: { pounds, pence, name },
      translate
    } = props;

    // Answers
    const answer1 = 5 - pounds - 1;
    const answer2 = 100 - pence;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterBuysAHotChocolateForXPoundsAndYPenceHowMuchChangeDoesCharacterGet(
          {
            character: name,
            pounds,
            pence
          }
        )}
        sentence={'£<ans /> and <ans />p'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        testCorrect={[answer1.toString(), answer2.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'atz',
  description: 'atz',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z
    .object({
      pounds: z.number().int().min(1).max(3),
      pence: z.number().int().min(5).max(95),
      name: nameSchema
    })
    .refine(
      val => val.pence.toString()[val.pence.toString().length - 1].includes('5'),
      'The ones digit of pence must be a 5'
    ),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(1, 3);
    const pence = randomIntegerInclusiveStep(5, 95, 10);
    const name = getRandomName();

    return { pounds, pence, name };
  },
  Component: props => {
    const {
      question: { pounds, pence, name },
      translate
    } = props;

    // Answers
    const answer1 = 5 - pounds - 1;
    const answer2 = 100 - pence;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterBuysAMilkshakeAndPaysWithA5PoundNoteHowMuchDoesTheMilkshakeCost(
          {
            character: name,
            pounds,
            pence
          }
        )}
        sentence={'£<ans /> and <ans />p'}
        testCorrect={[answer1.toString(), answer2.toString()]}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'atA2',
  description: 'atA',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z
    .object({
      moneyToSpend: numberEnum([5, 10, 20]),
      itemAPounds: z.number().int().min(3).max(5),
      itemBPounds: z.number().int().min(2).max(4),
      itemCPounds: z.number().int().min(3).max(5),
      itemsPence: z.number().int().min(5).max(95).multipleOf(5),
      name: nameSchema
    })
    .refine(
      val => val.itemCPounds !== val.itemBPounds,
      'itemCPounds should not be equal to itemBPounds'
    ),
  simpleGenerator: () => {
    const moneyToSpend = getRandomFromArray([5, 10, 20] as const);
    const itemAPounds = randomIntegerInclusive(3, 4);
    const itemBPounds = randomIntegerInclusive(2, 4);
    const itemCPounds = randomIntegerInclusive(3, 4, {
      // itemBPounds and itemCPounds have the same pence value so need different pound values
      constraint: x => x !== itemBPounds
    });
    const itemsPence = randomIntegerInclusiveStep(5, 95, 5);

    const name = getRandomName();

    return { moneyToSpend, itemAPounds, itemBPounds, itemCPounds, itemsPence, name };
  },
  Component: props => {
    const {
      question: { moneyToSpend, itemAPounds, itemBPounds, itemCPounds, itemsPence, name },
      translate,
      displayMode
    } = props;

    // Title change in pence
    const changeInPence = (moneyToSpend - itemCPounds) * 100 - itemsPence;

    // Title change in pence converted to pounds and pence
    const { pounds, pence } = penceToPoundsAndPence(changeInPence);

    const random = seededRandom(props.question);

    // Shuffled breakfast items
    const breakfastItems = shuffle(
      [translate.food.Omelette(2), translate.food.Pancake(2), translate.food.Waffle(2)],
      { random }
    );

    // Statements to select
    const A = {
      component: <Text variant="WRN700">{breakfastItems[0]}</Text>,
      value: breakfastItems[0]
    };

    const B = {
      component: <Text variant="WRN700">{breakfastItems[1]}</Text>,
      value: breakfastItems[1]
    };

    const C = {
      component: <Text variant="WRN700">{breakfastItems[2]}</Text>,
      value: breakfastItems[2]
    };

    const statements = shuffle([A, B, C], { random: seededRandom(props.question) });

    // Item options
    const itemAPence = 100 - itemsPence;

    const itemOptions = shuffle(
      [
        {
          name: breakfastItems[0],
          pounds: itemAPounds,
          pence: itemAPence
        },
        {
          name: breakfastItems[1],
          pounds: itemBPounds,
          pence: itemsPence
        },
        {
          name: breakfastItems[2],
          pounds: itemCPounds,
          pence: itemsPence
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterHasXPoundsToBuyBreakfastSelectTheBreakfastItemThatCharacterBuys(
          {
            character: name,
            poundsA: moneyToSpend,
            poundsB: pounds,
            pence: pence
          }
        )}
        pdfTitle={translate.instructions.characterHasXPoundsToBuyBreakfastCircleTheBreakfastItemThatCharacterBuys(
          {
            character: name,
            poundsA: moneyToSpend,
            poundsB: pounds,
            pence: pence
          }
        )}
        testCorrect={[breakfastItems[2]]}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <ContentBox containerStyle={{ width: dimens.width / 2 }}>
              {itemOptions.map((item, idx) => (
                <View
                  key={idx}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{item.name}</Text>
                  <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                    {translate.answerSentences.xPoundsAndYPence(item.pounds, item.pence)}
                  </Text>
                </View>
              ))}
            </ContentBox>
          </View>
        )}
        renderItems={statements}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'atA',
  description: 'atA',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z
    .object({
      moneyToSpend: z.number().int().min(7).max(9),
      itemAPounds: z.number().int().min(4).max(5),
      itemBPounds: z.number().int().min(3).max(4),
      itemCPounds: z.number().int().min(4).max(5),
      itemsPence: z.number().int().min(5).max(95).multipleOf(5),
      name: nameSchema
    })
    .refine(
      val => val.itemCPounds !== val.itemBPounds,
      'itemCPounds should not be equal to itemBPounds'
    ),
  simpleGenerator: () => {
    const moneyToSpend = randomIntegerInclusive(7, 9);
    const itemAPounds = randomIntegerInclusive(4, 5);
    const itemBPounds = randomIntegerInclusive(3, 4);
    const itemCPounds = randomIntegerInclusive(4, 5, {
      // itemBPounds and itemCPounds have the same pence value so need different pound values
      constraint: x => x !== itemBPounds
    });
    const itemsPence = randomIntegerInclusiveStep(5, 95, 5);

    const name = getRandomName();

    return { moneyToSpend, itemAPounds, itemBPounds, itemCPounds, itemsPence, name };
  },
  Component: props => {
    const {
      question: { moneyToSpend, itemAPounds, itemBPounds, itemCPounds, itemsPence, name },
      translate,
      displayMode
    } = props;

    // Title change in pence
    const changeInPence = (moneyToSpend - itemCPounds) * 100 - itemsPence;

    // Title change in pence converted to pounds and pence
    const { pounds, pence } = penceToPoundsAndPence(changeInPence);

    const random = seededRandom(props.question);

    // Shuffled breakfast items
    const breakfastItems = shuffle(
      [translate.food.Omelette(2), translate.food.Pancake(2), translate.food.Waffle(2)],
      { random }
    );

    // Statements to select
    const A = {
      component: <Text variant="WRN700">{breakfastItems[0]}</Text>,
      value: breakfastItems[0],
      correct: false
    };

    const B = {
      component: <Text variant="WRN700">{breakfastItems[1]}</Text>,
      value: breakfastItems[1],
      correct: false
    };

    const C = {
      component: <Text variant="WRN700">{breakfastItems[2]}</Text>,
      value: breakfastItems[2],
      correct: true
    };

    const statements = shuffle([A, B, C], { random: seededRandom(props.question) });

    // Item options
    const itemAPence = 100 - itemsPence;

    const itemOptions = shuffle(
      [
        {
          name: breakfastItems[0],
          pounds: itemAPounds,
          pence: itemAPence
        },
        {
          name: breakfastItems[1],
          pounds: itemBPounds,
          pence: itemsPence
        },
        {
          name: breakfastItems[2],
          pounds: itemCPounds,
          pence: itemsPence
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct).map(it => it.value);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterHasXPoundsToBuyBreakfastSelectTheBreakfastItemThatCharacterBuys(
          {
            character: name,
            poundsA: moneyToSpend,
            poundsB: pounds,
            pence: pence
          }
        )}
        pdfTitle={translate.instructions.characterHasXPoundsToBuyBreakfastCircleTheBreakfastItemThatCharacterBuys(
          {
            character: name,
            poundsA: moneyToSpend,
            poundsB: pounds,
            pence: pence
          }
        )}
        testCorrect={correctAnswers}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <ContentBox containerStyle={{ width: dimens.width / 2 }}>
              {itemOptions.map((item, idx) => (
                <View
                  key={idx}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{item.name}</Text>
                  <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                    {translate.answerSentences.xPoundsAndYPence(item.pounds, item.pence)}
                  </Text>
                </View>
              ))}
            </ContentBox>
          </View>
        )}
        renderItems={statements}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'atB',
  description: 'atB',
  keywords: ['Money', 'Pounds', 'Pence', 'Change'],
  schema: z
    .object({
      trainCostInPounds: z.number().int().min(4).max(6),
      trainCostInPence: z.number().int().min(50).max(90).multipleOf(10),
      busCostInPounds: z.number().int().min(1).max(2),
      busCostInPence: z.number().int().min(65).max(95),
      name: nameSchema
    })
    .refine(
      val => val.busCostInPence.toString()[val.busCostInPence.toString().length - 1].includes('5'),
      'The ones digit of busCostInPence must be a 5'
    ),
  simpleGenerator: () => {
    const trainCostInPounds = randomIntegerInclusive(4, 6);
    const trainCostInPence = randomIntegerInclusiveStep(50, 90, 10);
    const busCostInPounds = randomIntegerInclusive(1, 2);
    const busCostInPence = randomIntegerInclusiveStep(65, 95, 10);

    const name = getRandomName();

    return { trainCostInPounds, trainCostInPence, busCostInPounds, busCostInPence, name };
  },
  Component: props => {
    const {
      question: { trainCostInPounds, trainCostInPence, busCostInPounds, busCostInPence, name },
      translate
    } = props;

    // Convert £10, trainCostInPounds and busCostInPounds to pence
    // Subtract the pence versions of these from £10
    const changeInPence =
      10 * 100 -
      (trainCostInPounds * 100 + trainCostInPence) -
      (busCostInPounds * 100 + busCostInPence);

    // Use penceToPoundsAndPence util function to convert back to pounds and pence
    const { pounds, pence } = penceToPoundsAndPence(changeInPence);

    // Answers
    const answer1 = pounds;
    const answer2 = pence;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterBuysATrainTicketAndABusTicketCharacterPaysWithATenPoundNoteHowMuchChangeDoesCharacterGet(
          {
            character: name,
            trainCostInPounds,
            trainCostInPence,
            busCostInPounds,
            busCostInPence
          }
        )}
        sentence={'£<ans /> and <ans />p'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        testCorrect={[answer1.toString(), answer2.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindChange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
