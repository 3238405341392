import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { getTrustedQuestion, QuestionToken, SpecificQuestion } from '../../SchemeOfLearning';
import { filledArray, readonlyArrayEntry } from 'common/src/utils/collections';
import { Portal } from 'common/src/components/portal';
import ProgressContainer from 'common/src/components/molecules/ProgressContainer';
import ContinueButton from 'common/src/components/atoms/ContinueButton';
import OkModal from 'common/src/components/modals/OkModal';
import YesNoModal from 'common/src/components/modals/YesNoModal';
import WellDoneModal from 'common/src/components/modals/WellDoneModal';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useI18nContext } from 'common/src/i18n/i18n-react';
import DebugModeContext from '../../contexts/DebugModeContext';
import { Platform } from 'react-native';
import {
  MINIMUM_QUESTION_HEIGHT,
  QUESTION_WIDTH,
  ScaleContent,
  containAspectRatio
} from '../../theme/scaling';
import { getPlayer, soundChoices } from '../../utils/Audio';
import { noop } from '../../utils/flowControl';
import { ErrorBoundary, type ErrorBoundaryProps } from 'react-error-boundary';
import QuizErrorCard from './QuizErrorCard';

const MAX_ATTEMPTS = 3;

type Props = {
  /** Dimens that each question should fit in. Default: 1280x720 */
  dimens?: { width: number; height: number };
  /**
   * One of:
   * - tokens mode (normal operation): An array of tokens
   * - preview mode: A single token which simulates being at a particular point in a quiz, but the quiz doesn't
   * progress as it's answered (it just gets shown again)
   *
   * These tokens must be *trusted*, meaning you are **100% sure** that the UIDs are found in this library, and any
   * question params you provide are valid according to that Question Type's scheme. For example, you generated the
   * params within this runtime of your app, or you've obtained the tokens from the network / user input **and have
   * already validated them**.
   */
  questions:
    | { mode: 'tokens'; tokens: QuestionToken[] }
    | {
        mode: 'preview';
        token: QuestionToken;
        indexInQuiz: number;
        lengthOfQuiz: number;
        /**
         * Generate a different question instance each time.
         * (Only used if token is a specific question instance, otherwise this occurs anyway.)
         * Default: false
         */
        differentQuestionEachTime?: boolean;
      };
  /** Callback for when the quiz is exited early. */
  onExitQuiz?: () => void;
  /** Callback for when the quiz is finished. */
  onFinishQuiz?: (
    results: {
      /** Number of stars (0 to 3) awarded for this question. */
      stars: number;
    }[]
  ) => void;
  /** Optional callback for when a question is attempted. */
  onAnswer?: (
    questionIndex: number,
    answer: string,
    isCorrect: boolean,
    timeTaken: number,
    attemptNumber: number,
    parameters: Record<string, unknown>
  ) => void;
  /**
   * Optional callback for retrying a quiz. This is only used if an error occurs.
   * If not provided, the button is not offered.
   */
  onRetryQuiz?: () => void;
  /**
   * Optional callback for returning to the home screen. This is only used if an error occurs.
   * If not provided, the button is not offered.
   */
  onReturnToHome?: () => void;
};

/**
 * A quiz containing several questions.
 *
 * The quiz can only be progressed forwards, and the user gets three tries on each question.
 *
 * This tracks all of the user answer state for all questions it's asked so far.
 *
 * Important note: it is not supported to update the `questions` prop after the first render. If you wish to change it,
 * destroy and recreate this component (e.g. by changing its key).
 *
 * There is a transition between questions where the current question fades out to nothing and then next question
 * fades in. This includes the first and last questions. (Transitions don't work on web, at time of writing.)
 *
 * This component is wrapped in an error boundary, showing a simple error screen if a descendant component throws an
 * error.
 */
export default function Quiz({
  dimens = { width: 1280, height: 720 },
  questions,
  onExitQuiz,
  onFinishQuiz,
  onAnswer = noop,
  onRetryQuiz,
  onReturnToHome
}: Props) {
  const translate = useI18nContext().LL;
  const debugMode = useContext(DebugModeContext);

  if (questions.mode === 'tokens' && questions.tokens.length === 0) {
    throw new Error('`tokens` must not be an empty array');
  }

  // Initialize our state. This keeps track of the answers for each question, and which question we're on.
  const [quizState, dispatchQuizAction] = useReducer(quizStateReducer, null, (): QuizState => {
    if (questions.mode === 'tokens') {
      // Tokens mode - quiz of several tokens (normal operation)
      return {
        mode: 'tokens',
        currentQuestionIndex: 0,
        currentQuestionIncorrectAttempts: 0,
        currentAttemptStartTime: Date.now(),
        tokens: questions.tokens.map(questionTokenToSpecificQuestion),
        userAnswers: filledArray({ userAnswer: {} }, questions.tokens.length),
        results: filledArray({}, questions.tokens.length)
      };
    } else if (questions.mode === 'preview') {
      // Preview mode - just one token
      return {
        mode: 'preview',
        currentQuestionIndex: 0,
        currentQuestionIncorrectAttempts: 0,
        currentAttemptStartTime: Date.now(),
        previewToken:
          typeof questions.token !== 'string' && questions.differentQuestionEachTime
            ? questions.token[0] // Just use the UID for generating new questions
            : questions.token,
        // In preview mode we just populate the first question up front
        tokens: [questionTokenToSpecificQuestion(questions.token)],
        userAnswers: [{ userAnswer: {} }],
        results: [{}]
      };
    } else {
      // Produces TS error and throws runtime error if we missed a case
      throw new Error(`Logic error: unreachable (${questions satisfies never})`);
    }
  });

  const onEnterKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        dispatchQuizAction({ type: 'ENTER_KEY_PRESSED', onAnswer });
      }
    },
    [onAnswer]
  );

  // Add the key handler to submit the question when the
  // enter key is pressed
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('keydown', onEnterKeyDown);
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('keydown', onEnterKeyDown);
      }
    };
  }, [onEnterKeyDown]);

  // If the state ever sets the relevant flag, navigate to the results screen
  useEffect(() => {
    if (quizState.navigateToResultsScreen) {
      // Do the navigation
      onFinishQuiz && onFinishQuiz(quizState.results.map(result => ({ stars: result.stars ?? 0 })));
    }
  }, [quizState.navigateToResultsScreen, quizState.userAnswers, quizState.results, onFinishQuiz]);

  // Derived state
  const index = quizState.currentQuestionIndex;
  const token = quizState.tokens[index];
  const questionContent = useMemo(() => getTrustedQuestion(token), [token]);
  const { userAnswer } = quizState.userAnswers[index];
  const incorrectAttempts = quizState.currentQuestionIncorrectAttempts;
  const attemptsRemaining = MAX_ATTEMPTS - incorrectAttempts;
  const numberToShow = (() => {
    switch (questions.mode) {
      case 'tokens':
        return index + 1;
      case 'preview':
        return questions.indexInQuiz + 1;
    }
  })();
  const percentageProgress = (() => {
    switch (questions.mode) {
      case 'tokens':
        return (index / questions.tokens.length) * 100;
      case 'preview':
        return (questions.indexInQuiz / questions.lengthOfQuiz) * 100;
    }
  })();
  const isFinalQuestion =
    questions.mode === 'tokens' ? index === questions.tokens.length - 1 : false;
  const starsSoFar = quizState.results.slice(0, index).reduce((acc, x) => acc + (x.stars ?? 0), 0);
  const starsThisQuestion = quizState.results[index].stars ?? 0;

  // Error boundary
  const fallbackRender = useCallback(
    () => (
      <QuizErrorCard
        onExitClicked={onExitQuiz}
        onTryAgainClicked={onRetryQuiz}
        onHomeClicked={onReturnToHome}
      />
    ),
    [onExitQuiz, onRetryQuiz, onReturnToHome]
  );
  const onError: NonNullable<ErrorBoundaryProps['onError']> = useCallback(
    error => {
      // WIBNI this reported to a service such as Firebase or Sentry
      console.error(
        `Hit a render error in Quiz. ` +
          `Current question (${index}): ${JSON.stringify(token)}. ` +
          `Current user answer: ${JSON.stringify(userAnswer)}. ` +
          `Error message: ${error.message}.`
      );
    },
    [index, token, userAnswer]
  );

  // Calculate the dimensions of the question contained inside the dimens.
  const questionActualDimens = containAspectRatio(dimens, QUESTION_WIDTH / MINIMUM_QUESTION_HEIGHT);
  const scaleFactor = questionActualDimens.width / QUESTION_WIDTH;

  return (
    <ScaleContent dimens={questionActualDimens} scaleFactor={scaleFactor}>
      <ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
        <Animated.View
          key={index}
          entering={FadeIn.duration(300).delay(index === 0 ? 0 : 300)}
          exiting={FadeOut.duration(300)}
        >
          <questionContent.questionType.QuizComponent
            userAnswer={userAnswer}
            onUserAnswerChanged={userAnswer =>
              dispatchQuizAction({ type: 'UPDATE_CURRENT_QUESTION', userAnswer })
            }
            onCompleteChanged={isComplete =>
              dispatchQuizAction({ type: 'UPDATE_CURRENT_QUESTION', isComplete })
            }
            onCorrectChanged={isCorrect =>
              dispatchQuizAction({ type: 'UPDATE_CURRENT_QUESTION', isCorrect })
            }
            QuizInformation={useMemo(
              () => (
                <ProgressContainer
                  questionNumber={numberToShow}
                  quizProgress={percentageProgress}
                  quizStars={starsSoFar}
                  onExitClicked={onExitQuiz && (() => dispatchQuizAction({ type: 'EXIT_QUIZ' }))}
                />
              ),
              [numberToShow, onExitQuiz, percentageProgress, starsSoFar]
            )}
            QuizNavigation={useCallback(
              ({ variant }: { variant: 'normal' | 'thin' }) => (
                <ContinueButton
                  disabled={quizState.showingModal !== undefined}
                  variant={variant === 'thin' ? 'circle' : 'oval'}
                  onPress={() => {
                    dispatchQuizAction({ type: 'SUBMIT_QUESTION', onAnswer });
                  }}
                  onLongPress={
                    // In debug mode only, a long-press will skip questions
                    debugMode
                      ? () =>
                          dispatchQuizAction({ type: 'SUBMIT_QUESTION', forceSkip: true, onAnswer })
                      : undefined
                  }
                />
              ),
              [debugMode, onAnswer, quizState.showingModal]
            )}
            question={questionContent.data}
          />
        </Animated.View>
      </ErrorBoundary>

      <Portal>
        {(() => {
          switch (quizState.showingModal) {
            case 'incomplete':
              return (
                <OkModal
                  title={translate.quiz.modals.allAnswerBoxesNeedToBeFilled()}
                  text={translate.quiz.modals.checkAllAnswerBoxesFilled()}
                  onDismiss={() => dispatchQuizAction({ type: 'DISMISS_MODAL' })}
                  modalHeight={488}
                />
              );
            case 'try again':
              return (
                <OkModal
                  title={translate.quiz.modals.tryAgain()}
                  text={translate.quiz.modals.xAttemptsRemaining({ attemptsRemaining })}
                  onDismiss={() => dispatchQuizAction({ type: 'DISMISS_MODAL' })}
                />
              );
            case 'skip':
              return (
                <OkModal
                  title={
                    questions.mode === 'preview'
                      ? translate.quiz.modals.skipPreview()
                      : isFinalQuestion
                      ? translate.quiz.modals.skipSeeResults()
                      : translate.quiz.modals.skipTryNext()
                  }
                  onDismiss={() => dispatchQuizAction({ type: 'DISMISS_MODAL' })}
                />
              );
            case 'correct':
              return (
                <WellDoneModal
                  stars={starsThisQuestion}
                  onDismiss={() => dispatchQuizAction({ type: 'DISMISS_MODAL' })}
                />
              );
            case 'exit quiz':
              return (
                <YesNoModal
                  title={translate.quiz.modals.endQuiz()}
                  onDismiss={() => dispatchQuizAction({ type: 'DISMISS_MODAL' })}
                  onConfirm={() => {
                    dispatchQuizAction({ type: 'DISMISS_MODAL' });
                    onExitQuiz && onExitQuiz();
                  }}
                />
              );
          }
        })()}
      </Portal>
    </ScaleContent>
  );
}

type ModalType = 'incomplete' | 'try again' | 'skip' | 'correct' | 'exit quiz';

/**
 * State for the quiz, managed by a {@link quizStateReducer}.
 */
type QuizState = {
  /** Which mode to use. See props of this component for more information. */
  mode: 'tokens' | 'preview';

  /** The current question being shown. This is 0-indexed. */
  currentQuestionIndex: number;

  /** How many incorrect attempts the user has had at this question. */
  currentQuestionIncorrectAttempts: number;

  /** When was the current attempt of the current question started? */
  currentAttemptStartTime: number;

  /** Token of the question that we're previewing (preview mode only), a {@link SpecificQuestion} or just a UID. */
  previewToken?: QuestionToken;

  /** Tokens of questions in this quiz. In preview mode, it only shows the ones attempted so far. */
  tokens: SpecificQuestion[];

  /**
   * Working state of the questions. The questions are controlled components, and this is their states.
   *
   * Must be the same length as `tokens`.
   */
  userAnswers: {
    userAnswer: Record<string, unknown>;
    isComplete?: boolean;
    isCorrect?: boolean;
  }[];

  /**
   * This quiz does not allow the user to change their answers. Store their final answers here.
   *
   * Must be the same length as `tokens`.
   */
  results: {
    /** 0 to 3, where 0 means they skipped it. Undefined means not answered yet. */
    stars?: number;
  }[];

  /** Whether to navigate to the results screen now (defaults to false) */
  navigateToResultsScreen?: true;

  /** Whether we're currently showing a modal (undefined means no modal) */
  showingModal?: ModalType;
};

/**
 * - UPDATE_CURRENT_QUESTION
 *   - The currently shown question's user answer state changed.
 *
 * - SUBMIT_QUESTION
 *   - The user pressed a button to submit their answer to the current question.
 *   - Side effect: calls onAnswer callback
 *   - Optional flag to force skip (default false)
 *
 * - EXIT_QUIZ
 *   - The user pressed a button to exit the quiz.
 *
 * - DISMISS_MODAL
 *   - The user (or a timer) dismissed the current modal.
 *
 * - ENTER_KEY_PRESSED
 *   - The user pressed the enter key, which might dismiss a modal or might submit a question
 *   - Side effect: sometimes calls onAnswer callback
 */
type QuestionsAction =
  | {
      type: 'UPDATE_CURRENT_QUESTION';
      userAnswer?: Record<string, unknown>;
      isComplete?: boolean;
      isCorrect?: boolean;
    }
  | {
      type: 'SUBMIT_QUESTION';
      onAnswer: (
        questionIndex: number,
        answer: string,
        isCorrect: boolean,
        timeTaken: number,
        attemptNumber: number,
        parameters: Record<string, unknown>
      ) => void;
      forceSkip?: boolean;
    }
  | { type: 'EXIT_QUIZ' }
  | { type: 'DISMISS_MODAL' }
  | {
      type: 'ENTER_KEY_PRESSED';
      onAnswer: (
        questionIndex: number,
        answer: string,
        isCorrect: boolean,
        timeTaken: number,
        attemptNumber: number,
        parameters: Record<string, unknown>
      ) => void;
    };

/**
 * Reducer for {@link QuizState}.
 *
 * Notes:
 * - The current question index indicates which question is currently rendered to screen. Therefore, we only change
 *   this once any relevant modals have been dismissed. Therefore, dismissing those modals is an action that has
 *   significant effect (it can increment the question index, moving to next question). This might be counter
 *   intuitive.
 * - Side effects:
 *   - SUBMIT_QUESTION and ENTER_KEY_PRESSED actions may call the onAnswer callback
 * - Impurities:
 *   - the current system time is sampled when moving to a new question
 */
function quizStateReducer(state: QuizState, action: QuestionsAction): QuizState {
  switch (action.type) {
    case 'UPDATE_CURRENT_QUESTION': {
      const { userAnswer, isComplete, isCorrect } = action;
      return {
        ...state,
        userAnswers: readonlyArrayEntry(
          state.userAnswers,
          state.currentQuestionIndex,
          answerState => ({
            ...answerState,
            userAnswer: userAnswer ?? answerState.userAnswer,
            isComplete: isComplete ?? answerState.isComplete,
            isCorrect: isCorrect ?? answerState.isCorrect
          })
        )
      };
    }

    case 'SUBMIT_QUESTION': {
      return submitQuestion(state, action.onAnswer, action.forceSkip ?? false);
    }

    case 'EXIT_QUIZ': {
      const player = getPlayer();
      player.playSound('modal');
      return { ...state, showingModal: 'exit quiz' };
    }

    case 'ENTER_KEY_PRESSED': {
      if (state.showingModal) {
        return dismissModal(state);
      } else {
        return submitQuestion(state, action.onAnswer, false);
      }
    }

    case 'DISMISS_MODAL': {
      return dismissModal(state);
    }
  }
}

/**
 * Helper function to handle the submitting of the question. This can be called from a user
 * clicking the continue button or pressing the enter key.
 *
 * Calls `onAnswer` as a side effect.
 */
function submitQuestion(
  state: QuizState,
  onAnswer: (
    questionIndex: number,
    answer: string,
    isCorrect: boolean,
    timeTaken: number,
    attemptNumber: number,
    parameters: Record<string, unknown>
  ) => void,
  forceSkip: boolean
): QuizState {
  const {
    userAnswer,
    isComplete = false,
    isCorrect = false
  } = state.userAnswers[state.currentQuestionIndex];
  const incorrectAttempts = state.currentQuestionIncorrectAttempts;
  const startTime = state.currentAttemptStartTime;

  if (forceSkip) {
    // Used the force-skip debug option. Progress to the next question without showing any modals.
    // First add a 0-star result (don't bother checking for correctness).
    state = {
      ...state,
      results: readonlyArrayEntry(state.results, state.currentQuestionIndex, () => ({
        stars: 0
      }))
    };

    // Then go to next question
    return goToNextQuestion(state);
  }

  if (!isComplete) {
    // Clicked submit without an answer. Show modal indicating incomplete.
    const player = getPlayer();
    player.playSound('modal');
    return { ...state, showingModal: 'incomplete' };
  }

  if (onAnswer) {
    const [_uid, parameters] = state.tokens[state.currentQuestionIndex];
    onAnswer(
      state.currentQuestionIndex,
      JSON.stringify(userAnswer),
      isCorrect,
      // To get time taken in ms take away the startTime from the endTime (now)
      Date.now() - startTime,
      incorrectAttempts + 1,
      parameters
    );
  }

  if (!isCorrect) {
    // They got it wrong! Increment their incorrect attempts
    const player = getPlayer();
    player.playSound('modal');
    const newIncorrectAttempts = incorrectAttempts + 1;

    if (newIncorrectAttempts === MAX_ATTEMPTS) {
      // Too many wrong guesses, skip the question and log the results
      return {
        ...state,
        currentQuestionIncorrectAttempts: newIncorrectAttempts,
        results: readonlyArrayEntry(state.results, state.currentQuestionIndex, () => ({
          stars: 0
        })),
        showingModal: 'skip'
      };
    } else {
      // Show modal indicating it's wrong and reset the attempt started time
      return {
        ...state,
        currentQuestionIncorrectAttempts: newIncorrectAttempts,
        currentAttemptStartTime: Date.now(),
        showingModal: 'try again'
      };
    }
  }

  // They got it correct.
  // Play the correct sound. show the correct modal and log the results.
  const player = getPlayer();
  const correctSound = 'correct';
  const version = 2 - incorrectAttempts;
  player.playSound(correctSound as soundChoices, version);
  return {
    ...state,
    results: readonlyArrayEntry(state.results, state.currentQuestionIndex, () => ({
      stars: MAX_ATTEMPTS - incorrectAttempts
    })),
    showingModal: 'correct'
  };
}

/** Helper function for dismissing a modal. */
function dismissModal(state: QuizState): QuizState {
  const showingModal = state.showingModal;
  if (showingModal === undefined) {
    console.warn('dismissModal action called with no modal showing - ignoring');
    return state;
  }

  switch (showingModal) {
    case 'incomplete':
    case 'try again':
    case 'exit quiz':
      // Simply dismiss
      return { ...state, showingModal: undefined };

    case 'correct':
    case 'skip':
      return goToNextQuestion(state);
  }
}

/** Helper function to modify the quiz state to show the next question, or finish the quiz. */
function goToNextQuestion(state: QuizState): QuizState {
  // Navigate to the next question
  if (
    state.currentQuestionIndex + 1 <= state.userAnswers.length - 1 ||
    state.previewToken !== undefined
  ) {
    // Clicked submit on non-last question, or in preview mode - show next question
    const newQuestionIndex = state.currentQuestionIndex + 1;
    return {
      ...state,
      currentQuestionIndex: newQuestionIndex,
      currentQuestionIncorrectAttempts: 0,
      currentAttemptStartTime: Date.now(),
      showingModal: undefined,
      // In preview mode, generate the next question
      tokens:
        state.tokens[newQuestionIndex] !== undefined
          ? state.tokens
          : [...state.tokens, questionTokenToSpecificQuestion(state.previewToken!)],
      userAnswers:
        state.userAnswers[newQuestionIndex] !== undefined
          ? state.userAnswers
          : [...state.userAnswers, { userAnswer: {} }],
      results:
        state.results[newQuestionIndex] !== undefined ? state.results : [...state.results, {}]
    };
  } else {
    // Clicked submit on last question - show results screen
    return { ...state, navigateToResultsScreen: true, showingModal: undefined };
  }
}

function questionTokenToSpecificQuestion(trustedQuestionToken: QuestionToken): SpecificQuestion {
  const { questionType, data } = getTrustedQuestion(trustedQuestionToken);
  return [questionType.uid, data ?? questionType.generator()];
}
