import { useEffect, useState } from 'react';
import { Pressable, View, StyleSheet } from 'react-native';
import useLoginStore from '../storage/useLoginStore';
import Text from 'common/src/components/typography/Text';
import { Image } from 'expo-image';
import { colors } from 'common/src/theme/colors';
import { getStudentStars } from '../network/studentData';

export default function HomeMenu({
  stopLoading,
  menuActive
}: {
  stopLoading: () => void;
  menuActive: () => void;
}) {
  const [studentStars, setStudentStars] = useState(0);
  const [error, setError] = useState('');
  const { loggedInUser } = useLoginStore();

  // Get student stars
  const getStars = async () => {
    const classStudentId = loggedInUser?.profile.classStudentId;

    if (!classStudentId) return;

    // Get student stars
    const result = await getStudentStars(classStudentId);

    // Handle errors
    if (result === 'http error' || result === 'network error' || result === 'unknown error') {
      stopLoading();
      return setError(result);
    }

    setStudentStars(result);
  };

  useEffect(() => {
    getStars();
    // Intentionally empty dependency array so that this is run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <View style={styles.menu}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 16
          }}
        >
          <Pressable onPress={menuActive}>
            <Image
              source={require('pupil-app/assets/svg/Menu.svg')}
              style={{ width: 30, height: 30 }}
            />
          </Pressable>
          <Pressable onPress={menuActive}>
            <Text style={styles.menuText}>Menu</Text>
          </Pressable>
        </View>
        <View style={styles.menuProfile}>
          <Text variant="WRN700" style={styles.text}>
            {loggedInUser?.profile?.firstName} {loggedInUser?.profile?.lastName}
          </Text>
          <View style={styles.starsWrapper}>
            <Image
              source={require('../../../../packages/common/src/assets/svg/Star.svg')}
              style={{ width: 40, height: 40 }}
            />
            <Text
              variant="WRN700"
              style={[
                styles.text,
                {
                  // Give container default width to prevent content shifting when loading stars
                  minWidth: 40
                }
              ]}
            >
              {studentStars}
            </Text>
          </View>
        </View>
      </View>
      {/* Errors */}
      <>
        {error && (
          <View style={{ paddingBottom: 8 }}>
            <Text style={styles.text}>Loading student stars - {error}</Text>
          </View>
        )}
      </>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    fontSize: 32
  },
  menu: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 48,
    paddingTop: 20,
    paddingHorizontal: 24
  },
  menuProfile: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 18
  },
  menuText: {
    fontSize: 32,
    color: colors.white,
    fontWeight: '700'
  },
  starsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10
  }
});
