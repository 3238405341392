import { TitleStyleProps } from '../../molecules/TitleRow';
import { View, type StyleProp, type ViewStyle, type TextStyle } from 'react-native';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import BaseLayout from '../../molecules/BaseLayout';
import { isEqualUnordered } from '../../../utils/matchers';
import { SetState } from '../../../utils/react';
import { Selectable } from '../../atoms/Selectable';
import { withStateHOC } from '../../../stateTree';

type Props<T extends string> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  topContent?: JSX.Element;
  leftContent: JSX.Element;
  /** The keys of this object are what we will see in the user answer. The values are the display strings. */
  selectables: Record<T, string>;
  /** Whether multiple selectables can be selected at once. */
  multiSelect?: boolean;
  correctAnswer: T[];

  selectableTextStyle?: StyleProp<TextStyle>;

  /** (PDF-only) how high is the overall question. Must agree with question type definition. */
  questionHeight?: number;
};

export default function QF39ContentWithSelectablesOnRight<T extends string>({
  title,
  pdfTitle = title,
  topContent,
  leftContent,
  selectables,
  correctAnswer,
  selectableTextStyle,
  questionHeight,
  multiSelect,
  ...titleStyleProps
}: Props<T>) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  if (isPdf) {
    return (
      <BaseLayoutPDF
        title={pdfTitle}
        {...titleStyleProps}
        mainPanelContents={
          <>
            {topContent}
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                gap: 16,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {leftContent}
              <Selectables
                state={displayMode === 'markscheme' ? correctAnswer : []}
                selectables={selectables}
                textStyle={[{ fontWeight: '700' }, selectableTextStyle]}
              />
            </View>
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      {...titleStyleProps}
      mainPanelContents={
        <>
          {topContent}
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              gap: 16,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {leftContent}
            <SelectablesWithState
              id="selectables"
              multiSelect={multiSelect}
              testCorrect={isEqualUnordered(correctAnswer)}
              selectables={selectables}
              textStyle={[{ fontWeight: '700' }, selectableTextStyle]}
            />
          </View>
        </>
      }
    />
  );
}

/** Several selectables in a row, with at most one selected at a time. */
export function Selectables<T extends string>({
  state,
  setState,
  selectables,
  multiSelect,
  textStyle,
  style
}: {
  state: T[];
  setState?: SetState<T[]>;
  selectables: Record<T, string>;
  multiSelect?: boolean;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={[{ gap: 24 }, style]}>
      {Object.entries<string>(selectables).map(([id, displayString]) => (
        <Selectable
          key={id}
          selected={state.includes(id as T)}
          toggleSelected={() =>
            setState &&
            setState(ans =>
              ans.includes(id as T)
                ? ans.filter(it => it !== id)
                : multiSelect
                ? [...ans, id as T]
                : [id as T]
            )
          }
          disabled={setState === undefined}
          textStyle={textStyle}
        >
          {displayString}
        </Selectable>
      ))}
    </View>
  );
}

const SelectablesWithState = withStateHOC(Selectables, {
  defaults: { testComplete: ans => ans.length > 0, defaultState: [] }
});
