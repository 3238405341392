import { StudentProfile } from '../utils/studentData';
import { getRequestQuery, isHttpSuccess } from './requests';

export const getStudentStars = async (classStudentId: number) => {
  const studentProfileEndpoint = `/web/infinity/student/${classStudentId}`;
  const result = await getRequestQuery(studentProfileEndpoint, 'json');

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        console.warn('(getStudentStars) Network error:', result.error);
        return 'network error';
      case 'http':
        console.warn('(getStudentStars) HTTP error:', result.response.status, result.error);
        return 'http error';
      case 'unknown':
        console.warn('(getStudentStars) unknown error:', result.error);
        return 'unknown error';
    }
  }

  const { stars } = result.response.data as StudentProfile;

  return stars;
};
