import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF20aBarModelInteractive from '../../../../components/question/questionFormats/QF20aBarModelInteractive';
import TenFrames from '../../../../components/question/representations/TenFrame';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFloats, integerToWord } from '../../../../utils/math';
import { filledArray, range } from '../../../../utils/collections';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { Dimens } from '../../../../theme/scaling';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { createHundredSquareShape } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNE',
  description: 'aNE',
  keywords: ['Tenths', 'Decimals', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;
    return (
      <QF20aBarModelInteractive
        numberOfRows={1}
        numberOfCols={10}
        title={translate.instructions.shadeBarModelToShowX((number1 / 10).toLocaleString())}
        testCorrect={number1}
        fullWidthTopBrace={(1).toLocaleString()}
        braceTextStyle={{
          fontSize: displayMode === 'digital' ? 40 : 50,
          top: displayMode === 'digital' ? -72 : -84
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNF',
  description: 'aNF',
  keywords: ['Ten frame', 'Decimals', 'Place value counters'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    return { number1 };
  },
  Component: ({ question: { number1 }, translate }) => {
    return (
      <QF1ContentAndSentence
        extraSymbol={'decimalPoint'}
        title={translate.instructions.whatIsValueShownOnTenFrame()}
        Content={<TenFrames numberOfCounters={number1} placeValue={'tenths'} />}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={3}
        pdfDirection="column"
        testCorrect={userAnswer => compareFloats(userAnswer[0], number1 / 10)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNG',
  description: 'aNG',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;
    const startNumber = 0;
    const endNumber = 1;
    const tickInterval = 0.1;
    const answer = number1 / 10;
    // Create array to pass to Number Line
    const tickValues = range(startNumber, endNumber, tickInterval).map(number => {
      return number === startNumber || number === endNumber ? number.toLocaleString() : '';
    });
    // Set where the answers should go
    tickValues[number1] = '<ans/>';
    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.fillInMissingNumberOnNumberLine()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        tickValues={tickValues}
        inputMaxCharacters={3}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNH',
  description: 'aNH',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;
    const startNumber = 0;
    const endNumber = 1;
    const tickInterval = 0.1;
    const answer = number1 / 10;
    // Create array to pass to Number Line
    const tickValues = range(startNumber, endNumber, tickInterval).map(number => {
      return number === startNumber || number === endNumber ? number.toLocaleString() : '';
    });
    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowWhereXIsOnTheNumberLine(answer)}
        pdfTitle={translate.instructions.whereIsNumPdf(answer)}
        testCorrect={[answer - 0.01, answer + 0.01]}
        min={startNumber}
        max={endNumber}
        sliderStep={0.1}
        tickValues={tickValues}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aNI',
  description: 'aNI',
  keywords: ['Tenths', 'Decimals', 'Fractions'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(9)).length(3),
    asWords: z.array(z.boolean()).length(3)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 3);
    const asWords1 = getRandomFromArray([true, false]);
    const asWords2 = getRandomFromArray([true, false]);
    const asWords3 = getRandomFromArray([true, false]);
    const asWords = [asWords1, asWords2, asWords3];
    return {
      numbers,
      asWords
    };
  },
  Component: props => {
    const {
      question: { numbers, asWords },
      translate
    } = props;
    const lhsWidth = 300;
    const statements = range(0, 2).map(i => {
      return {
        lhsComponent: (
          <View style={{ width: lhsWidth, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={
                asWords[i]
                  ? `${numbers[i]} ${translate.fractions.tenths(numbers[i])}`
                  : `<frac n='${numbers[i]}' d='10' />`
              }
            />
          </View>
        ),
        correctAnswer: (numbers[i] / 10).toString()
      };
    });
    const answerOptions = range(0, 2).map(i => (numbers[i] / 10).toLocaleString());
    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchTheFractionsWordsAndDecimals()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFracsWordsAndDecimals()}
        items={shuffledAnswerOptions}
        statements={statements}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5V2 = newQuestionContent({
  uid: 'aNI2',
  description: 'aNI',
  keywords: ['Tenths', 'Decimals', 'Fractions'],
  schema: z.object({
    number: z.number().int().min(1).max(9),
    asWords: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const asWords = getRandomBoolean();

    return {
      number,
      asWords
    };
  },
  Component: props => {
    const {
      question: { number, asWords },
      translate
    } = props;
    const lhsWidth = 300;
    const statements = [
      {
        lhsComponent: (
          <View style={{ width: lhsWidth, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={
                asWords
                  ? `${number} ${translate.fractions.tenths(number)}`
                  : `<frac n='${number}' d='10' />`
              }
            />
          </View>
        ),
        correctAnswer: (number / 10).toString()
      }
    ];

    const answerOptions = [
      (number / 10).toLocaleString(),
      (number / 100).toLocaleString(),
      number.toLocaleString(undefined, { minimumSignificantDigits: 2 }),
      (number + number / 10).toLocaleString()
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardToMatchTheEquivalentDecimal()}
        pdfTitle={translate.instructions.useTheCardToMatchTheEquivalentDecimal()}
        items={shuffledAnswerOptions}
        statements={statements}
        questionHeight={900}
        useArrows={false}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aNJ',
  description: 'aNJ',
  keywords: ['Tenths', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(1).max(10),
    number3: z.number().int().min(1).max(10),
    number4: z.number().int().min(1).max(10),
    correctAnswer: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(1, 10);
    const number3 = randomIntegerInclusive(1, 10);
    const number4 = randomIntegerInclusive(1, 10);
    const correctAnswer = getRandomFromArray([number1, number2, number3, number4]);
    return { number1, number2, number3, number4, correctAnswer };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, correctAnswer },
      translate
    } = props;
    // find all correct answers
    const correctAnswers: string[] = [];
    if (correctAnswer === number1) correctAnswers.push('A');
    if (correctAnswer === number2) correctAnswers.push('B');
    if (correctAnswer === number3) correctAnswers.push('C');
    if (correctAnswer === number4) correctAnswers.push('D');
    // Ten Frame
    const item1 = {
      value: 'A',
      component: <TenFrames numberOfCounters={number1} placeValue={'tenths'} />
    };
    // Number Line
    const startingNumber = 0;
    const endNumber = 1;
    const tickValues = filledArray('', 11);
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[10] = endNumber.toLocaleString();
    const item2 = (dimens: Dimens) => {
      return {
        value: 'B',
        component: (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number2 / 10}
            blueArrow
          />
        )
      };
    };
    const item3 = (dimens: Dimens) => {
      return {
        value: 'C',
        component: (
          <ShadedFractionBarModel
            totalSubSections={10}
            totalPerSection={1}
            height={50}
            coloredSections={range(0, number3 - 1)}
            width={dimens.width - 20}
          />
        )
      };
    };
    const item4 = (dimens: Dimens) => {
      return {
        value: 'D',
        component: (
          <DisplayShapeOnGrid givenShape={createHundredSquareShape(number4 * 10)} dimens={dimens} />
        )
      };
    };
    return (
      <QF11SelectImagesUpTo4
        title={
          correctAnswers.length > 1
            ? translate.instructions.selectImagesThatShow(
                `${integerToWord(correctAnswer)} ${translate.fractions.tenths(correctAnswer)}`
              )
            : translate.instructions.selectImageThatShows(
                `${integerToWord(correctAnswer)} ${translate.fractions.tenths(correctAnswer)}`
              )
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.instructions.circleImagesThatShow(
                `${integerToWord(correctAnswer)} ${translate.fractions.tenths(correctAnswer)}`
              )
            : translate.instructions.circleImageThatShows(
                `${integerToWord(correctAnswer)} ${translate.fractions.tenths(correctAnswer)}`
              )
        }
        numItems={4}
        testCorrect={correctAnswers}
        multiSelect
        renderItems={({ dimens }) =>
          shuffle([item1, item2(dimens), item3(dimens), item4(dimens)], {
            random: seededRandom(props.question)
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TenthsAsDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5V2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
