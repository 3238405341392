// Four operations symbols
export const ADD = '+';
export type ADD = typeof ADD;
export const SUB = '\u2212';
export type SUB = typeof SUB;
export const DIV = '\u00F7';
export type DIV = typeof DIV;
export const MULT = '\u00D7';
export type MULT = typeof MULT;

// Other symbols
export const DECIMAL_POINT = '.';
export type DECIMAL_POINT = typeof DECIMAL_POINT;
export const APPROX_EQUAL = '\u2248';
export type APPROX_EQUAL = typeof APPROX_EQUAL;
export const DEGREES = '°';
export type DEGREES = typeof DEGREES;

// HTML-escaped symbols
export const LESS_THAN = '&lt;';
export type LESS_THAN = typeof LESS_THAN;
export const GREATER_THAN = '&gt;';
export type GREATER_THAN = typeof GREATER_THAN;

// Algebraic symbols
export const ALGEBRAIC_A = '𝑎';
export type ALGEBRAIC_A = typeof ALGEBRAIC_A;
export const ALGEBRAIC_B = '𝑏';
export type ALGEBRAIC_B = typeof ALGEBRAIC_B;
export const ALGEBRAIC_C = '𝑐';
export type ALGEBRAIC_C = typeof ALGEBRAIC_C;
export const ALGEBRAIC_D = '𝑑';
export type ALGEBRAIC_D = typeof ALGEBRAIC_D;
export const ALGEBRAIC_H = 'ℎ';
export type ALGEBRAIC_H = typeof ALGEBRAIC_H;
export const ALGEBRAIC_L = '𝑙';
export type ALGEBRAIC_L = typeof ALGEBRAIC_L;
export const ALGEBRAIC_M = '𝑚';
export type ALGEBRAIC_M = typeof ALGEBRAIC_M;
export const ALGEBRAIC_N = '𝑛';
export type ALGEBRAIC_N = typeof ALGEBRAIC_N;
export const ALGEBRAIC_P = '𝑝';
export type ALGEBRAIC_P = typeof ALGEBRAIC_P;
export const ALGEBRAIC_S = '𝑠';
export type ALGEBRAIC_S = typeof ALGEBRAIC_S;
export const ALGEBRAIC_T = '𝑡';
export type ALGEBRAIC_T = typeof ALGEBRAIC_T;
export const ALGEBRAIC_W = '𝑤';
export type ALGEBRAIC_W = typeof ALGEBRAIC_W;
export const ALGEBRAIC_X = '𝑥';
export type ALGEBRAIC_X = typeof ALGEBRAIC_X;
export const ALGEBRAIC_Y = '𝑦';
export type ALGEBRAIC_Y = typeof ALGEBRAIC_Y;
export const ALGEBRAIC_Z = '𝑧';
export type ALGEBRAIC_Z = typeof ALGEBRAIC_Z;

// Backspace
export const BACKSPACE = '\u232B';
export type BACKSPACE = typeof BACKSPACE;
