import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

// Animals
export const animalNames = ['Sheep', 'Horses', 'Pigs', 'Goats', 'Cows'] as const;

export const animalSchema = z.enum(animalNames);

export type AnimalName = (typeof animalNames)[number];

export const animalAsWord = (object: AnimalName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Sheep':
      return translate.animals.Sheep();
    case 'Horses':
      return translate.animals.Horses();
    case 'Pigs':
      return translate.animals.Pigs();
    case 'Goats':
      return translate.animals.Goats();
    case 'Cows':
      return translate.animals.Cows();
  }
};

export function getRandomAnimal() {
  return getRandomFromArray(animalNames);
}
export function getRandomUniqueAnimals(numberOfAnimals: number) {
  return getRandomSubArrayFromArray([...animalNames] as const, numberOfAnimals);
}
