import { errorMessages } from './errorMessages';
import SentryLogger from './sentry';
import { type ErrorMessages, type SeverityLevel } from './types';

/**
 * Format error messages and log to console or Sentry,
 * based on context of the environment and severity level.
 */
export default class Logger {
  private static formatMessage = (
    functionName: string,
    message: string | ((x: string) => string),
    additionalDetails?: string
  ) => {
    return `(${functionName}) ${
      typeof message === 'function' ? message(additionalDetails ?? '') : message
    }`;
  };

  private static logToSentry(
    level: SeverityLevel,
    message: string,
    eventData?: Record<string, unknown>
  ) {
    // Most logs we will just captureEvent, this allows more information to be captured.
    if (level !== 'info') {
      SentryLogger.captureEvent(message, level, eventData);
    } else {
      SentryLogger.captureMsg(message);
    }
  }

  /** General-use log function */
  public static captureEvent<K extends keyof ErrorMessages>(
    level: SeverityLevel,
    functionName: K,
    messageKey: keyof ErrorMessages[K],
    additionalInfo: {
      additionalMsg?: string;
      eventData?: Record<string, unknown>;
    }
  ) {
    // Fetch and format message
    const msgContent = errorMessages[functionName][messageKey];
    const msg = Logger.formatMessage(
      functionName,
      msgContent as string | ((x: string) => string),
      additionalInfo.additionalMsg
    );

    // TODO: bring back sentry logging
    Logger.logToSentry(level, msg, additionalInfo.eventData);
  }
}
