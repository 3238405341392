import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import Svg, { Line } from 'react-native-svg';
import { Dimens } from 'common/src/theme/scaling';
import { range } from 'common/src/utils/collections';
import { numberToBase10Object } from 'common/src/utils/math';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../Base Ten/BaseTenRepresentations';
import { colors } from '../../../../theme/colors';
import TextStructure from '../../../molecules/TextStructure';
import { DisplayMode } from '../../../../contexts/displayMode';

export type TextPartition = (number | string | '$ans')[]; // Note this format can use the '<frac/>' notation, but must still use '$ans' for answer boxes.
export type Base10Partition = (number | '$ans')[]; // Note that string items that aren't '$ans' are not allowed.
export interface Base10 {
  number: number;
  representation: 'base10';
}

type Props = {
  top: number | string | Base10; // Note this format can use the '<frac/>' notation, but must still use '$ans' for answer boxes.
  dimens: Dimens;
  userAnswer?: string[];
  isInteractive?: boolean;
  variation?: 'topDown' | 'bottomUp' | 'leftRight';
  fractionTextStyle?: StyleProp<TextStyle>;
  onTextInput?: (answer: string, index: number) => void;
} & (
  | {
      representation?: 'text';
      partition: TextPartition;
    }
  | {
      representation: 'base10';
      partition: Base10Partition;
    }
);

export const PartWholeModel = ({
  top,
  representation = 'text',
  partition,
  dimens: { width, height },
  userAnswer = [''],
  variation = 'topDown',
  fractionTextStyle,
  onTextInput = () => {
    /* do nothing */
  }
}: Props) => {
  // Check if top prop is an object and matches Base10 type (representation, number)
  const isBase10Object = (obj: string | number | Base10): obj is Base10 => {
    if (typeof obj !== 'object') return false;

    return 'representation' in obj && 'number' in obj;
  };

  const displayMode = useContext(DisplayMode);

  // Calculate the positions
  const circleDiameter = displayMode === 'digital' ? Math.min(180, height / 2 - 15) : 300;

  const usableBase10Space = circleDiameter - 30;
  const numCircles = partition.length;
  const verticalPad = 10;
  let wholeCircleY = verticalPad;
  let wholeCircleX = width / 2 - circleDiameter / 2;
  let partCircleY = range(1, numCircles).map(_ => height - circleDiameter - verticalPad);
  const xSpaceBetween = (width - circleDiameter * numCircles) / (numCircles + 1);
  let partCircleX = range(1, numCircles).map(index => {
    return xSpaceBetween * index + circleDiameter * (index - 1);
  });

  // Inverting the positions
  if (variation === 'bottomUp') {
    [wholeCircleY, partCircleY] = [partCircleY[0], range(1, numCircles).map(_ => wholeCircleY)];
  }
  if (variation === 'leftRight') {
    wholeCircleX = wholeCircleY + verticalPad;
    wholeCircleY = height / 2 - circleDiameter / 2;

    partCircleX = range(1, numCircles).map(_ => width - circleDiameter - verticalPad);
    partCircleY = range(1, numCircles).map(
      (_, index) => (height / numCircles) * index + verticalPad
    );
  }

  const styles = useMemo(
    () => getStyles(width, height, circleDiameter, displayMode),
    [width, height, circleDiameter, displayMode]
  );

  // Create the lines
  const lines = range(1, numCircles).map((_, index) => {
    return (
      <Line
        x1={partCircleX[index] + circleDiameter / 2}
        y1={partCircleY[index] + circleDiameter / 2}
        x2={wholeCircleX + circleDiameter / 2}
        y2={wholeCircleY + circleDiameter / 2}
        stroke={displayMode === 'digital' ? colors.prussianBlue : 'black'}
        strokeWidth={displayMode === 'digital' ? 3 : 6}
        key={'Line' + index}
      />
    );
  });

  // Calculate the scale to ensure it is the same across all circles
  const base10Elements: number[] = [];

  // Collect all the base 10 elements from the partition prop
  if (representation === 'base10') {
    (partition as Base10Partition).forEach(x => {
      if (x !== '$ans') {
        base10Elements.push(x);
      }
    });
  }
  // Add the top prop if it's a base 10 element
  if (isBase10Object(top)) {
    const { number } = top;
    base10Elements.push(number);
  }

  const base10ElementsScale = base10Elements.map(
    x =>
      BaseTenRepCalcGridsAndScale(
        usableBase10Space,
        usableBase10Space,
        numberToBase10Object(x),
        'Cubes'
      ).scale
  );

  const scale = base10ElementsScale.length > 0 ? Math.min(...base10ElementsScale) : 1;

  // Create the circles
  let ansIndex = -1;
  const circles = partition.map((part, index) => {
    if (part === '$ans') {
      ansIndex += 1;
      // Created a scoped copy otherwise all ansIndex's end up as the final index
      const i = ansIndex;
      return (
        <View
          style={[
            styles.circle,
            {
              top: partCircleY[index],
              left: partCircleX[index],
              backgroundColor: colors.white,
              // Should not be able to see the circle border underneath the answer box at all:
              borderColor: 'transparent'
            }
          ]}
          key={'input' + ansIndex + (numCircles - 1)}
        >
          <NoKeyboardTextInput
            value={userAnswer[ansIndex]}
            onChangeText={text => onTextInput(text, i)}
            style={styles.answerCircle}
          />
        </View>
      );
    }

    let partComp = null;
    switch (representation) {
      case 'text':
        partComp = (
          <TextStructure
            textStyle={styles.circleText}
            fractionTextStyle={[styles.circleText, fractionTextStyle]}
            fractionDividerStyle={styles.fractionDivider}
            sentence={typeof part === 'number' ? part.toLocaleString() : part}
          />
        );
        break;
      case 'base10':
        partComp = (
          <BaseTenRepresentation
            // Casting to number is safe here because in the case where representation === 'base10', each of
            // the number are of type number | '$ans', and the '$ans' case has already been checked.
            b10Rep={{
              variant: 'Cubes',
              numbers: numberToBase10Object(part as number),
              arrangement: 'ltr'
            }}
            usableWidth={usableBase10Space}
            usableHeight={usableBase10Space}
            align={'center'}
            scale={scale}
          />
        );
        break;
    }
    return (
      <View
        style={[styles.circle, { top: partCircleY[index], left: partCircleX[index] }]}
        key={'text' + index}
      >
        {partComp}
      </View>
    );
  });

  const renderTopWithAns = () => {
    ansIndex += 1;
    // Created a scoped copy otherwise all ansIndex's end up as the final index
    const i = ansIndex;

    return (
      <NoKeyboardTextInput
        value={userAnswer[ansIndex]}
        onChangeText={text => onTextInput(text, i)}
        style={styles.answerCircle}
      />
    );
  };

  const renderTopWithBase10 = (top: Base10) => {
    const { number } = top;
    return (
      <BaseTenRepresentation
        b10Rep={{
          variant: 'Cubes',
          numbers: numberToBase10Object(number),
          arrangement: 'ltr'
        }}
        usableWidth={usableBase10Space}
        usableHeight={usableBase10Space}
        align={'center'}
        scale={scale}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ position: 'absolute', top: 0, left: 0 }}
        pointerEvents={'none'}
      >
        {lines}
      </Svg>

      <View
        style={[
          styles.circle,
          {
            top: wholeCircleY,
            left: wholeCircleX,
            borderColor:
              top === '$ans'
                ? 'transparent'
                : displayMode === 'digital'
                ? colors.prussianBlue
                : 'black'
          }
        ]}
      >
        {top === '$ans' ? (
          renderTopWithAns()
        ) : isBase10Object(top) ? (
          renderTopWithBase10(top)
        ) : (
          <TextStructure
            textStyle={styles.circleText}
            fractionTextStyle={[styles.circleText, fractionTextStyle]}
            fractionDividerStyle={styles.fractionDivider}
            sentence={top.toLocaleString()}
          />
        )}
      </View>
      {circles}
    </View>
  );
};

const getStyles = (
  width: number,
  height: number,
  circleDiameter: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height
    },

    circle: {
      borderWidth: 2,
      borderRadius: 999,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : 'black',
      padding: 10,
      width: circleDiameter,
      height: circleDiameter,
      textAlign: 'center',
      justifyContent: 'center',
      textAlignVertical: 'center',
      alignItems: 'center',
      alignContent: 'center',
      lineHeight: 80,
      backgroundColor: colors.white,
      position: 'absolute'
    },

    circleText: {
      fontSize: displayMode === 'digital' ? 32 : 50,
      fontWeight: '700',
      lineHeight: displayMode === 'digital' ? 48 : 75
    },

    fractionDivider: {
      marginVertical: 0
    },

    answerCircle: {
      width: circleDiameter,
      height: circleDiameter,
      borderRadius: 999,
      fontWeight: '700'
    }
  });
