import { StyleProp, View, TextStyle, ViewStyle } from 'react-native';
import { filledArray } from 'common/src/utils/collections';
import { useContext } from 'react';
import { Theme } from 'common/src/theme';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import Text from '../../typography/Text';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import EasyDragAndDropWithGrid from '../../draganddrop/EasyDragAndDropWithGrid';
import { isEqualUnordered, isNotEqual } from '../../../utils/matchers';
import { DraggableVariant } from '../../draganddrop/utils';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * The correct grouping of values. The 3D array goes in order: rows, columns, items.
   * Must agree with the number of rows and columns, as defined by `rowNames` and `columnNames`.
   *
   * Note that values will be compared by identity (i.e. ===), so make sure that the
   * values are primitives (e.g. string or number), or they are references to the same objects as those passed into
   * the other prop `items`. Order of each sub-array doesn't matter.
   *
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect: T[][][] | ((userAnswer: T[][][]) => boolean);
  /**
   * Optional custom mark scheme answer (with items). If you provide testCorrect as a function, you _must_ provide
   * this. If you provide testCorrect as an array, you don't need to provide this.
   *
   * Either a text string to display, or an array of rows, each with an array of columns, each with an array of
   * correct answers to go in that zone.
   */
  markSchemeAnswer?: T[][][] | string;

  /** Shape of the items. Default: square. */
  itemVariant?: DraggableVariant;
  pdfItemVariant?: DraggableVariant;

  /** The items to show as draggables. Each item can be passed as a plain string or number. */
  items: ((T & (string | number)) | ItemInfo<T>)[];
  // used if items contains plain strings or numbers
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;

  /** Default: endWide. */
  actionPanelVariant?: 'end' | 'endWide' | 'endMid';

  /** Default: move. */
  moveOrCopy?: 'move' | 'copy';

  /** The names of each column. */
  columnNames: string[];
  /** The names of each row. */
  rowNames: string[];
  /**
   * How many items fit into each zone. Default: 9.
   * Note: ideally this could be worked out for you from the zone's size and the item's size, but we don't have
   * access to that information without introducing a MeasureView, which would negatively impact performance.
   */
  zoneCapacity?: number;
  /** Default: font size 25 */
  tableHeaderStyle?: StyleProp<TextStyle>;
  pdfTableHeaderStyle?: StyleProp<TextStyle>;
  /**
   * Styling to be applied only to the headers on the side of the table. Will override style of the same type passed into pdfTableHeaderStyle.
   */
  pdfTableSideHeaderStyle?: StyleProp<TextStyle>;
  /** Default: 1 */
  tableHeaderMaxLines?: number;
  questionHeight?: number;
};

/**
 * Question Format 9: Drag Into Table of up to 6 Groups
 *
 * Title at the top, draggables on the right, and 2x2 table of positions to drag them into on the left.
 *
 * This is a many-4 drag and drop.
 *
 * No hard restriction on the number of draggables.
 *
 * Note: this can support more than 4 groups, e.g. 3 columns and 2 rows.
 * Limitation: the labels for the rows do not line wrap very well, if using more than 2 rows, try to keep the titles
 * down to one line.
 */
export default function QF9DragIntoTableOfGroups<T>({
  title,
  pdfTitle,
  testCorrect,
  markSchemeAnswer,
  items: itemsProp,
  columnNames,
  rowNames,
  zoneCapacity = 6,
  tableHeaderStyle,
  pdfTableHeaderStyle,
  pdfTableSideHeaderStyle,
  tableHeaderMaxLines = 1,
  actionPanelVariant = 'endWide',
  moveOrCopy = 'move',
  itemVariant = 'square',
  pdfItemVariant,
  itemsTextVariant = 'WRN700',
  itemsLetterEmWidth,
  itemsMaxLines,
  questionHeight,
  ...props
}: Props<T>) {
  if (typeof testCorrect === 'function' && markSchemeAnswer === undefined) {
    throw new Error('testCorrect is a function, so you must provide the exampleCorrectAnswer prop');
  }

  const displayMode = useContext(DisplayMode);

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const numRows = rowNames.length;
  const numColumns = columnNames.length;
  const emptyState = filledArray(filledArray([], numColumns), numRows);
  const columnsPerRow = filledArray(numColumns, numRows);
  const itemStyle =
    actionPanelVariant === 'endMid' ? ({ justifyContent: 'center' } as StyleProp<ViewStyle>) : {};
  const dropSource = (
    <DragAndDropSection
      style={displayMode !== 'digital' && { paddingBottom: 32 }}
      itemsStyle={[displayMode !== 'digital' && { justifyContent: 'center' }, itemStyle]}
    >
      {items.map((_item, index) => (
        <EasyDragAndDropWithGrid.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );
  const dragZones = (
    <>
      {/* column headers */}
      <View style={{ height: displayMode === 'digital' ? 56 : 78, flexDirection: 'row' }}>
        <View style={{ width: displayMode === 'digital' ? 56 : 78 }} />
        {columnNames.map((columnName, columnIndex) => (
          <View
            key={columnIndex}
            style={{
              backgroundColor: displayMode === 'digital' ? colors.pacificBlue600 : colors.greys100,
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              marginHorizontal: 3,
              borderColor: displayMode === 'digital' ? undefined : colors.black,
              borderWidth: displayMode === 'digital' ? 0 : 4,
              paddingHorizontal: 12
            }}
          >
            <Text
              style={[
                displayMode === 'digital' && {
                  fontSize: 25,
                  color: 'white'
                },
                tableHeaderStyle,
                displayMode !== 'digital' && pdfTableHeaderStyle
              ]}
              variant="WRN400"
              numberOfLines={tableHeaderMaxLines}
            >
              {columnName}
            </Text>
          </View>
        ))}
      </View>

      {rowNames.map((rowName, rowIndex) => (
        <View key={rowIndex} style={{ flex: 1, flexDirection: 'row' }}>
          {/* row header */}
          <View
            style={{
              width: displayMode === 'digital' ? 56 : 78,
              backgroundColor: displayMode === 'digital' ? colors.pacificBlue600 : colors.greys100,
              alignItems: 'center',
              justifyContent: 'center',
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              marginVertical: 3,
              borderColor: displayMode === 'digital' ? undefined : colors.black,
              borderWidth: displayMode === 'digital' ? 0 : 4
            }}
          >
            <View
              style={{
                position: 'absolute',
                transform: [{ rotate: '-90deg' }],
                width: displayMode === 'digital' ? 222 : 444, // Note: Works well for a 2x2 grid, but may be a bit rubbish for other sizes
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 12
              }}
            >
              <Text
                style={[
                  displayMode === 'digital' && {
                    fontSize: 25,
                    color: 'white'
                  },
                  { textAlign: 'center' },
                  tableHeaderStyle,
                  displayMode !== 'digital' && pdfTableHeaderStyle,
                  displayMode !== 'digital' && pdfTableSideHeaderStyle
                ]}
                variant="WRN400"
                numberOfLines={tableHeaderMaxLines}
              >
                {rowName}
              </Text>
            </View>
          </View>

          {columnNames.map((_, columnIndex) => {
            const isTopEdge = rowIndex === 0;
            const isBottomEdge = rowIndex === numRows - 1;
            const isLeftEdge = columnIndex === 0;
            const isRightEdge = columnIndex === numColumns - 1;

            return (
              <View
                key={columnIndex}
                style={{
                  flex: 1,
                  marginLeft: !isLeftEdge ? -3 : 0,
                  marginTop: !isTopEdge ? -3 : 0,
                  // Used to hide borders, when combined by a negative top/left margin in its child
                  overflow: 'hidden'
                }}
              >
                <EasyDragAndDropWithGrid.ZoneMultiple
                  row={rowIndex}
                  column={columnIndex}
                  capacity={zoneCapacity}
                  style={[
                    {
                      flex: 1,
                      borderRadius: 0,
                      borderStyle: displayMode === 'digital' ? 'dashed' : 'solid'
                    },
                    // Hide some borders. Note that we can't use borderTopWidth or borderLeftWidth on native as these are
                    // broken with borderStyle: 'dashed'. Instead we use negative margins to hide borders.
                    isTopEdge && {
                      // Hide the border which is up against the top title row.
                      marginTop: -3
                    },
                    isLeftEdge && {
                      // Hide the border which is up against the left title row.
                      marginLeft: -3
                    },
                    !isBottomEdge && {
                      // Hide the bottom border, as this is covered by the cell below.
                      marginBottom: -3
                    },
                    !isRightEdge && {
                      // Hide the right border, as this is covered by the cell to the right.
                      marginRight: -3
                    },
                    isRightEdge &&
                      isBottomEdge && {
                        // This is the only bit of border with a curved corner
                        borderBottomRightRadius: 8
                      }
                  ]}
                />
              </View>
            );
          })}
        </View>
      ))}
    </>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    if (typeof markSchemeAnswer === 'string') {
      // Mark scheme is just a string - just render a string
      return renderMarkSchemeProp(markSchemeAnswer);
    }

    // Mark scheme a table showing values - figure out what those values are
    const isNumber = (x: unknown): x is number => typeof x === 'number';
    let defaultState: T[][][];
    if (markSchemeAnswer !== undefined) {
      defaultState = markSchemeAnswer;
    } else {
      defaultState = (testCorrect as T[][][]).map(row =>
        row.map(zone =>
          // Make a best effort to sort - if they're all numbers then sort numerically, else lexicographically
          [...zone].sort(zone.every(isNumber) ? (a, b) => (a as number) - (b as number) : undefined)
        )
      );
    }

    return (
      <EasyDragAndDropWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        defaultState={displayMode === 'markscheme' ? defaultState : undefined}
        variant={pdfItemVariant ?? itemVariant}
        columnsPerRow={columnsPerRow}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              {displayMode === 'pdf' ? (
                dropSource
              ) : (
                <View
                  style={{
                    height: 166 * Math.ceil(items.length / 5) // Calculation for finding height of empty space above table where the draggables usually are (5 per line)
                  }}
                ></View>
              )}
              {dragZones}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={emptyState}
      // Complete if at least one zone has at least one item.
      testComplete={isNotEqual(emptyState)}
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : state =>
              state.every((row, rowIndex) =>
                row.every((zone, columnIndex) =>
                  isEqualUnordered(testCorrect[rowIndex][columnIndex])(zone)
                )
              )
      }
      columnsPerRow={columnsPerRow}
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={dropSource}
        mainPanelContents={dragZones}
        {...props}
      />
    </EasyDragAndDropWithGrid.ProviderWithState>
  );
}
