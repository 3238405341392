import { useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import { isEqual } from '../../../utils/matchers';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { Theme } from '../../../theme';
import { FunctionMachines } from '../representations/FunctionMachines';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  /**
   * Title at the top of the question on PDFs.
   */
  pdfTitle?: string;
  /**
   * An array of arrays representing each function machine.
   * Every array within this rowsOfBoxes array represents a separate function machine.
   * Within each inner array, each string will correspond to the contents displayed in each text box in the same order.
   * If '<ans/>' is passed, the box will become an answer input box in this place.
   */
  rowsOfBoxes: string[][];
  /** By default, this is an array of array of empty strings '' matching the number of <ans/> in the rows of function machines. */
  initialState?: string[];
  /**
   * If sending in as an array the answers should be [..topRowLeftToRight, ...bottomRowLeftToRight]
   */
  testCorrect:
    | string[]
    | {
        length: number;
        testFunc: (ans: (T | undefined)[]) => boolean;
      };
  /** Height of the boxes in the function machine. Optional, defaults to 150. */
  boxHeight?: number;
  /** Width of the boxes in the function machine. Optional, defaults to 150. */
  boxWidth?: number;
  /**
   * Indexes of columns that are to be shared across the entire function machine.
   * Box will show the contents of this index from the last row - entries in this index in other rows will be ignored.
   */
  sharedColumns?: number[];
  actionPanelVariant?: 'end' | 'endWide' | 'bottom';
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;
  items: ((T & string) | ItemInfo<T>)[];
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

/**
 * Question Format 28a: Function Machines with Draggables.
 *
 * Title at the top. Displays function machines as a series of boxes, any of which could contain an answer box.
 * Multiple function machines can be passed as separate arrays within the rowsOfBoxes array.
 * The first box of each function machine is labelled 'Input', and the last box is labelled 'Output', with right-facing arrows
 * in between each box in a function machine.
 *
 * Answers are marked from left-to-right, top-to-bottom, except any that come from sharedColumns; those are always treated as part
 * of the last row, where its content/answer boxes are retrieved from.
 */
export default function QF28aFunctionMachinesDraggable<T extends string | number>({
  title,
  pdfTitle,
  rowsOfBoxes,
  initialState,
  itemsTextVariant = 'WRN700',
  items: itemsProp,
  itemsLetterEmWidth,
  itemsMaxLines,
  testCorrect: testCorrectProp,
  boxHeight,
  boxWidth,
  sharedColumns = [],
  actionPanelVariant = 'endWide',
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item, value: item }
  );

  const draggableInputBox = (
    _rowIndex: number,
    _currentBoxIndex: number,
    _boxWidth: number,
    _boxHeight: number,
    index = 0
  ) => {
    return (
      <>
        {displayMode === 'markscheme' && (
          <AssetSvg
            name="True"
            width={50}
            style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
          />
        )}
        <EasyDragAndDropWithSingleZones.ZoneSingle
          style={[
            {
              width: isPdf ? 150 : 96,
              minHeight: isPdf ? 150 : 96
            }
          ]}
          id={index}
          key={index}
        />
      </>
    );
  };

  const draggableSource = isPdf ? (
    <DragAndDropSectionPDF
      style={{ flex: 0.2 }}
      itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap', justifyContent: 'center' }}
    >
      {items.map((_item, index) => (
        <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
      ))}
    </DragAndDropSectionPDF>
  ) : (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((_item, index) => (
        <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer = Array.isArray(testCorrectProp)
      ? testCorrectProp
      : customMarkSchemeAnswer?.answersToDisplay;

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="dragandgrop"
        items={items}
        textVariant={itemsTextVariant}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
        variant="pdfSquare"
        moveOrCopy="copy"
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          questionHeight={questionHeight}
          mainPanelContents={
            <>
              {draggableSource}
              <MeasureView>
                {dimens => (
                  <FunctionMachines
                    inputBox={draggableInputBox}
                    rowsOfBoxes={rowsOfBoxes}
                    sharedColumns={sharedColumns}
                    boxWidth={boxWidth}
                    boxHeight={boxHeight}
                    draggableWidths
                    dimens={dimens}
                  />
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="dragandgrop"
      items={items}
      testCorrect={testCorrect}
      textVariant={itemsTextVariant}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={draggableSource}
        title={title}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <FunctionMachines
                inputBox={draggableInputBox}
                rowsOfBoxes={rowsOfBoxes}
                sharedColumns={sharedColumns}
                boxWidth={boxWidth}
                boxHeight={boxHeight}
                draggableWidths
                dimens={dimens}
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}
