import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { useCallback, useContext, useMemo } from 'react';
import UserInput, { ExtraSymbols, UserInputEvent } from '../../molecules/UserInput';
import BaseLayout from '../../molecules/BaseLayout';
import { CompleteTheSentenceWithState } from '../../molecules/CompleteTheSentence';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { parseMarkup } from '../../../markup';
import { addWhitespacePadding, parseSymbolsToString } from '../../../utils/parse';
import { SYMBOLS } from '../../../utils/parse';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type UserAnswer = string[];

type Props = TitleStyleProps & {
  title: string | JSX.Element;
  /** Sentence to complete. */
  sentence: string;
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  mainPanelContainerStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** Default: numpad. */
  inputType?: UserInputEvent['inputType'];
  /** By default, this will be set to an array of empty strings '' matching the number of <ans/> in sentence. */
  initialState?: UserAnswer;
  /** By default, just checks all strings in the user answer are non-empty. */
  testComplete?: (userAnswer: UserAnswer) => boolean;
  /** Can provide an array of correct answers, or a function for finer control. */
  testCorrect: UserAnswer | ((userAnswer: UserAnswer) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  extraSymbol?: ExtraSymbols;
  /** Whitespace props */
  whitespacePadding?: boolean;
  numberOfSpaces?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
  actionPanelVariant?: 'bottomTall' | 'endWide';
  /** Props for extra content modal */
  promptButton?: string | JSX.Element;
  modalTitle?: string;
  modalContent?: JSX.Element;
};

/**
 * Layout containing a title, numpad and a single sentence in the middle of the remaining space.
 */
export default function QF2AnswerBoxOneSentence({
  title,
  sentence,
  sentenceStyle,
  textStyle,
  inputStyle,
  inputType = 'numpad',
  initialState,
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  inputMaxCharacters: inputMaxCharactersProp,
  extraSymbol,
  whitespacePadding = SYMBOLS.some(symbol => sentence.includes(symbol)),
  numberOfSpaces = 2,
  mainPanelContainerStyle,
  fractionContainerStyle,
  fractionTextStyle,
  actionPanelVariant = 'endWide',
  promptButton,
  modalTitle,
  modalContent,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles();

  // Add whitespace around all symbols
  if (whitespacePadding) {
    sentence = addWhitespacePadding(sentence, numberOfSpaces);
  }

  // Default initialState
  const { numberOfAns } = useMemo(() => parseMarkup(sentence), [sentence]);
  initialState = initialState ?? filledArray('', numberOfAns);

  // Default testComplete
  const testComplete = useCallback(
    (userAnswer: UserAnswer) => {
      if (testCompleteProp !== undefined) {
        return testCompleteProp(userAnswer);
      } else {
        return userAnswer.every(it => it.length > 0);
      }
    },
    [testCompleteProp]
  );

  // Handle testCorrect
  const testCorrect = useCallback(
    (userAnswer: UserAnswer) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp);
      }
    },
    [testCorrectProp]
  );

  // Try to make the input boxes a suitable width.
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={title}
        containerStyle={mainPanelContainerStyle}
        mainPanelContents={
          <CompleteTheSentenceWithState
            id="sentence"
            sentence={sentence}
            autoFocus
            inputMaxCharacters={inputMaxCharacters}
            inputStyle={inputStyle}
            style={[styles.sentence, sentenceStyle]}
            textStyle={textStyle}
            fractionContainerStyle={fractionContainerStyle}
            fractionTextStyle={fractionTextStyle}
          />
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={title}
        containerStyle={mainPanelContainerStyle}
        mainPanelContents={
          <>
            <CompleteTheSentenceWithState
              id="sentence"
              sentence={sentence}
              autoFocus
              inputMaxCharacters={inputMaxCharacters}
              inputStyle={inputStyle}
              style={[styles.sentence, sentenceStyle]}
              textStyle={[styles.markSchemeText, textStyle]}
              defaultState={
                typeof testCorrectProp === 'function'
                  ? customMarkSchemeAnswer?.answersToDisplay
                  : testCorrectProp.map(ans => {
                      // Temporary variable to convert simple string to localized string
                      const temp = Number(parseSymbolsToString(ans));
                      return temp.toLocaleString();
                    })
              }
              fractionContainerStyle={fractionContainerStyle}
              fractionTextStyle={fractionTextStyle}
            />
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContainerStyle={mainPanelContainerStyle}
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput
          variant={actionPanelVariant === 'bottomTall' ? 'wide' : 'tall'}
          inputType={inputType}
          extraSymbol={extraSymbol}
        />
      }
      mainPanelContents={
        <CompleteTheSentenceWithState
          id="sentence"
          defaultState={initialState}
          testCorrect={testCorrect}
          testComplete={testComplete}
          sentence={sentence}
          autoFocus
          inputMaxCharacters={inputMaxCharacters}
          inputStyle={inputStyle}
          style={[styles.sentence, sentenceStyle]}
          textStyle={textStyle}
          fractionContainerStyle={fractionContainerStyle}
          fractionTextStyle={fractionTextStyle}
        />
      }
      {...props}
    />
  );
}

const useStyles = () => {
  return StyleSheet.create({
    sentence: {
      justifyContent: 'center'
    },
    markSchemeText: {
      fontSize: 40
    }
  });
};
