import { z } from 'zod';
import { getRandomSubArrayFromArray } from '../random';

export const brokenTriangles = [
  'Broken_shapes/triangle_RA_break_1',
  'Broken_shapes/triangle_RA_break_2',
  'Broken_shapes/triangle_break_1',
  'Broken_shapes/triangle_break_2',
  'Broken_shapes/triangle_isos_narrow_break_1',
  'Broken_shapes/triangle_isos_narrow_break_2',
  'Broken_shapes/triangle_isos_wide_break_1',
  'Broken_shapes/triangle_isos_wide_break_2',
  'Broken_shapes/triangle_scalene_break_1',
  'Broken_shapes/triangle_scalene_break_2'
] as const;

export const equilateralTriangles = [
  'Equilateral_triangles/triangle_equal_blue',
  'Equilateral_triangles/triangle_equal_green',
  'Equilateral_triangles/triangle_equal_pink',
  'Equilateral_triangles/triangle_equal_purple',
  'Equilateral_triangles/triangle_equal_white',
  'Equilateral_triangles/triangle_equal_yellow'
] as const;

export const isoscelesTrianglesNarrow = [
  'Isosceles_triangles_narrow/triangle_isos_narrow_blue',
  'Isosceles_triangles_narrow/triangle_isos_narrow_green',
  'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
  'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
  'Isosceles_triangles_narrow/triangle_isos_narrow_white',
  'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
] as const;

export const isoscelesTrianglesWide = [
  'Isosceles_triangles_wide/triangle_isos_wide_blue',
  'Isosceles_triangles_wide/triangle_isos_wide_green',
  'Isosceles_triangles_wide/triangle_isos_wide_pink',
  'Isosceles_triangles_wide/triangle_isos_wide_purple',
  'Isosceles_triangles_wide/triangle_isos_wide_white',
  'Isosceles_triangles_wide/triangle_isos_wide_yellow'
] as const;

export const raTriangles = [
  'Right_angled_triangles/triangle_RA_blue',
  'Right_angled_triangles/triangle_RA_green',
  'Right_angled_triangles/triangle_RA_pink',
  'Right_angled_triangles/triangle_RA_purple',
  'Right_angled_triangles/triangle_RA_white',
  'Right_angled_triangles/triangle_RA_yellow'
] as const;

export const raTrianglesLong = [
  'Right_angled_triangles/triangle_RA_long_green',
  'Right_angled_triangles/triangle_RA_long_pink',
  'Right_angled_triangles/triangle_RA_long_purple',
  'Right_angled_triangles/triangle_RA_long_white',
  'Right_angled_triangles/triangle_RA_long_yellow'
] as const;

export const scaleneTriangles = [
  'Scalene_triangles/triangle_scalene_blue',
  'Scalene_triangles/triangle_scalene_green',
  'Scalene_triangles/triangle_scalene_pink',
  'Scalene_triangles/triangle_scalene_purple',
  'Scalene_triangles/triangle_scalene_white',
  'Scalene_triangles/triangle_scalene_yellow'
] as const;

export const markedEquilateralTriangle = [
  'Shapes_with_marked_sides/Equilateral_triangle_all__sides_blue',
  'Shapes_with_marked_sides/Equilateral_triangle_all__sides_green',
  'Shapes_with_marked_sides/Equilateral_triangle_all__sides_pink',
  'Shapes_with_marked_sides/Equilateral_triangle_all__sides_purple',
  'Shapes_with_marked_sides/Equilateral_triangle_all__sides_yellow'
] as const;

export const markedIsoscelesTriangle = [
  'Shapes_with_marked_sides/Isosceles1_markedlines_blue',
  'Shapes_with_marked_sides/Isosceles1_markedlines_green',
  'Shapes_with_marked_sides/Isosceles1_markedlines_pink',
  'Shapes_with_marked_sides/Isosceles1_markedlines_purple',
  'Shapes_with_marked_sides/Isosceles1_markedlines_yellow',
  'Shapes_with_marked_sides/Isosceles3_markedlines_blue',
  'Shapes_with_marked_sides/Isosceles3_markedlines_green',
  'Shapes_with_marked_sides/Isosceles3_markedlines_pink',
  'Shapes_with_marked_sides/Isosceles3_markedlines_purple',
  'Shapes_with_marked_sides/Isosceles3_markedlines_yellow',
  'Shapes_with_marked_sides/Isosceles4_markedlines_blue',
  'Shapes_with_marked_sides/Isosceles4_markedlines_green',
  'Shapes_with_marked_sides/Isosceles4_markedlines_pink',
  'Shapes_with_marked_sides/Isosceles4_markedlines_purple',
  'Shapes_with_marked_sides/Isosceles4_markedlines_yellow'
] as const;

export const isoscelesTriangleNarrowNamesSchema = z.enum(isoscelesTrianglesNarrow);

export const equilateralTriangleNamesSchema = z.enum(equilateralTriangles);
export const markedEquilateralTriangleNamesSchema = z.enum(markedEquilateralTriangle);

export const scaleneTriangleNamesSchema = z.enum(scaleneTriangles);
export const markedIsoscelesTriangleNamesSchema = z.enum(markedIsoscelesTriangle);

export const triangleNamesSchema = z.enum([
  ...equilateralTriangles,
  ...isoscelesTrianglesNarrow,
  ...isoscelesTrianglesWide,
  ...raTriangles,
  ...scaleneTriangles
]);

export const getRandomUniqueTriangleNames = (quantity: number) => {
  return getRandomSubArrayFromArray(
    [
      ...equilateralTriangles,
      ...isoscelesTrianglesNarrow,
      ...isoscelesTrianglesWide,
      ...raTriangles,
      ...scaleneTriangles
    ],
    quantity
  );
};
