import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

export const dayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
] as const;

export const daySchema = z.enum(dayNames);

export function getRandomDay() {
  return getRandomFromArray(dayNames);
}

export function getRandomUniqueDays(numberOfDays: number) {
  return getRandomSubArrayFromArray(dayNames, numberOfDays);
}

export type DayName = (typeof dayNames)[number];

export const isDay = (x: string): x is DayName => dayNames.includes(x as DayName);

export const dayAsWord = (day: DayName, translate: TranslationFunctions) => {
  return translate.time[day]();
};
