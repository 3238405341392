import { ExtraSymbols } from '../../molecules/UserInput';
import { isEqual } from '../../../utils/matchers';
import { useContext, useMemo } from 'react';
import { NumberTrack } from '../representations/Number Track/NumberTrack';
import { StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { Theme } from '../../../theme';
import { ThingOrFunction, resolveThingOrFunction } from '../../../utils/react';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { DraggableVariant } from '../../draganddrop/utils';
import { View } from 'react-native';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  testCorrect:
    | T[]
    | {
        length: number;
        testFunc: (ans: (T | undefined)[]) => boolean;
      };
  /** Values for each box in number track, use $ans to specify where a draggable answer box would be */
  boxValues: string[];
  initialState?: string[];
  extraSymbol?: ExtraSymbols;
  itemsTextVariant?: keyof Theme['fonts'];
  contentTextVariant?: keyof Theme['fonts'];
  fractionTextStyle?: StyleProp<TextStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  items: ThingOrFunction<(T | ItemInfo<T>)[]>;
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;
  /** Default: 'tallRectangle' */
  pdfItemVariant?: DraggableVariant;
  customBoxWidth?: number;
  customBoxHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[]; answerText?: string };
  /** PDF Question Height */
  questionHeight?: number;
};

/**
 * QF14a is a number track with draggable items instead of keyboard input
 */
export default function QF14aCompleteNumberTrackDraggable<T extends string | number>({
  title,
  pdfTitle,
  testCorrect: testCorrectProp,
  initialState,
  extraSymbol,
  boxValues,
  itemsTextVariant = 'WRN700',
  pdfItemVariant = 'tallRectangle',
  customBoxHeight,
  customBoxWidth,
  items: itemsProp,
  itemsLetterEmWidth,
  itemsMaxLines,
  contentTextVariant,
  fractionTextStyle,
  fractionDividerStyle,
  customMarkSchemeAnswer,
  questionHeight,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const testCorrect = useMemo(
    () => ('testFunc' in testCorrectProp ? testCorrectProp.testFunc : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  const items: ItemInfo<T>[] = resolveThingOrFunction(itemsProp).map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const draggableInputBox = (
    index: number,
    boxWidth: number,
    boxHeight: number,
    style: StyleProp<ViewStyle>
  ) => (
    // Need zIndex to prevent answer overlapping the tick of the previous answer.
    <View style={{ zIndex: 999 - index }} key={index}>
      {/* Outer view is required to limit the space that the drag and drop zone will take up*/}
      <EasyDragAndDropWithSingleZones.ZoneSingle
        style={[
          {
            width: boxWidth,
            minHeight: boxHeight
          },
          displayMode === 'digital' && styles.draggableDimens,
          style
        ]}
        id={index}
      />
      {displayMode === 'markscheme' && (
        <AssetSvg
          name="True"
          width={50}
          style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
        />
      )}
    </View>
  );

  const draggableSource =
    displayMode === 'digital' ? (
      <DragAndDropSection style={{ padding: 0 }}>
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSection>
    ) : (
      <DragAndDropSectionPDF
        itemsStyle={{
          flexDirection: 'row',
          gap: 16,
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSectionPDF>
    );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      'testFunc' in testCorrectProp ? customMarkSchemeAnswer?.answersToDisplay : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        moveOrCopy={displayMode === 'pdf' ? 'move' : 'copy'}
        items={items}
        variant={pdfItemVariant}
        textStyle={styles.text}
        textVariant={itemsTextVariant}
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              {draggableSource}
              <MeasureView>
                {dimens => (
                  <NumberTrack
                    inputBox={draggableInputBox}
                    boxValues={boxValues}
                    contentTextVariant={contentTextVariant}
                    fractionTextStyle={fractionTextStyle}
                    fractionDividerStyle={fractionDividerStyle}
                    customBoxWidth={customBoxWidth ?? 150}
                    customBoxHeight={customBoxHeight}
                    dimens={dimens}
                    isDraggable={true}
                  />
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="dragandgrop"
      items={items}
      testCorrect={testCorrect}
      draggableStyle={styles.draggableDimens}
      textVariant={itemsTextVariant}
      textStyle={styles.text}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="bottom"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <NumberTrack
                inputBox={draggableInputBox}
                boxValues={boxValues}
                contentTextVariant={contentTextVariant}
                fractionTextStyle={fractionTextStyle}
                fractionDividerStyle={fractionDividerStyle}
                customBoxWidth={88}
                customBoxHeight={88}
                dimens={dimens}
                isDraggable={true}
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  text: {
    fontWeight: '700'
  },
  draggableDimens: {
    width: 88,
    height: 88
  }
});
