import { StatusBar } from 'expo-status-bar';
import { useCallback, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { RootStackProps } from '../navigation/types';
import { countRange } from 'common/src/utils/collections';
import FilledButton from '../components/FilledButton';
import Spinner from 'common/src/components/molecules/Spinner';
import { useHeaderHeight } from '@react-navigation/elements';
import useBreakpoints from '../hooks/useBreakpoints';
import useKeyboard from '../hooks/useKeyboard';
import useScreenDimensions from '../hooks/useScreenDimensions';
import { resolveFont } from 'common/src/theme/fonts';
import { colors } from 'common/src/theme/colors';
import { Image } from 'expo-image';
import { useI18nContext } from '../i18n/i18n-react';
import { type LocalizedString } from 'typesafe-i18n';
import { validatePupilAccessCode } from '../network/pupilAccessCode';
import TextInputRow from '../components/TextInputRow';
import { getPlayer } from 'common/src/utils/Audio';
import { getQuizQuestions } from '../network/quizSession';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import Text from 'common/src/components/typography/Text';
import CantLoginButton from '../components/CantLoginButton';
import { Portal } from 'common/src/components/portal';
import CantLoginModal from '../components/CantLoginModal';
import useLoginStore from '../storage/useLoginStore';
import { showInfoToast } from '../components/Toast';

const CODE_LENGTH = 3;

export default function EnterPupilAccessCode({
  navigation,
  route: { params }
}: RootStackProps<'EnterPupilAccessCode'>) {
  const translate = useI18nContext().LL;

  const [code, setCode] = useState<string[]>(countRange(CODE_LENGTH).map(() => ''));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    displayString: LocalizedString;
    /** Whether the error is because the code is invalid (rather than due to some other error) */
    isInvalid?: boolean;
  } | null>(null);
  const [modalActive, setModalActive] = useState(false);
  const { resize, type } = useBreakpoints();

  const schoolCode = useLoginStore(state => state.school!.code);
  const loggedInUser = useLoginStore(state => state.loggedInUser);
  const school = useLoginStore(state => state.school);
  const setLoggedInUser = useLoginStore(state => state.setLoggedInUser);
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);

  const onSuccessLaunchQuizPin = params?.onSuccessLaunchQuizPin;
  const onSuccessGoToEnterQuizPIN = params?.onSuccessGoToEnterQuizPIN;

  const handleQuizCreation = useCallback(async () => {
    // If quiz code has been stored from the QR
    if (onSuccessLaunchQuizPin) {
      setLoading(true);
      const response = await getQuizQuestions({
        learningGroupShareCode: schoolCode, // School code
        quizVersionShareShareCode: onSuccessLaunchQuizPin // Quiz code
      });

      if (typeof response === 'string') {
        // Creating quiz session failed, show an error message and hide the loading spinner
        setLoading(false);

        switch (response) {
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            setError({
              displayString: translate.enterCodeOrPINScreen.somethingWentWrongError(),
              isInvalid: false
            });
            return;
          case 'network error':
            setError({
              displayString: translate.enterCodeOrPINScreen.internetConnectionLostError(),
              isInvalid: false
            });
            return;
          case 'not found':
            setError({
              displayString: translate.enterCodeOrPINScreen.quizNotFoundError(),
              isInvalid: false
            });
            return;
          case 'quiz locked':
            setError({
              displayString: translate.enterCodeOrPINScreen.quizLockedError(),
              isInvalid: false
            });
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${response satisfies never})`);
        }
      } else {
        getPlayer(response.quizSounds);
        setQuizSession(response);
        setLoading(false);

        if (school?.hasInfinityPlus && !loggedInUser) {
          showInfoToast({
            title: translate.infoModals.resultsNotSaved(),
            message: translate.infoModals.resultsNotSaved(),
            extraDuration: 1000,
            resize: resize
          });
        }

        navigation.replace('Quiz');
      }
    } else {
      // If user has been redirected from the school code page
      navigation.navigate('EnterQuizPIN');
    }
  }, [
    loggedInUser,
    navigation,
    onSuccessLaunchQuizPin,
    resize,
    school?.hasInfinityPlus,
    schoolCode,
    setQuizSession,
    translate.enterCodeOrPINScreen,
    translate.infoModals
  ]);

  const screenDimensions = useScreenDimensions();
  const keyboard = useKeyboard();
  const headerHeight = useHeaderHeight();

  const onContinueClicked = useCallback(async () => {
    const pupilCode = code.join('-').toUpperCase();

    // Local validation - check it's exactly 9 letters with dashes after each 3
    if (!/^[A-Z]{3}-[A-Z]{3}-[A-Z]{3}$/.test(pupilCode)) {
      setError({
        displayString: translate.enterCodeOrPINScreen.invalidPupilAccessCode(),
        isInvalid: true
      });
      return;
    }

    // API validation - check the pupil exists
    setLoading(true);
    const response = await validatePupilAccessCode(pupilCode, schoolCode);
    if (typeof response === 'string') {
      // API validation failed, show an error message and hide the loading spinner
      setLoading(false);

      switch (response) {
        case 'invalid response':
        case 'unknown error':
        case 'network error':
          setError({ displayString: translate.enterCodeOrPINScreen.internetConnectionLostError() });
          return;
        case 'not found':
          setError({
            displayString: translate.enterCodeOrPINScreen.invalidPupilAccessCode(),
            isInvalid: true
          });
          return;
        case 'http error':
          setError({
            displayString: translate.enterCodeOrPINScreen.somethingWentWrongError(),
            isInvalid: true
          });
          return;
        default:
          // Produces TS error and throws runtime error if we missed a case
          throw new Error(`Logic error: unreachable (${response satisfies never})`);
      }
    } else {
      setLoggedInUser({
        authTokens: {
          token: response.token,
          refreshToken: response.refreshToken
        },
        profile: {
          firstName: response.firstName,
          lastName: response.lastName,
          classStudentId: response.classStudentId
        }
      });

      setLoading(false);
      // If user has been redirected here due to needing to login in because school has infinity plus
      if (onSuccessGoToEnterQuizPIN || onSuccessLaunchQuizPin) {
        handleQuizCreation();
      } else {
        // Navigate to pupil homepage
        navigation.navigate('PupilHome');
      }
    }
  }, [
    code,
    schoolCode,
    translate.enterCodeOrPINScreen,
    setLoggedInUser,
    onSuccessGoToEnterQuizPIN,
    onSuccessLaunchQuizPin,
    handleQuizCreation,
    navigation
  ]);

  // Available height, after subtracting the keyboard and header (assumes app is full screen)
  const availableHeight =
    screenDimensions.height - headerHeight - (keyboard.keyboardShown ? keyboard.keyboardHeight : 0);

  // When keyboard is showing, there might not be enough space to show the continue button
  const showShorterLayoutBecauseKeyboard = keyboard.keyboardShown && availableHeight < 300 * resize;

  const loadingSpinner = (
    <View
      style={[
        StyleSheet.absoluteFill,
        { backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', gap: 24 }
      ]}
    >
      <Spinner height={showShorterLayoutBecauseKeyboard ? 100 * resize : 156 * resize} />
      {type !== 'mobile' && (
        <Text
          style={resolveFont({
            fontFamily: 'White_Rose_Noto',
            fontWeight: '400',
            fontSize: 32,
            lineHeight: 48,
            color: colors.prussianBlue
          })}
        >
          {translate.loadingEllipsis()}
        </Text>
      )}
    </View>
  );

  const textInputRow = (
    <TextInputRow
      maxInputLength={3}
      shape="l-l-l"
      code={code}
      setCode={action => {
        setCode(action);
        // Whenever the user changes the code, hide any error messages
        setError(null);
      }}
      onFinalEnter={onContinueClicked}
      // Highlight the input boxes as errored if it was the code itself being invalid that caused the error
      error={error?.isInvalid}
      autoFocus
    />
  );

  const errorText = (
    <View testID="ERROR_MESSAGE" style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}>
      {error !== null && (
        <Image
          source={require('pupil-app/assets/svg/InfoIcon.svg')}
          style={{ width: 27 * resize, height: 28 * resize }}
        />
      )}
      <Text
        style={resolveFont({
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 21.67 * resize,
          lineHeight: 32.5 * resize,
          color: colors.danger
        })}
      >
        {error?.displayString}
      </Text>
    </View>
  );

  const continueButton = (
    <FilledButton
      icon={() => (
        <Image
          source={require('pupil-app/assets/svg/RightArrow.svg')}
          style={{ width: 48 * resize, height: 48 * resize }}
        />
      )}
      iconOnRight
      onPress={onContinueClicked}
      buttonWidth={320 * resize}
    >
      Continue
    </FilledButton>
  );

  return (
    <>
      <StatusBar style="dark" />
      {showShorterLayoutBecauseKeyboard ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: Platform.OS === 'ios' ? keyboard.keyboardHeight : 0,
            minWidth: 760
          }}
        >
          {textInputRow}
          {errorText}
          {loading && loadingSpinner}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 32 * resize,
            minWidth: 760
          }}
        >
          {textInputRow}
          {error !== null && errorText}
          {continueButton}
          {/* Can't login button */}
          {(onSuccessGoToEnterQuizPIN || onSuccessLaunchQuizPin) && (
            <CantLoginButton onPress={() => setModalActive(true)} />
          )}
          {/* Can't login modal */}
          {modalActive && (
            <>
              <Portal>
                <CantLoginModal
                  onDismiss={() => setModalActive(false)}
                  onConfirm={() => {
                    handleQuizCreation();
                    setModalActive(false);
                  }}
                />
              </Portal>
            </>
          )}
          {loading && loadingSpinner}
        </View>
      )}
    </>
  );
}
