import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from 'common/src/components/question/representations/DisplayShapeOnGrid';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { createHundredSquareShape, hundredSquareSliced } from 'common/src/utils/shapes';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { View } from 'react-native';
import {
  moneyToHighestDenominations,
  displayMoney,
  numToCurrency,
  isValidMoneyAnswer
} from 'common/src/utils/money';
import Text from 'common/src/components/typography/Text';
import { getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import { compareFloats } from 'common/src/utils/math';
import { arrayHasNoDuplicates, sumNumberArray } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aw6',
  description: 'aw6',
  keywords: ['Money', 'Decimals', '100 square', 'Pence'],
  schema: z.object({
    squaresShaded: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const squaresShaded = randomIntegerInclusive(1, 99);

    return { squaresShaded };
  },
  Component: props => {
    const {
      question: { squaresShaded },
      translate
    } = props;

    const shape = createHundredSquareShape(squaresShaded);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsAHundredSquareEachSquareRepresents1p()}
        sentence={translate.answerSentences.whatIsTheValueOfTheShadedSquares()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        testCorrect={[squaresShaded.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aw7',
  description: 'aw7',
  keywords: ['Money', 'Decimals', '100 square', 'Pounds', 'Pence'],
  schema: z.object({
    squaresShaded: z.number().int().min(101).max(599)
  }),
  simpleGenerator: () => {
    const squaresShaded = randomIntegerInclusive(101, 599);

    return { squaresShaded };
  },
  Component: props => {
    const {
      question: { squaresShaded },
      translate
    } = props;

    // Get sliced version of squaresShaded
    // For e.g 345 returns [100, 100, 100, 45]
    const shapes = hundredSquareSliced(squaresShaded);

    // Create 100 square for each index in shapes
    const shapesToDisplay = shapes.map(shape => createHundredSquareShape(shape));

    const [firstRow, secondRow] = (() => {
      switch (shapesToDisplay.length) {
        case 2:
          return [[shapesToDisplay[0], shapesToDisplay[1]], []];
        case 3:
          return [[shapesToDisplay[0], shapesToDisplay[1], shapesToDisplay[2]], []];
        case 4:
          return [
            [shapesToDisplay[0], shapesToDisplay[1]],
            [shapesToDisplay[2], shapesToDisplay[3]]
          ];
        case 5:
          return [
            // Not a typo - the specialists would like the final shape to display on the first row in this case, not the second:
            [shapesToDisplay[0], shapesToDisplay[1], shapesToDisplay[4]],
            [shapesToDisplay[2], shapesToDisplay[3]]
          ];
        case 6:
          return [
            [shapesToDisplay[0], shapesToDisplay[1], shapesToDisplay[2]],
            [shapesToDisplay[3], shapesToDisplay[4], shapesToDisplay[5]]
          ];
        default:
          return [[], []];
      }
    })();

    // Answer
    const ans = squaresShaded / 100;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.eachHundredSquareRepresentsOnePoundWhatValueIsShown()}
        sentence={'£<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 16
              }}
            >
              {firstRow.map((shape, idx) => (
                <DisplayShapeOnGrid
                  key={idx}
                  givenShape={shape}
                  dimens={{
                    width: dimens.width / firstRow.length,
                    height: secondRow.length === 0 ? dimens.height : dimens.height / 2
                  }}
                  borderWidthAmount={2}
                />
              ))}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 16
              }}
            >
              {secondRow.map((shape, idx) => (
                <DisplayShapeOnGrid
                  key={idx}
                  givenShape={shape}
                  dimens={{
                    // Width determined by the length of the firstRow, as this is always equal to or longer than secondRow's length:
                    width: dimens.width / firstRow.length,
                    height: secondRow.length === 0 ? dimens.height : dimens.height / 2
                  }}
                  borderWidthAmount={2}
                />
              ))}
            </View>
          </View>
        )}
        inputMaxCharacters={4}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aw8',
  description: 'aw8',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence'],
  schema: z.object({
    pounds: z.number().int().min(1).max(6),
    pence: z.number().int().min(20).max(60).multipleOf(10)
  }),
  questionHeight: 1280,
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(1, 6);
    const pence = randomIntegerInclusiveStep(20, 60, 10);

    return { pounds, pence };
  },
  Component: ({ question, translate }) => {
    const { pounds, pence } = question;

    // Only return pound values, excluding notes
    const poundsToDisplay = moneyToHighestDenominations(pounds, 'pounds', [2, 1]);
    // Only return pence values
    const penceToDisplay = moneyToHighestDenominations(pence, 'pence');

    // Answers
    const answerTotal = (pounds * 100 + pence) / 100;

    return (
      <QF1ContentAndSentence
        sentence={
          pounds === 1
            ? translate.answerSentences.thereIsAnsPoundThereIsAnsPenceTheTotalAmountIsPoundsAns()
            : translate.answerSentences.thereAreAnsPoundsThereIsAnsPenceTheTotalAmountIsPoundsAns()
        }
        title={translate.instructions.completeSentences()}
        inputMaxCharacters={4}
        testCorrect={answer => {
          return (
            answer[0] === pounds.toString() &&
            answer[1] === pence.toString() &&
            compareFloats(answer[2], answerTotal) &&
            isValidMoneyAnswer(answer[2])
          );
        }}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        questionHeight={1280}
        Content={({ dimens }) => (
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 12
              }}
            >
              {displayMoney(
                [...poundsToDisplay, ...penceToDisplay],
                dimens.height,
                dimens.height,
                true
              )}
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            pounds.toLocaleString(),
            pence.toLocaleString(),
            answerTotal.toLocaleString(undefined, { minimumFractionDigits: 2 })
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aw9',
  description: 'aw9',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence'],
  schema: z.object({
    pounds: z.number().int().min(3).max(12),
    pence: z.number().int().min(1).max(85)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(3, 12);
    const pence = randomIntegerInclusive(1, 85);

    return { pounds, pence };
  },
  Component: ({ question, translate, displayMode }) => {
    const { pounds, pence } = question;

    // Only return pound values
    const poundsToDisplay = moneyToHighestDenominations(pounds, 'pounds');
    // Only return pence values
    const penceToDisplay = moneyToHighestDenominations(pence, 'pence');

    // Answers
    const ans = (pounds * 100 + pence) / 100;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.poundAns()}
        title={translate.instructions.howMuchMoneyIsThereWriteYourAnswersAsADecimal()}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 12,
              flexWrap: 'wrap'
            }}
          >
            {displayMoney(
              [...poundsToDisplay, ...penceToDisplay],
              displayMode === 'digital' ? 90 : 150,
              displayMode === 'digital' ? 90 : 150,
              true
            )}
          </View>
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axa',
  description: 'axa',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence'],
  schema: z.object({
    pounds: z.number().int().min(3).max(9),
    pence: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const { pounds, pence } = rejectionSample(
      () => {
        const pounds = randomIntegerInclusive(3, 9);
        const pence = randomIntegerInclusive(1, 99);
        return { pounds, pence };
      },

      ({ pounds, pence }) =>
        arrayHasNoDuplicates(moneyToHighestDenominations(pounds, 'pounds')) &&
        arrayHasNoDuplicates(moneyToHighestDenominations(pence, 'pence'))
    );
    return {
      pounds,
      pence
    };
  },
  Component: props => {
    const {
      question: { pounds, pence },
      translate,
      locale
    } = props;

    const moneyObjects = [
      { currency: '1p', value: 1 },
      { currency: '10p', value: 10 },
      { currency: '£1', value: 100 },
      { currency: '£10', value: 1000 },
      { currency: '2p', value: 2 },
      { currency: '20p', value: 20 },
      { currency: '£2', value: 200 },
      { currency: '£20', value: 2000 },
      { currency: '5p', value: 5 },
      { currency: '50p', value: 50 },
      { currency: '£5', value: 500 },
      { currency: '£50', value: 5000 }
    ];

    const items = shuffle(
      moneyObjects.map(({ value, currency }) => {
        return {
          value: value,
          component: displayMoney([currency], currency.includes('£') ? 170 : 80, 80)[0],
          currency
        };
      }),
      { random: seededRandom(props.question) }
    );

    const moneyString = numToCurrency({ amount: pounds + pence / 100, locale });

    return (
      <QF10SelectNumbers
        title={translate.instructions.useNotesAndCoinsToMakeXPoundsAndYPence(moneyString)}
        testCorrect={userAnswer =>
          moneyString ===
          numToCurrency({
            amount: sumNumberArray(userAnswer.map(num => Number(num))) / 100,
            locale
          })
        }
        items={items.map(selectable => ({
          value: selectable.value,
          component: selectable.component as JSX.Element
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            ...moneyToHighestDenominations(pence, 'pence'),
            ...moneyToHighestDenominations(pounds, 'pounds')
          ].map(currency => moneyObjects.find(obj => obj.currency === currency)?.value),
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'axb',
  description: 'axb',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add'],
  schema: z.object({
    poundsA: z.number().int().min(1).max(4),
    penceA: z.number().int().min(10).max(80).multipleOf(10),
    poundsB: z.number().int().min(5).max(9),
    penceB: z.number().int().min(1).max(9),
    nameA: nameSchema,
    nameB: nameSchema
  }),
  questionHeight: 920,
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 4);
    const penceA = randomIntegerInclusiveStep(10, 80, 10);

    const poundsB = randomIntegerInclusive(5, 9);
    const penceB = randomIntegerInclusive(1, 9);

    const [nameA, nameB] = getRandomUniqueNames(2);

    return { poundsA, penceA, poundsB, penceB, nameA, nameB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { poundsA, penceA, poundsB, penceB, nameA, nameB } = question;

    // Only return pound values
    const poundsAToDisplay = moneyToHighestDenominations(poundsA, 'pounds', [2, 1]);
    // Only return pence values
    const penceAToDisplay = moneyToHighestDenominations(penceA, 'pence');

    // Only return pound values, excluding notes
    const poundsBToDisplay = moneyToHighestDenominations(poundsB, 'pounds');
    // Only return pence values
    const penceBToDisplay = moneyToHighestDenominations(penceB, 'pence');

    // Answers
    const ans = (poundsA * 100 + penceA + (poundsB * 100 + penceB)) / 100;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.poundAns()}
        title={translate.instructions.characterHasTheseCoins({
          name: nameA
        })}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 12
              }}
            >
              {displayMoney(
                [...poundsAToDisplay, ...penceAToDisplay],
                displayMode === 'digital' ? 100 : 150,
                displayMode === 'digital' ? 100 : 150,
                true
              )}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.characterHasThisMoney({
                name: nameB
              })}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 12
              }}
            >
              {displayMoney(
                [...poundsBToDisplay, ...penceBToDisplay],
                displayMode === 'digital' ? 100 : 150,
                displayMode === 'digital' ? 100 : 150,
                true
              )}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.howMuchMoneyDoTheyHaveAltogether()}
            </Text>
          </View>
        )}
        questionHeight={920}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WriteMoneyUsingDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
