import { Camera } from 'expo-camera';
import { Platform } from 'react-native';
import showAlert from './showAlert';
import { RootStackNavigationProp } from '../navigation/types';

const isWeb = Platform.OS === 'web';
export const checkPermissions = async function (navigation: RootStackNavigationProp) {
  try {
    const webHasCamera = isWeb && (await Camera.isAvailableAsync());
    if (isWeb && !webHasCamera) {
      showAlert(
        'Your device does not have a useable camera. You can enter the code manually.',
        'Scanning unavailable'
      );
    }

    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      const routes = navigation.getState().routes;

      if (routes[routes.length - 1].name === 'PupilMenu') {
        navigation.replace('ScanQR');
        return;
      }

      navigation.navigate('ScanQR');
    } else if (status === 'denied') {
      showAlert('This app does not have permission to access the camera.', 'Scanning unavailable');
    }
  } catch {
    showAlert(
      'Your device does not have a useable camera. You can enter the code manually.',
      'Scanning unavailable',
      [
        {
          text: 'Ok',
          onPress: () => navigation.replace('Home')
        }
      ]
    );
  }
};
