import { z } from 'zod';
import { createContext, memo, useCallback, useMemo, useState } from 'react';
import { type StyleProp, type ViewStyle, View, StyleSheet } from 'react-native';
import { type DescriptionKey, type KeywordKey } from '../i18n/custom-types';
import { type TranslationFunctions, type Locales } from '../i18n/i18n-types';
import { type Theme, useTheme } from '../theme';
import { type ElementOrRenderFunction, type SetState } from '../utils/react';
import { useI18nContext } from '../i18n/i18n-react';
import { StateTreeRoot } from '../stateTree';
import { noop } from '../utils/flowControl';
import { DisplayMode } from '../contexts/displayMode';
import ProgressContainer from '../components/molecules/ProgressContainer';
import ContinueButton from '../components/atoms/ContinueButton';
import { AutoScaleTextStoreProvider } from '../components/typography/AutoScaleText';
import { UserInputStoreProvider } from '../components/molecules/UserInput';
import UidContext from '../contexts/UidContext';
import {
  MINIMUM_QUESTION_HEIGHT,
  PDF_QUESTION_WIDTH,
  QUESTION_WIDTH,
  ScaleContent,
  containAspectRatio
} from '../theme/scaling';

/**
 * A definition of a Question Type - contains all the information about a Question Type.
 *
 * @template Q The type of question returned by this definition.
 */
export type QuestionTypeContent<Q> = {
  /**
   * A unique (across the app) and stable (not changing in later versions of the app) identifier.
   *
   * This should be as short as possible for use in efficient serialization, and it doesn't matter if it's guessable.
   * Must use only the characters a-z,A-Z,0-9 (no commas).
   */
  uid: string;
  /**
   * Schema used for validating a question's parameters, e.g. for serialization/deserialization. The question
   * component should be able to handle any data that conforms to this schema, and the question generator must
   * produce parameters that conform to this schema (though it doesn't necessarily have to generate every valid
   * value).
   */
  schema: z.Schema<Q>;
  /**
   * The raw component to render, as written in the Question Type, with no meddling.
   *
   * Requires access to the following contexts:
   * - Theme
   * - TypesafeI18n
   * - ScaleFactorContext and ScaleContentContext
   * - AutoScaleTextContext
   * - DisplayModeContext
   * - UidContext
   * - UserInputStore
   * - QuestionNavigationContext
   * - StateTree
   *
   * If displayMode is digital, this is 1280x760. Otherwise, it is 1980xheight, where height defaults to 720 but can vary.
   *
   * Implementers of this component can assume they has a 1280x720 rectangle to work with. In reality, it may take up
   * more or less space on the screen due to being scaled up or down.
   *
   * Also, this component may assume it has access to `ScaleFactorContext`, if it needs to know exactly what
   * that scale factor is. Similarly it may assume it is within a {@link StateTreeRoot}.
   *
   * This should only be used directly when writing a question which renders other questions.
   *
   * Otherwise, do not use this directly. Use `QuizComponent`, `UncontrolledComponent` or `PDFComponent` instead, as
   * these set up a lot of the contexts above for you.
   */
  Component: React.FC<QuestionPropsHelpers & { question: Q }>;
  /**
   * The component that a Quiz should render.
   *
   * This has props to handle userAnswer state, as well as props for what components to display along the top
   * (QuizInformation) and at the bottom right (QuizNavigation).
   *
   * Digital components are always 1280x720, but if you provide a `dimens` prop, then the question will be scaled down
   * or up to fit (contain) in the dimensions you provide.
   */
  QuizComponent: React.FC<QuestionPropsUserAnswer & QuestionPropsRendering & { question: Q }>;
  /**
   * Uncontrolled version of `QuizComponent`. This _doesn't_ take props for the userAnswer state - it simply
   * manages that itself. This is easier for previews.
   *
   * Note that the default `QuizNavigation` prop of this component is a continue button which is always disabled.
   * (This could be enhanced, with a little work.)
   *
   * Digital components are always 1280x720, but if you provide a `dimens` prop, then the question will be scaled down
   * or up to fit (contain) in the dimensions you provide.
   */
  UncontrolledComponent: React.FC<QuestionPropsRendering & { question: Q }>;
  /**
   * Non-intereactive, printable version of the Question.
   *
   * Dimens is 1980 x pdfQuestionHeight, where pdfQuestionHeight varies per question but is usually 720.
   * (pdfQuestionHeight is a sibling property of this property, if you need to read it.)
   */
  PDFComponent: React.FC<{
    question: Q;
    displayMode: 'pdf' | 'markscheme';
  }>;
  /** Height of the question (PDFComponent only). */
  pdfQuestionHeight: number;
  /** User-visible description of the question. (Key to translation table.) */
  description: DescriptionKey;
  /** User-visible keywords, such as manipulatives used. (Key to translation table.) */
  keywords: readonly KeywordKey[];
  /** Example question to show. */
  example?: Q;
  /** Generator - creates a question, which is allowed to use randomness. */
  generator: () => Q;
};

/**
 * A constructor for {@link QuestionTypeContent}, and assists with implementing it.
 *
 * Also, users of this function enjoy type checks provided by the functions generic types. This ensures that
 * the provided parameters are self-consistent - e.g. the object provided in `example` agrees with the object
 * returned by the generator.
 *
 * @param params See {@link QuestionTypeContent} for hints about the params.
 */
export function newQuestionContent<QSchema extends z.Schema>({
  simpleGenerator,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  schema,
  Component,
  ...params
}: {
  uid: string;
  description: DescriptionKey;
  keywords: readonly KeywordKey[];
  schema: QSchema;
  example?: z.infer<QSchema>;
  simpleGenerator: () => z.infer<QSchema>;
  Component: React.FC<QuestionPropsHelpers & { question: z.infer<QSchema> }>;
  /** (PDF-only) question height (default: 720) */
  questionHeight?: number;
}): QuestionTypeContent<z.infer<QSchema>> {
  /**
   * Memoize the Component. This is a useful optimisation because whenever a user interacts with a question, they
   * change the userAnswer state, which causes a re-render of the QuizComponent. However, this doesn't need to cause
   * re-render of the Component itself. The change in userAnswer state is passed directly to the interactive component
   * via context (specifically {@link StateTreeRoot}, so nothing in between should need to change.
   */
  const MemoizedComponent = memo(Component);

  return {
    schema,

    pdfQuestionHeight: questionHeight,

    generator: () => {
      const generatedQuestion = simpleGenerator();
      const result = schema.safeParse(generatedQuestion);
      if (!result.success) {
        throw Error(
          `Generated function was invalid. This is a bug. Check the question generator of ${params.uid}.\n` +
            `Actual: ${JSON.stringify(generatedQuestion)}. Error: ${result.error}`
        );
      }
      return generatedQuestion;
    },

    Component,

    QuizComponent: ({
      userAnswer,
      onUserAnswerChanged = noop,
      onCorrectChanged,
      onCompleteChanged,
      dimens = { width: 1280, height: 720 },
      containerStyle,
      QuizInformation,
      QuizNavigation,
      question
    }) => {
      const theme = useTheme();
      const { LL: translate, locale } = useI18nContext();

      // Allow the tree to update its state, by forwarding that on to our `onUserAnswerChanged` callback.
      // Slightly fiddly because we convert a (value: T) => void into a SetState<T>.
      const setUserAnswer: SetState<Record<string, unknown>> = useCallback(
        actionOrState => {
          const newState =
            typeof actionOrState === 'function'
              ? (actionOrState as (prevState: Record<string, unknown>) => Record<string, unknown>)(
                  userAnswer
                )
              : actionOrState;

          onUserAnswerChanged(newState);
        },
        [onUserAnswerChanged, userAnswer]
      );

      // Calculate the dimensions of the question contained inside the dimens.
      const questionActualDimens = containAspectRatio(
        dimens,
        QUESTION_WIDTH / MINIMUM_QUESTION_HEIGHT
      );
      const scaleFactor = questionActualDimens.width / QUESTION_WIDTH;

      return (
        <View style={[dimens, styles.centerChildren, containerStyle]}>
          <ScaleContent dimens={questionActualDimens} scaleFactor={scaleFactor}>
            <AutoScaleTextStoreProvider>
              <DisplayMode.Provider value="digital">
                <UidContext.Provider value={params.uid}>
                  <UserInputStoreProvider>
                    <QuestionNavigationContext.Provider
                      value={useMemo(
                        () => ({
                          QuizInformation:
                            QuizInformation ?? defaultQuestionNavigationContext.QuizInformation,
                          QuizNavigation:
                            QuizNavigation ?? defaultQuestionNavigationContext.QuizNavigation
                        }),
                        [QuizInformation, QuizNavigation]
                      )}
                    >
                      <StateTreeRoot
                        state={userAnswer}
                        setState={setUserAnswer}
                        onCorrectChanged={onCorrectChanged}
                        onCompleteChanged={onCompleteChanged}
                      >
                        <MemoizedComponent
                          question={question}
                          theme={theme}
                          translate={translate}
                          locale={locale}
                          displayMode="digital"
                        />
                      </StateTreeRoot>
                    </QuestionNavigationContext.Provider>
                  </UserInputStoreProvider>
                </UidContext.Provider>
              </DisplayMode.Provider>
            </AutoScaleTextStoreProvider>
          </ScaleContent>
        </View>
      );
    },

    UncontrolledComponent: ({
      QuizInformation,
      QuizNavigation,
      dimens = { width: 1280, height: 720 },
      containerStyle,
      question
    }) => {
      const theme = useTheme();
      const { LL: translate, locale } = useI18nContext();

      // Calculate the dimensions of the question contained inside the dimens.
      const questionActualDimens = containAspectRatio(
        dimens,
        QUESTION_WIDTH / MINIMUM_QUESTION_HEIGHT
      );
      const scaleFactor = questionActualDimens.width / QUESTION_WIDTH;

      const [userAnswer, setUserAnswer] = useState<Record<string, unknown>>({});

      return (
        <View style={[dimens, styles.centerChildren, containerStyle]}>
          <ScaleContent dimens={questionActualDimens} scaleFactor={scaleFactor}>
            <AutoScaleTextStoreProvider>
              <DisplayMode.Provider value="digital">
                <UidContext.Provider value={params.uid}>
                  <UserInputStoreProvider>
                    <QuestionNavigationContext.Provider
                      value={useMemo(
                        () => ({
                          QuizInformation:
                            QuizInformation ?? defaultQuestionNavigationContext.QuizInformation,
                          QuizNavigation:
                            QuizNavigation ?? defaultQuestionNavigationContext.QuizNavigation
                        }),
                        [QuizInformation, QuizNavigation]
                      )}
                    >
                      <StateTreeRoot state={userAnswer} setState={setUserAnswer}>
                        <MemoizedComponent
                          question={question}
                          theme={theme}
                          translate={translate}
                          locale={locale}
                          displayMode="digital"
                        />
                      </StateTreeRoot>
                    </QuestionNavigationContext.Provider>
                  </UserInputStoreProvider>
                </UidContext.Provider>
              </DisplayMode.Provider>
            </AutoScaleTextStoreProvider>
          </ScaleContent>
        </View>
      );
    },

    PDFComponent: ({ question, displayMode }) => {
      const theme = useTheme();
      const { LL: translate, locale } = useI18nContext();

      // PDF questions are fixed to scale factor of 1.
      const questionActualDimens = { width: PDF_QUESTION_WIDTH, height: questionHeight };
      const scaleFactor = 1;

      // Whilst these questions aren't interactive, some questions might use state tree to initialize their state on
      // first render.
      const [userAnswer, setUserAnswer] = useState<Record<string, unknown>>({});

      return (
        <ScaleContent dimens={questionActualDimens} scaleFactor={scaleFactor}>
          <AutoScaleTextStoreProvider>
            <DisplayMode.Provider value={displayMode}>
              <UidContext.Provider value={params.uid}>
                <UserInputStoreProvider>
                  <QuestionNavigationContext.Provider
                    // These are not required for PDF
                    value={useMemo(
                      () => ({
                        QuizInformation: <></>,
                        QuizNavigation: <></>
                      }),
                      []
                    )}
                  >
                    <StateTreeRoot state={userAnswer} setState={setUserAnswer}>
                      <MemoizedComponent
                        question={question}
                        theme={theme}
                        translate={translate}
                        locale={locale}
                        displayMode={displayMode}
                      />
                    </StateTreeRoot>
                  </QuestionNavigationContext.Provider>
                </UserInputStoreProvider>
              </UidContext.Provider>
            </DisplayMode.Provider>
          </AutoScaleTextStoreProvider>
        </ScaleContent>
      );
    },

    ...params
  };
}

export type QuestionPropsRendering = {
  /**
   * Dimens of the question's container.
   *
   * Each question is forced to be a 16:9 ratio but can be freely scaled. The question will be the largest 16:9
   * rectangle that fits inside these dimensions, centered inside an outer container of these dimensions.
   *
   * In pdf or markscheme mode, the same principle applies, but the aspect ratio of a question is configurable.
   *
   * Default: 1280x720
   */
  dimens?: { width: number; height: number };

  /**
   * Any additional style to provide to the container of the question. Only to be used for positioning. For sizing,
   * use dimens.
   */
  containerStyle?: StyleProp<ViewStyle>;

  /**
   * Component to place at the top line of the question.
   * Defaults to a dummy progress bar.
   */
  QuizInformation?: JSX.Element;

  /**
   * Component to place at the bottom right of the question.
   * Should have two variants, depending on the width available.
   * Defaults to a disabled continue button.
   */
  QuizNavigation?: ElementOrRenderFunction<{
    variant: 'normal' | 'thin';
  }>;
};

export type QuestionPropsUserAnswer = {
  /**
   * Pass in a user answer to render. Start with {} to allow the layout to pick its own initial state.
   */
  userAnswer: Record<string, unknown>;
  /**
   * Callback for when the user answer changes.
   */
  onUserAnswerChanged?: (userAnswer: Record<string, unknown>) => void;
  onCompleteChanged?: (attempted: boolean) => void;
  onCorrectChanged?: (correct: boolean) => void;
};

export type QuestionPropsHelpers = {
  /** Theme. This is also accessible via context {@link useTheme}. */
  theme: Theme;
  /** Access to string translations. This is also accessible via context {@link useI18nContext}. */
  translate: TranslationFunctions;
  /** The current locale. Usually you don't need this, and can just use `translate`. */
  locale: Locales;
  /** Mode to display question in. This is also accessible via context {@link DisplayMode}. */
  displayMode: 'digital' | 'pdf' | 'markscheme';
};

const defaultQuestionNavigationContext = {
  QuizInformation: <ProgressContainer questionNumber={1} quizProgress={0} quizStars={0} />,
  QuizNavigation: ({ variant }: { variant: 'normal' | 'thin' }) => (
    <ContinueButton disabled={true} variant={variant === 'thin' ? 'circle' : 'oval'} />
  )
} as const;

/**
 * Context for a question's navigational state.
 *
 * Each of our questions also show some information, or behave in response to, data that's really owned by the quiz.
 *
 * Each question has a few places where they can host components passed from the quiz. For example, the quiz might
 * want a progress bar along the top-left. These components are provided via this context.
 */
export const QuestionNavigationContext = createContext<{
  /** Component to place at the top line of the question. */
  QuizInformation?: JSX.Element;

  /** Component to place at the bottom right of the question. Might be a thin variant. */
  QuizNavigation?: ElementOrRenderFunction<{
    variant: 'normal' | 'thin';
  }>;
}>(defaultQuestionNavigationContext);

const styles = StyleSheet.create({
  centerChildren: { justifyContent: 'center', alignItems: 'center' }
});
