import { View } from 'react-native';
import { StudentQuestion } from '../utils/studentData';
import { QuizTile } from './QuizTile';

export default function QuizList({ item }: { item: StudentQuestion }) {
  return (
    <View
      style={{
        width: 260,
        marginHorizontal: 20
      }}
      testID="STUDENT_QUIZ"
    >
      <QuizTile item={item} />
    </View>
  );
}
