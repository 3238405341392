import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

/** returns the gradient between two points */
export function getLineGradient(point1: [number, number], point2: [number, number]) {
  const x = 0;
  const y = 1;
  const dy = Math.abs(point1[y] - point2[y]);
  const dx = Math.abs(point1[x] - point2[x]);
  return dx === 0 ? 0 : number(math.evaluate(`${dy} / ${dx}`));
}

/** returns the line intercept for given gradient */
export function getLineIntercept(point1: [number, number], gradient: number) {
  const x = 0;
  const y = 1;
  return number(math.evaluate(`${point1[y]} - ${gradient} * ${point1[x]}`));
}

/** returns the y value of a given x for between two points on a line */
export function getYValueOfPoint(
  point1: [number, number],
  point2: [number, number],
  xNew: number,
  round = true
) {
  const gradient = getLineGradient(point1, point2);
  const intercept = getLineIntercept(point1, gradient);
  const value = number(math.evaluate(`${gradient} * ${xNew} + ${intercept}`));
  return round ? Math.round(value) : value;
}
