import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { isEqual } from '../../../utils/matchers';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { JugWithDraggableLiquidWithState, JugWithLiquid } from '../representations/JugWithLiquid';
import {
  type ElementOrRenderFunction,
  resolveElementOrRenderFunction
} from 'common/src/utils/react';
import { Dimens, MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { View } from 'react-native';
import Text from '../../typography/Text';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * Total capacity of the jug SVG, in millilitres.
   */
  jugCapacity: number;
  /**
   * Value of each  major tick on the jug SVG, in millilitres.
   */
  tickValue: number;
  /**
   * Type of liquid to show in the jug.
   * Optional prop, defaults to 'water'.
   */
  liquidType?: 'water' | 'orange';
  /**
   * What units the label should be in. Defaults to litres.
   */
  labelUnits?: 'litres' | 'ml';
  /**
   * Which major labels to display. "all" shows all major labels, "first" shows the first at the bottom and top label. "top" shows only the top.
   * Defaults to "all"
   */
  displayMajorLabels?: 'all' | 'first' | 'top';
  /**
   * How many minor ticks between major ticks
   */
  unitsPerMajorTick: number;
  /** Optional content to fill the area to the left of the interactive jug. */
  lhsContent?: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;
  /**
   * Optional string label to display beneath the interactive jug.
   */
  label?: string;
  /**
   * Optional string label to display beneath the content to the left of the interactive jug.
   */
  lhsContentLabel?: string;
  /**
   * Amount in ml for the arrow to incrementally snap to.
   * Optional prop, if left undefined, the arrow will simply snap to the minor tick values.
   */
  snapToNearest?: number;
  testCorrect: number | ((userAnswer: number) => boolean);
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answerToDisplay?: number; answerText?: string };
  questionHeight?: number;
};

export default function QF13DragLiquidInJug({
  title,
  pdfTitle,
  jugCapacity,
  tickValue,
  liquidType,
  labelUnits,
  displayMajorLabels,
  unitsPerMajorTick,
  lhsContent,
  label,
  lhsContentLabel,
  snapToNearest,
  testCorrect,
  customMarkSchemeAnswer,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <View style={{ flexDirection: 'row', flex: 1 }}>
            {lhsContent && (
              <MeasureView style={{ alignItems: 'center' }}>
                {dimens => (
                  <>
                    {resolveElementOrRenderFunction(lhsContent, {
                      dimens: {
                        width: dimens.width,
                        height: lhsContentLabel ? dimens.height * 0.9 : dimens.height
                      }
                    })}
                    {lhsContentLabel && <Text variant="WRN700">{lhsContentLabel}</Text>}
                  </>
                )}
              </MeasureView>
            )}
            <MeasureView style={{ alignItems: 'center' }}>
              {dimens => (
                <>
                  <JugWithLiquid
                    dimens={{
                      width: dimens.width,
                      height: label ? dimens.height * 0.9 : dimens.height
                    }}
                    jugCapacity={jugCapacity}
                    tickValue={tickValue}
                    liquidAmount={0}
                    displayMajorLabels={displayMajorLabels}
                    unitsPerMajorTick={unitsPerMajorTick}
                    labelUnits={labelUnits}
                    liquidType={liquidType}
                  />
                  {label && <Text variant="WRN700">{label}</Text>}
                </>
              )}
            </MeasureView>
          </View>
        }
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <View style={{ flexDirection: 'row', flex: 1 }}>
            {lhsContent && (
              <MeasureView style={{ alignItems: 'center' }}>
                {dimens => (
                  <>
                    {resolveElementOrRenderFunction(lhsContent, {
                      dimens: {
                        width: dimens.width,
                        height: lhsContentLabel ? dimens.height * 0.9 : dimens.height
                      }
                    })}
                    {lhsContentLabel && <Text variant="WRN700">{lhsContentLabel}</Text>}
                  </>
                )}
              </MeasureView>
            )}
            <MeasureView style={{ alignItems: 'center' }}>
              {dimens => (
                <>
                  <JugWithLiquid
                    dimens={{
                      width: dimens.width,
                      height: label ? dimens.height * 0.9 : dimens.height
                    }}
                    jugCapacity={jugCapacity}
                    tickValue={tickValue}
                    liquidAmount={typeof testCorrect === 'number' ? testCorrect : 0}
                    displayMajorLabels={displayMajorLabels}
                    unitsPerMajorTick={unitsPerMajorTick}
                    labelUnits={labelUnits}
                    liquidType={liquidType}
                  />
                  {label && <Text variant="WRN700">{label}</Text>}
                </>
              )}
            </MeasureView>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </View>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="end"
      mainPanelContents={
        <View style={{ flexDirection: 'row', flex: 1 }}>
          {lhsContent && (
            <MeasureView style={{ alignItems: 'center' }}>
              {dimens => (
                <>
                  {resolveElementOrRenderFunction(lhsContent, {
                    dimens: {
                      width: dimens.width,
                      height: lhsContentLabel ? dimens.height * 0.9 : dimens.height
                    }
                  })}
                  {lhsContentLabel && <Text variant="WRN700">{lhsContentLabel}</Text>}
                </>
              )}
            </MeasureView>
          )}
          <MeasureView>
            {dimens => (
              <>
                <JugWithDraggableLiquidWithState
                  id="draggableLiquidInJug"
                  testCorrect={
                    typeof testCorrect === 'function' ? testCorrect : isEqual(testCorrect)
                  }
                  dimens={{
                    width: dimens.width,
                    height: label ? dimens.height * 0.9 : dimens.height
                  }}
                  defaultState={0}
                  jugCapacity={jugCapacity}
                  unitsPerMajorTick={unitsPerMajorTick}
                  tickValue={tickValue}
                  displayMajorLabels={displayMajorLabels}
                  labelUnits={labelUnits}
                  liquidType={liquidType}
                  snapToNearest={snapToNearest}
                  isInteractive={true}
                />
                {label && <Text variant="WRN700">{label}</Text>}
              </>
            )}
          </MeasureView>
        </View>
      }
      {...props}
    />
  );
}
