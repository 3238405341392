import { View, StyleProp, ViewStyle } from 'react-native';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { ScaleObjectName, scaleObjects } from '../../../utils/objects';
import PyramidOfObjects from '../../molecules/PyramidOfObjects';
import { getMinTriangularNumber } from '../../../utils/math';
import { Dimens } from '../../../theme/scaling';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type ScaleTypes = {
  direction?: 'equal' | 'left' | 'right';
  items: [ScaleObjectName, ScaleObjectName];
  containerStyle?: StyleProp<ViewStyle>;
  amount?: [number, number];
  dimens: Dimens;
};

/**
 * Wooden balance scale with directions of equal, left or right.
 * @param amount - A number array with a limit of 2. The amount of icons at each side of the scale.
 * @param maxLength - The maximum amount of icons the pyramid will span wide from the bottom.
 */
export default function WoodenBalanceScale({
  direction = 'equal',
  items,
  containerStyle,
  amount,
  dimens
}: ScaleTypes) {
  const displayMode = useContext(DisplayMode);
  const equalScale = displayMode === 'digital' ? 0.8 : 0.6;
  const WIDTH = direction === 'equal' ? dimens.width * equalScale : 510;
  const SCALE_NAME =
    direction === 'equal'
      ? 'Scales/wooden_balance_scale_equal'
      : direction === 'left'
      ? 'Scales/wooden_balance_scale_left'
      : 'Scales/wooden_balance_scale_right';

  const scaleInfo = getSvgInfo(SCALE_NAME);
  const scaleSvgHeight = WIDTH / scaleInfo.aspectRatio;

  const maxLength = amount ? Math.max(...amount.map(val => getMinTriangularNumber(val))) : 1;
  const iconScale = (WIDTH * 0.4) / maxLength;

  return (
    <View style={[{ position: 'relative' }, containerStyle]}>
      {items.map((item, idx) => {
        const scaleObject = scaleObjects[item];
        const rotation =
          direction === 'equal'
            ? 0
            : direction === 'left'
            ? -scaleObject.rotation
            : scaleObject.rotation;

        // Styles
        const rotateStyles = {
          rotateZ: `${rotation}deg`
        };
        return (
          <View
            key={idx}
            style={[
              { position: 'absolute' },
              // Equal
              direction === 'equal'
                ? {
                    bottom: scaleSvgHeight,
                    left: idx === 0 ? 0 : undefined,
                    right: idx === 1 ? 0 : undefined
                  }
                : // Left
                direction === 'left'
                ? idx === 0
                  ? {
                      bottom: 40,
                      left: 0,
                      transform: [rotateStyles]
                    }
                  : {
                      bottom: 176,
                      right: 10,
                      transform: [rotateStyles]
                    }
                : // Right
                idx === 0
                ? {
                    bottom: 176,
                    left: 10,
                    transform: [rotateStyles]
                  }
                : {
                    bottom: 40,
                    right: 0,
                    transform: [rotateStyles]
                  },
              { zIndex: 10 }
            ]}
          >
            {amount ? (
              <PyramidOfObjects
                icon={item}
                amount={amount[idx]}
                iconWidth={Math.min(iconScale, scaleObjects[item].width)}
                pyramidWidth={WIDTH * 0.4}
                alignLeft={idx === 0}
              />
            ) : (
              <AssetSvg name={scaleObjects[item].name} width={scaleObjects[item].width} />
            )}
          </View>
        );
      })}

      <AssetSvg name={SCALE_NAME} width={WIDTH} />
    </View>
  );
}
