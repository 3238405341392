import { type ErrorMessages } from './types';

export const errorMessages: ErrorMessages = {
  validateSchoolCode: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    UNKNOWN_ERROR: 'Unknown Error',
    SCHOOL_CODE_NOT_FOUND: (schoolCode: string) => `School Code was not found: ${schoolCode})`
  },

  getQuizQuestions: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    UNKNOWN_ERROR: 'Unknown Error',
    QUIZ_NOT_FOUND: (quizPin: string) => `Quiz not found: ${quizPin}`,
    QUIZ_LOCKED: (quizPin: string) => `Quiz locked: ${quizPin}`,
    QUIZ_EXPIRED: (quizPin: string) => `Quiz expired: ${quizPin})`
  },

  updateQuizResults: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error'
  },

  validatePupilAccessCode: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error',
    PUPIL_NOT_FOUND: (pupilCode: string) => `Pupil was not found: ${pupilCode}`
  },

  getStudentQuizzes: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error'
  }
};
