import Logger from '../utils/logger';
import { StudentQuestions } from '../utils/studentData';
import { getRequestQuery, isHttpSuccess } from './requests';

export const getStudentQuizzes = async (pageNum: number) => {
  const studentQuizzesEndpoint = `/web/infinity/student-quizzes?_page=${pageNum}&_itemsPerPage=32`;
  const result = await getRequestQuery(studentQuizzesEndpoint, 'json');

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', 'getStudentQuizzes', 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        Logger.captureEvent('error', 'getStudentQuizzes', 'HTTP_ERROR', { eventData: result });

        return 'http error';
      case 'unknown':
        Logger.captureEvent('error', 'getStudentQuizzes', 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
    }
  }

  // Data from API
  const data = result.response.data as StudentQuestions;
  const quiz = data['hydra:member'];
  const quizLength = data['hydra:totalItems'];

  return {
    quiz,
    quizLength
  };
};
