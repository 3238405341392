import { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { validateQuestion, type QuestionToken } from '../../SchemeOfLearning';
import Quiz from '../molecules/Quiz';
import Spinner from '../molecules/Spinner';
import BaseScreen from './BaseScreen';
import Results from '../molecules/Results';
import Text from '../typography/Text';
import QuizCard from './QuizCard';
import { useI18nContext } from '../../i18n/i18n-react';
import { WellDone } from './WellDone';
import { FullMarks } from './FullMarks';
import { getPlayer } from '../../utils/Audio';

type Props = {
  quizName: string;
  tokens: QuestionToken[];
  loadingTextColor: string;
  /**
   * Called during the loading screen when any tokens are invalid.
   *
   * If all tokens are invalid, the quiz will get stuck at the loading screen.
   */
  onTokensInvalid?: (invalidTokens: QuestionToken[]) => void;
  /** Called when the X button at the top left is clicked.*/
  onExitQuiz: () => void;
  /** Called from the results screen. If absent, the retry quiz button isn't there. */
  onRetryQuiz?: () => void;
  /** Called from the results screen. If absent, the return to home button isn't there. */
  onReturnToHome?: () => void;
  /** Optional calback called from the quiz screen on every answer. */
  onAnswer?: (
    questionIndex: number,
    answer: string,
    isCorrect: boolean,
    timeTaken: number,
    attemptNumber: number,
    parameters: Record<string, unknown>
  ) => void;
  /** Optional calback called from the results screen. */
  onQuizEnd?: () => void;
};

/**
 * A full-screen component combining:
 * - Loading screen
 * - Quiz Screen
 * - Well done / 100% animation
 * - Results screen
 *
 * This will take up the whole window, and put the content in the largest 16:9 rectangle, centered.
 *
 * Various callbacks are available to run custom code at different points.
 */
export default function QuizAndResultsScreen({
  quizName,
  tokens,
  loadingTextColor,
  onTokensInvalid,
  onExitQuiz: onExitQuizProp,
  onRetryQuiz: onRetryQuizProp,
  onReturnToHome: onReturnToHomeProp,
  onAnswer,
  onQuizEnd: onQuizEndProp
}: Props) {
  const translate = useI18nContext().LL;
  const player = getPlayer();

  const [subScreen, setSubScreen] = useState<'loading' | 'quiz' | 'welldone' | 'results'>(
    'loading'
  );
  const [results, setResults] = useState<Array<{
    stars: number;
  }> | null>(null);
  const fullMarks =
    (subScreen === 'results' || subScreen === 'welldone') && results!.every(it => it.stars === 3);

  // Validate tokens
  const { invalidTokens, validTokens } = useMemo(() => {
    const isTokenValidArray = tokens.map(token => validateQuestion(token));
    const invalidTokens = tokens.filter((_token, index) => !isTokenValidArray[index]);
    const validTokens = tokens.filter((_token, index) => isTokenValidArray[index]);
    return { invalidTokens, validTokens };
  }, [tokens]);

  // Once only, try to call onTokensInvalid if any tokens were invalid.
  useEffect(() => {
    if (invalidTokens.length > 0 && onTokensInvalid) {
      return onTokensInvalid(invalidTokens);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Once only, show loading spinner for 3 seconds before showing Quiz (if there are valid tokens)
  useEffect(() => {
    if (subScreen === 'loading') {
      const timer = setTimeout(() => {
        if (validTokens.length > 0) {
          setSubScreen('quiz');
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [validTokens.length, subScreen]);

  // Play the correct audio
  useEffect(() => {
    if (subScreen === 'loading') {
      player.playSound('loading');
    } else if (subScreen === 'quiz') {
      player.stopSound();
      player.playSound('quizstart');
    } else if (subScreen === 'welldone') {
      fullMarks ? player.playSound('hundredpercent') : player.playSound('welldone');
    } else if (subScreen === 'results') {
      player.playSound('resultsscreen');
    }
  }, [fullMarks, player, subScreen]);

  const questions = useMemo(
    () => ({ mode: 'tokens' as const, tokens: validTokens }),
    [validTokens]
  );

  ////
  // Callbacks
  ////

  const onExitQuiz = useCallback(() => {
    player.stopSound();
    onExitQuizProp();
  }, [onExitQuizProp, player]);

  const onRetryQuiz = useMemo(
    () =>
      // Keep this prop undefined if onRetryQuiz is undefined
      onRetryQuizProp
        ? () => {
            player.stopSound();
            onRetryQuizProp();
          }
        : undefined,
    [onRetryQuizProp, player]
  );

  const onReturnToHome = useMemo(
    () =>
      // Keep this prop undefined if onReturnToHome is undefined
      onReturnToHomeProp
        ? () => {
            player.stopSound();
            onReturnToHomeProp();
          }
        : undefined,
    [onReturnToHomeProp, player]
  );

  const onFinishQuiz = useCallback(
    (quizResults: { stars: number }[]) => {
      onQuizEndProp && onQuizEndProp();
      setResults(quizResults);
      setSubScreen('welldone');
    },
    [onQuizEndProp]
  );

  return (
    <BaseScreen>
      {subScreen !== 'loading' && (
        // Card to go behind questions as they animate out and back in, as well as between subScreens
        <Animated.View
          style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center' }]}
          // Delay long enpough to not get in the way of the first question appearing
          entering={FadeIn.duration(250).delay(1000)}
          exiting={FadeOut.duration(300)}
        >
          <QuizCard />
        </Animated.View>
      )}
      {(() => {
        switch (subScreen) {
          case 'loading':
            return (
              <View key="loading" style={{ gap: 64, alignItems: 'center' }}>
                <View style={{ gap: 24 }}>
                  <Spinner height={156} />
                  <Text
                    variant="WRN400"
                    style={{ color: loadingTextColor, fontSize: 32, lineHeight: 48 }}
                  >
                    {translate.misc.loadingEllipsis()}
                  </Text>
                </View>
                <View style={{ gap: 5, alignItems: 'center' }}>
                  <Text
                    variant="WRN700"
                    style={{
                      color: loadingTextColor,
                      fontSize: 32,
                      lineHeight: 48,
                      textAlign: 'center'
                    }}
                  >
                    {quizName}
                  </Text>
                  <Text
                    variant="WRN400"
                    style={{ color: loadingTextColor, fontSize: 32, lineHeight: 48 }}
                  >
                    {translate.misc.numberOfQuestions(validTokens.length)}
                  </Text>
                </View>
              </View>
            );
          case 'quiz':
            return (
              <Quiz
                key="quiz"
                questions={questions}
                onExitQuiz={onExitQuiz}
                onFinishQuiz={onFinishQuiz}
                onAnswer={onAnswer}
                onRetryQuiz={onRetryQuiz}
                onReturnToHome={onReturnToHome}
              />
            );
          case 'welldone':
            return (
              <Animated.View
                key="welldone"
                style={{ gap: 24, alignItems: 'center', zIndex: 9990 }}
                // Delay long enough for the last question to exit
                entering={FadeIn.duration(250).delay(300)}
                exiting={FadeOut.duration(300)}
              >
                {fullMarks ? (
                  <FullMarks
                    onAnimationFinish={
                      () =>
                        setTimeout(() => {
                          setSubScreen('results');
                        }, 1500) // 3.5 seconds - animation.length === 1500
                    }
                  />
                ) : (
                  <WellDone onAnimationFinish={() => setSubScreen('results')} />
                )}
              </Animated.View>
            );
          case 'results':
            return (
              <Animated.View
                key="results"
                style={{ gap: 24, alignItems: 'center', zIndex: 9999 }}
                // Delay long enough for the well done screen to exit
                entering={FadeIn.duration(250).delay(300)}
                exiting={FadeOut.duration(300)}
              >
                <Results
                  results={results!}
                  onExitClicked={onExitQuiz}
                  onTryAgainClicked={onRetryQuiz}
                  onHomeClicked={onReturnToHome}
                  quizName={quizName}
                />
              </Animated.View>
            );
        }
      })()}
    </BaseScreen>
  );
}
