import {
  arrayInsert,
  countRange,
  filledArray,
  NonEmptyArray,
  range,
  sortNumberArray
} from './collections';
import { Digit, numberWithEnforcedDigit } from './math';
import safeJsonStringify from 'safe-json-stringify';

/** Generate a random color. */
export function randomColor(random = Math.random) {
  return 'rgb(' + random255(random) + ',' + random255(random) + ',' + random255(random) + ')';
}

function random255(random = Math.random) {
  return randomIntegerInclusive(0, 255, { sample: random });
}

/**
 * Get a random number to a certain decimal precision (default: no rounding) within a range of two numbers.
 * (The upper bound is exclusive, i.e. the highest number possible is one step down from the upper bound, in the
 * chosen precision.)
 * For most accurate results, ensure that the lower and upper bounds are numbers expressible at the given precision.
 */
export function randomNumber(
  lowerBound: number,
  upperBound: number,
  /** null represents maximum precision / no rounding. */
  decimalPlaces: number | null = null,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
  } = {}
): number {
  const { random = Math.random, sample = uniformSample } = options;
  if (decimalPlaces !== null) {
    assertInteger(decimalPlaces, 'decimalPlaces');
  }

  const randomFloat = sample(lowerBound, upperBound, random);
  if (decimalPlaces === null) {
    return randomFloat;
  } else {
    const precision = Math.pow(10, decimalPlaces);
    return Math.floor(randomFloat * precision) / precision;
  }
}

/**
 * Like {@link randomNumber}, but the upper bound is inclusive. Requires rounding to decimal places.
 */
export function randomNumberInclusive(
  lowerBound: number,
  upperBound: number,
  decimalPlaces: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
  } = {}
) {
  return randomNumber(
    lowerBound,
    upperBound + Math.pow(10, -decimalPlaces),
    decimalPlaces,
    options
  );
}

/**
 * Get a random integer within a range of two numbers.
 * (Both bounds are inclusive.)
 */
export function randomIntegerInclusive(
  lowerBound: number,
  upperBound: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
    /** Optional constraint to apply. Defaults to no constraint. */
    constraint?: (x: number) => boolean;
  } = {}
): number {
  return randomUniqueIntegersInclusive(lowerBound, upperBound, 1, options)[0];
}

/**
 * Like {@link randomIntegerInclusive}, except that it takes a step size.
 *
 * upperBound should be a whole number of steps from lowerBound.
 */
export function randomIntegerInclusiveStep(
  lowerBound: number,
  upperBound: number,
  step: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
    /** Optional constraint to apply. Defaults to no constraint. */
    constraint?: (x: number) => boolean;
  } = {}
) {
  return randomUniqueIntegersInclusiveStep(lowerBound, upperBound, step, 1, options)[0];
}

/**
 * Get an array of integers within a range of two numbers. These are all different to each other.
 * (The upper bound is inclusive.)
 *
 * This algorithm is memory-efficient with large ranges, as it never makes an array of all possible values.
 * It is also time-efficient with the constraint, as it remembers rejected values (though if the rejected space
 * is large, this may not be memory-efficient).
 */
export function randomUniqueIntegersInclusive(
  lowerBound: number,
  upperBound: number,
  quantity: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
    /** Optional constraint to apply. Defaults to no constraint. */
    constraint?: (x: number) => boolean;
  } = {}
): number[] {
  const { random = Math.random, sample = uniformSample, constraint = () => true } = options;
  const sampleSpaceSize = upperBound + 1 - lowerBound;
  // Round the lower bound up to the nearest integer
  lowerBound = Math.ceil(lowerBound);
  // Round the upper bound down to the nearest integer
  upperBound = Math.floor(upperBound);
  assertInteger(quantity, 'quantity');
  assertRange(quantity, 'quantity', 0, sampleSpaceSize);

  let numbers: number[] = []; // in ascending order
  const rejected = new Set<number>();
  while (numbers.length - rejected.size < quantity) {
    // Try to find another number
    if (numbers.length === sampleSpaceSize) {
      // But there are no more numbers to try!
      throw Error(
        `Not enough valid numbers in range [${lowerBound},${upperBound}]: required ${quantity}`
      );
    }

    // Remove the existing numbers from the range. This creates numbers.length+1 segments, and numbers.length holes.
    // Imagine joining them up, producing a range that's smaller by numbers.length. Sample from that.
    let sampled = Math.floor(sample(lowerBound, upperBound + 1 - numbers.length, random));

    // Undo the joining up. Find which segment the sampled number belongs to.
    // Work from left to right. For every segment it's not in, need to increase the sampled number by 1 to account
    // for filling the hole.
    for (let i = 0; i < numbers.length + 1; i++) {
      const segmentBound = numbers[i] ?? upperBound + 1;

      if (sampled < segmentBound) {
        // Belongs to this segment.
        numbers = arrayInsert(numbers, sampled, i);
        break;
      }
      sampled++;
    }

    // Check if this number is actually valid. If not, add it to the set of rejections too.
    // Rejections are still used to split up the sample space into segements, they just don't count towards the final
    // tally.
    if (!constraint(sampled)) {
      rejected.add(sampled);
    }
  }

  return shuffle(
    numbers.filter(it => !rejected.has(it)),
    { random }
  );
}

/**
 * Like {@link randomUniqueIntegersInclusive}, except that it takes a step size.
 *
 * upperBound should be a whole number of steps from lowerBound.
 */
export function randomUniqueIntegersInclusiveStep(
  lowerBound: number,
  upperBound: number,
  step: number,
  quantity: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
    /** Optional constraint to apply. Defaults to no constraint. */
    constraint?: (x: number) => boolean;
  } = {}
) {
  const { random = Math.random, sample = uniformSample, constraint = () => true } = options;
  assertInteger(step, 'step');
  assertRange(step, 'step', 1);

  // Sample with everything divided by step, then multiply back up
  const transform = (x: number) => (x - lowerBound) / step;
  const unTransform = (x: number) => x * step + lowerBound;
  const transformedChoices = randomUniqueIntegersInclusive(
    transform(lowerBound),
    transform(upperBound),
    quantity,
    {
      // Sample function must be called on unTransformed values. For example, the transform here might be taking
      // the space of 10 to 100 with steps of 10, to 0 to 9 with steps of 1. If the sample function were
      // logUniformSample, it wouldn't even make sense on the range: 0 to 9 since log(0) is -infinity.
      sample: (a, b) => transform(sample(unTransform(a), unTransform(b), random)),
      constraint: x => constraint(unTransform(x))
    }
  );

  return transformedChoices.map(unTransform);
}

/**
 * Get a random element from an array, leaving the array unchanged.
 * Returns null if the input array is empty.
 *
 * The return type might look a little confusing, but basically if the input array was known by typescript to be
 * non-empty (such as for const arrays) then the return type is non-null. Otherwise, it might be null.
 *
 * If you are sampling integers, consider using {@link randomIntegerInclusive} instead, as it is more memory and time
 * efficient.
 */
export function getRandomFromArray<A extends Readonly<Array<unknown> | NonEmptyArray<unknown>>>(
  input: A,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
  } = {}
): A extends Readonly<NonEmptyArray<unknown>> ? A[number] : A[number] | null {
  const { random = Math.random } = options;
  if (input.length === 0) {
    return null as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  const choice = Math.floor(uniformSample(0, input.length, random));
  return input[choice] as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * Get a random element from an array with weights, leaving the array unchanged.
 * Returns null if the input array is empty.
 *
 * The return type might look a little confusing, but basically if the input array was known by typescript to be
 * non-empty (such as for const arrays) then the return type is non-null. Otherwise, it might be null.
 *
 * If you are sampling integers, consider using {@link randomIntegerInclusive} instead, as it is more memory and time
 * efficient.
 */
export function getRandomFromArrayWithWeights<
  A extends Readonly<Array<unknown> | NonEmptyArray<unknown>>
>(
  input: A,
  weights: number[],
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
  } = {}
): A extends Readonly<NonEmptyArray<unknown>> ? A[number] : A[number] | null {
  const adjustedInput = input
    .map((val, i) => countRange(weights[i]).map(() => val))
    .reduce((a, b) => [...a, ...b]);

  const { random = Math.random } = options;
  if (adjustedInput.length === 0) {
    return null as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  const choice = Math.floor(uniformSample(0, adjustedInput.length, random));
  return adjustedInput[choice] as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * Get a random sub-array from an array, leaving the array unchanged.
 *
 * If the input contains duplicates, the result may also contain duplicates.
 *
 * If you are sampling integers, consider using {@link randomUniqueIntegersInclusive} instead, as it is more memory and time
 * efficient.
 */
export function getRandomSubArrayFromArray<T>(
  input: readonly T[],
  quantity: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
    /** Optional constraint to apply. Uses rejection sampling, tracking rejections. Defaults to no constraint. */
    constraint?: (x: T) => boolean;
  } = {}
): T[] {
  const { random = Math.random, sample = uniformSample, constraint = () => true } = options;

  const chosenIndices = randomUniqueIntegersInclusive(0, input.length - 1, quantity, {
    random,
    sample,
    constraint: index => constraint(input[index])
  });

  return chosenIndices.map(index => input[index]);
}

/**
 * Returns a random boolean
 *
 */
export function getRandomBoolean(): boolean {
  return getRandomFromArray([true, false] as const);
}

/**
 * Shuffle the order of the elements in an array
 */
export function shuffle<T>(
  input: readonly T[],
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
  } = {}
): T[] {
  const { random = Math.random } = options;
  let currentIndex = input.length,
    randomIndex;

  // Copy because read only
  const copy = [...input];

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(uniformSample(0, currentIndex, random));
    currentIndex--;

    // And swap it with the current element.
    [copy[currentIndex], copy[randomIndex]] = [copy[randomIndex], copy[currentIndex]];
  }
  return copy;
}

/**
 * Take a sample from a sample space with a constraint. Rejects results that violate constraints.
 *
 * Warning: runs a while loop until success, so only use when the sample space allowed by the constaints is
 * large enough.
 *
 * Note:
 *
 * This is the most general form of sampling with a constraint, however more specialized functions may be more
 * efficient:
 * - if sampling an integer with a constraint, prefer {@link randomIntegerInclusive}
 * - if sampling from an array with a constraint, prefer {@link getRandomSubArrayFromArray} with quantity 1
 * - if sampling many unique integers with an additional constraint that only looks at one integer at a time, prefer
 *   {@link randomUniqueIntegersInclusive}
 * - if sampling many unique elements from an array with an additional constraint that only looks at one element at a
 *   time, prefer {@link getRandomSubArrayFromArray} with quantity >1
 *
 * For some heuristics:
 *
 * If your sample meets the constraints p of the time, it will take on average 1/p iterations (but it may be more!).
 * So depending on how long your sample code takes to run and how performance critical your code is, different values
 * of p might be acceptable.
 *
 * For example, if your sample meets the constraints 20% of the time, it will take on average 5 iterations. You can
 * also be 99% sure that it won't take more than 21 iterations.
 *
 * If your sample meets the contraints 1% of the time, it will take on average 100 iterations. You can also be 99% sure
 * that it won't take more than 459 iterations. The is still acceptable if your sample code runs very fast.
 *
 * For more maths, note that the number of iterations follows the geometric distribution with parameter p.
 */
export function rejectionSample<T>(sample: () => T, constraint: (t: T) => boolean) {
  let ans: T | null;
  do {
    ans = sample();
  } while (ans === null || !constraint(ans));
  return ans;
}

/**
 * Generates a random integer with an enforced digit in a particular power of ten.
 * The range parameter should be integers, and is inclusive.
 */
export function randomNumberWithSpecificDigit(
  powerToChange: number,
  range: { lower: number; upper: number },
  digit: Digit,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
    /** Sampling function. Defaults to {@link uniformSample}, but other distributions could be used */
    sample?: (a: number, b: number, random: () => number) => number;
  } = {}
) {
  const randomInteger = randomIntegerInclusive(range.lower, range.upper, options);
  return numberWithEnforcedDigit(randomInteger, powerToChange, digit);
}

/*
 * Returns an array of 3 numbers where two of the numbers are consecutive within a range
 * and the third isn't consecutive in the same range.
 * This is used heavily by the number line questions
 */
export function getTwoConsecutiveAndOneNot(
  start: number,
  end: number,
  interval: number,
  options: {
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
  } = {}
) {
  const numbers = new Set<number>();
  const choices = range(start, end, interval);
  if (choices.length < 4) {
    throw new Error('Range is not able to produce two consecutive numbers and one that is not');
  }

  // Make sure the first 2 numbers are consecutive
  // Select the first number for consecutive
  const firstConsecutiveNumber = getRandomFromArray(choices, options);
  numbers.add(firstConsecutiveNumber);

  // Select the second number for consecutive
  const index = choices.indexOf(firstConsecutiveNumber);
  // If the first numbers index is 0 choose the number at index 1.
  if (index === 0) {
    numbers.add(choices[1]);
  }
  // If the first numbers index is the last number, choose the number at the second to last index.
  else if (index === choices.length - 1) {
    numbers.add(choices[choices.length - 2]);
  }
  // If there are only 4 choices and the first index is 1, choose the number at index 0
  else if (index === 1 && choices.length === 4) {
    numbers.add(choices[0]);
  }
  // If there are only 4 choices and the first index is 2, choose the number at index 3
  else if (index === 2 && choices.length === 4) {
    numbers.add(choices[3]);
  }
  // Else just choose the next consecutive number
  else {
    numbers.add(choices[index + 1]);
  }

  // Add the third number that isn't consecutive
  const numberArray = sortNumberArray(Array.from(numbers));
  const firstNumber = numberArray[0];
  const secondNumber = numberArray[1];
  const upper = secondNumber + interval;
  const lower = firstNumber - interval;

  numbers.add(
    rejectionSample(
      () => getRandomFromArray(choices, options),
      x => !numbers.has(x) && x !== upper && x !== lower
    )
  );

  return sortNumberArray(Array.from(numbers));
}

////
// Sampling functions
//
// Technical term is "random variate generation function". Produces a single value (a random variate, not to be
// confused with random variable) where the produced values follows some distribution.
//
// Math.random is itself a sampling function on the interval [0, 1) with uniform distribution, but it's not listed
// here because it's so fundamental to how random number generation is implemented. Sampling functions with other
// distributions are listed here. (Note: technically most of the functions in this file are sampling functions, but
// these are the nice general mathematical ones.)
////

/**
 * Get a random number from [a, b), uniformly distributed. (upper bound is exclusive)
 *
 * This means that the probability of landing in some interval within [a, b) depends on the length of that interval.
 * For example, if a=0 and b=10, the probability of landing between 3 and 6 is 3/10.
 */
export function uniformSample(a: number, b: number, random: () => number = Math.random): number {
  if (b <= a) {
    throw Error(`b must be greater than a: ${a}, ${b}`);
  }
  return a + random() * (b - a);
}

/**
 * Get a random number from [a, b), log-uniformly distributed. (upper bound is exclusive)
 *
 * This means that the probability of landing in some interval within [a, b) depends on both the length of the
 * interval, and where the interval starts. In particular, the interval [a, ax) is hit as many times as [ax, ax^2).
 * For example, if a=1 and b=100, the probability of landing between 1 and 10 is the same as the probability of
 * landing between 10 and 100 (x=10). Since this covers the whole of [a, b), they both have probability 0.5.
 */
export function logUniformSample(a: number, b: number, random: () => number = Math.random): number {
  if (a <= 0 || b <= a) {
    throw Error(`a must be positive, b must be greater than a: ${a}, ${b}`);
  }
  return Math.exp(uniformSample(Math.log(a), Math.log(b), random));
}

////
// Seeded randomness
////

/**
 * Produce a new pseudo-random number generator using a seed number.
 *
 * This returns a function which can be used just like Math.random(), except fully deterministic - if you
 * provide the same seed you get the same sequence of results.
 *
 * Taken from https://github.com/bryc/code/blob/master/jshash/PRNGs.md which is public domain.
 */
export function seededRandom(seed: number | string | object): () => number {
  let a: number;
  if (typeof seed === 'number') {
    a = 0 < seed && seed < 1 ? seed : seed * 4294967296;
  } else {
    // Seed is a string or something that we'll have to stringify. Use safe-json-stringify because we just want a
    // best-effort approach which doesn't throw errors.
    seed = typeof seed === 'string' ? seed : safeJsonStringify(seed);
    let h = 1779033703 ^ seed.length;
    for (let i = 0; i < seed.length; i++) {
      h = Math.imul(h ^ seed.charCodeAt(i), 3432918353);
      h = (h << 13) | (h >>> 19);
    }
    (h = Math.imul(h ^ (h >>> 16), 2246822507)), (h = Math.imul(h ^ (h >>> 13), 3266489909));
    a = (h ^= h >>> 16) >>> 0;
  }

  return function () {
    a |= 0;
    a = (a + 0x6d2b79f5) | 0;
    let t = Math.imul(a ^ (a >>> 15), 1 | a);
    t = (t + Math.imul(t ^ (t >>> 7), 61 | t)) ^ t;
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

////
// Arrangement Functions
////

/**
 * Get a random arrangement of 2 to 12 items.
 *
 * It is defaults to max 4 rows of items, each holding up to 3 items, for a maximum of 12 items, but this can be overriden to suit the space.
 * If the number of items is between 2-5 then it will ignore max rows in favour of just 2 rows
 * The middle two rows will have more items than the others.
 * For each row with a missing item, the elements are slid around randomly.
 *
 * This simply returns the left-margins for each item.
 */
export function getItemArrangement<T>(
  itemsPassed: readonly T[],
  random: () => number,
  maxRows = 4,
  maxCols = 3
): { item: T; marginLeft: number }[][] {
  const items = shuffle(itemsPassed, { random });
  const numItems = items.length;

  // Check if selectables can fit in space
  if (numItems > maxRows * maxCols) {
    throw Error('Selectables will not fit the space');
  }
  // For 2 to 5 items, just use 2 rows. For 6 to 12 items, use all 4 rows or maxRows that can fit.
  const numRows = numItems < 6 ? 2 : maxRows;

  // First, choose how many elements each row should have. Each must have at least 1.
  const population = filledArray(1, numRows);

  filledArray(null, numItems - numRows).forEach(() => {
    const freeRows = range(0, population.length - 1).filter(
      i => population[i] < maxCols
    ) as NonEmptyArray<number>;
    const rowToAddTo = getRandomFromArray(freeRows, { random });
    population[rowToAddTo] += 1;
  });

  // Next, make sure that the middle two rows are the most populated.
  const [middleRow1, middleRow2, outerRow1 = 0, outerRow2 = 0] = sortNumberArray(
    population,
    'descending'
  );
  const [row1, row2] = shuffle([middleRow1, middleRow2], { random });
  const [row0, row3] = shuffle([outerRow1, outerRow2], { random });
  // Filter out empty rows
  const tweakedPopulation = [row0, row1, row2, row3].filter(items => items > 0);

  // Now finally distribute left margins amongst the items
  const itemsRemaining = [...items];

  return tweakedPopulation.map(numItemsThisRow => {
    const minMargin = 10;
    let marginRemainingThisRow = numItemsThisRow === 1 ? 600 : numItemsThisRow === 2 ? 300 : 0;

    return filledArray(null, numItemsThisRow).map(() => {
      const marginShare = randomIntegerInclusiveStep(0, marginRemainingThisRow, 150, { random });
      marginRemainingThisRow -= marginShare;
      const item = itemsRemaining.shift()!;
      return { marginLeft: minMargin + marginShare, item };
    });
  });
}

////
// Assertion helpers
////

function assertInteger(x: unknown, name: string) {
  if (!Number.isInteger(x)) {
    throw Error(`${name} must be an integer: ${x}`);
  }
}

function assertRange(
  x: number,
  name: string,
  lowerInclusive: number = Number.NEGATIVE_INFINITY,
  upperInclusive: number = Number.POSITIVE_INFINITY
) {
  if (x < lowerInclusive || x > upperInclusive) {
    throw Error(`${name} must be in the interval [${lowerInclusive}, ${upperInclusive}]: ${x}`);
  }
}
