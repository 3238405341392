import Year3 from './Year 3';
import Year4 from './Year 4';
import Year5 from './Year 5';
import Year6 from './Year 6';
import { newSolContent } from './Sol';
import { YearContent, YearId } from './Year';
import { QuestionTypeContent } from './Question';
import { TermContent, TermId } from './Term';
import { BlockContent, BlockId } from './Block';
import { SmallStepContent, SmallStepId } from './SmallStep';
import { fluent } from '@codibre/fluent-iterable';
import { TermKey, YearKey } from '../i18n/custom-types';
import { TranslationFunctions } from '../i18n/i18n-types';
import { base64ToString, stringToBase64 } from '../utils/encodings';

/**
 * The data used by the question store app to show an entry for each question definition, with forms, etc.
 *
 * Each data type has an Id (which is unique and serializable, so it can be used as a navigation prop) and some
 * Content. The data is nested in the directory structure, which maps the SoL.
 */
export const Sol = newSolContent({
  years: [Year3, Year4, Year5, Year6]
});

/** Fully qualified question type - contains information about where it is in the course. */
export type FQQuestionTypeContent<Q = Record<string, unknown>> = QuestionTypeContent<Q> &
  SmallStepId & { hide: boolean };

/**
 * Straightforward map from UID to question type.
 *
 * INCLUDES ARCHIVED AND UNPUBLISHED QUESTION TYPES.
 */
export const QuestionTypesByUid: Map<string, FQQuestionTypeContent> = fluent(Sol.years)
  .flatMap(({ year, terms }) =>
    fluent(terms).flatMap(({ term, blocks }) =>
      fluent(blocks).flatMap(({ block, smallSteps }) =>
        fluent(smallSteps).flatMap(
          ({ smallStep, questionTypes, archivedQuestionTypes, unpublishedQuestionTypes }) => {
            const questionTypesArray = fluent(questionTypes).map(questionType => ({
              year,
              term,
              block,
              smallStep,
              ...questionType,
              hide: false
            }));
            const archivedQuestionTypesArray = fluent(archivedQuestionTypes).map(questionType => ({
              year,
              term,
              block,
              smallStep,
              ...questionType,
              hide: true
            }));
            const unpublishedQuestionTypesArray = fluent(unpublishedQuestionTypes).map(
              questionType => ({
                year,
                term,
                block,
                smallStep,
                ...questionType,
                hide: true
              })
            );
            return questionTypesArray
              .concat(archivedQuestionTypesArray)
              .concat(unpublishedQuestionTypesArray);
          }
        )
      )
    )
  )
  .toMap(
    it => it.uid,
    it => it
  );

export const QuestionTypes = [...QuestionTypesByUid.values()];
export const QuestionTypeUids = [...QuestionTypesByUid.keys()];
export const ValidYears: YearKey[] = [...new Set(QuestionTypes.map(it => it.year))];
export const isValidYear = (year: string): year is YearKey => ValidYears.some(it => it === year);
export const ValidTerms: TermKey[] = [...new Set(QuestionTypes.map(it => it.term))];
export const isValidTerm = (term: string): term is TermKey => ValidTerms.some(it => it === term);

/** A question type UID with its data. The first element is the UID, the second element is the parameters. */
export type SpecificQuestion = [uid: string, parameters: Record<string, unknown>];
/** Information uniquely specifying a question type or specific question. This is small and serializable. */
export type QuestionToken = string | [uid: string, parameters: Record<string, unknown>];

/** Fully qualified question type optionally with data. */
export type FQQuestionTypeContentMaybeWithData<Q = Record<string, unknown>> = {
  questionType: FQQuestionTypeContent<Q>;
  data?: Q;
};

export type FQQuestionTypeContentWithData<Q = Record<string, unknown>> = {
  questionType: FQQuestionTypeContent<Q>;
  data: Q;
};

/**
 * Parse a question, returning an error string if it was invalid.
 *
 * The input takes the form of a UID string, or a UID + question params pair (AKA {@link SpecificQuestion}).
 *
 * A question is invalid if:
 * - a Question Type for the UID was not found
 * - the question params (if provided) were not valid according to the Question Type's schema
 *
 * If the input was a valid {@link SpecificQuestion}, the returned object's `data` is guaranteed to conform to the
 * Question Type's schema.
 */
export function parseQuestion(
  question: QuestionToken
): FQQuestionTypeContentMaybeWithData | 'notFound' | 'invalid' {
  if (typeof question === 'string') {
    // Just a UID
    const questionType = QuestionTypesByUid.get(question);
    if (questionType === undefined) return 'notFound';
    return { questionType };
  } else {
    // UID with data - needs validating.
    const questionType = QuestionTypesByUid.get(question[0]);
    if (questionType === undefined) return 'notFound';
    const validationResult = questionType.schema.safeParse(question[1]);
    if (validationResult.success) {
      // Content was valid
      return { questionType, data: validationResult.data };
    } else return 'invalid';
  }
}

/**
 * Like {@link parseQuestion}, except it just returns a boolean of whether it was valid or not.
 */
export function validateQuestion(question: QuestionToken): boolean {
  const result = parseQuestion(question);
  return typeof result !== 'string';
}

/**
 * Like {@link parseQuestion}, except it throws an error if the UID was not found, and it skips validation of the
 * question params (if provided).
 *
 * This is useful for when the question token is trusted by the calling code, e.g. it's already been validated or was
 * created locally. In that case, using this function lets you skip checking whether the return value was an error
 * string.
 */
export function getTrustedQuestion<T extends QuestionToken>(
  question: T
): T extends SpecificQuestion ? FQQuestionTypeContentWithData : FQQuestionTypeContentMaybeWithData {
  if (typeof question === 'string') {
    // Just a UID
    const questionType = QuestionTypesByUid.get(question);
    if (questionType === undefined) {
      throw Error(`Trusted question was not found: ${question}`);
    }

    return { questionType } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  } else {
    // UID with data
    const questionType = QuestionTypesByUid.get(question[0]);
    if (questionType === undefined) {
      throw Error(`Trusted question was not found: ${question}`);
    }

    // Skip validation (unless in dev mode)
    if (__DEV__) {
      if (!validateQuestion(question)) {
        throw Error(`[Dev mode] Trusted question had invalid params: ${question}`);
      }
    }

    return { questionType, data: question[1] };
  }
}

////
// Utility functions for accessing the data a bit more easily.
////

export function getYearContent(id: YearId): YearContent | undefined {
  return Sol.years.find(it => it.year === id.year);
}

export function getTermContent(id: TermId): TermContent | undefined {
  return getYearContent(id)?.terms.find(it => it.term === id.term);
}

export function getBlockContent(id: BlockId): BlockContent | undefined {
  return getTermContent(id)?.blocks.find(it => it.block === id.block);
}

export function getSmallStepContent(id: SmallStepId): SmallStepContent | undefined {
  return getBlockContent(id)?.smallSteps.find(it => it.smallStep === id.smallStep);
}

export function filterQuestionTypes(
  translate: TranslationFunctions,
  /** All terms that the user might enter - they are post-translation. */
  filter: {
    year?: string;
    term?: string;
    block?: string;
    smallStep?: string;
    keywords?: string[];
  }
): QuestionTypeContent<Record<string, unknown>>[] {
  return fluent(QuestionTypes)
    .filter(questionType => {
      return (
        (filter.year === undefined || filter.year === translate.year(questionType.year)) &&
        (filter.term === undefined || filter.term === translate.term(questionType.term)) &&
        (filter.block === undefined || filter.block === translate.block(questionType.block)) &&
        (filter.smallStep === undefined ||
          filter.smallStep === translate.smallStep(questionType.smallStep)) &&
        (filter.keywords === undefined ||
          filter.keywords.every(keyword =>
            questionType.keywords.map(it => translate.keyword(it).toString()).includes(keyword)
          ))
      );
    })
    .toArray();
}

////
// Utility functions for encoding/decoding tokens for URL strings
////

/**
 * Convert a question token to a URL-safe string.
 *
 * String tokens are as-they-are. Tuple tokens are represented as uid~base64EncodedData, e.g.
 * abc~eyJudW1iZXIiOjQyLCJsZWZ0UGFydCI6MjB9, where the base64 encoding uses -_ as its final two symbols.
 */
export function stringifyToken(token: QuestionToken): string {
  if (typeof token === 'string') {
    return token;
  }
  const [uid, data] = token;
  return `${uid}~${stringToBase64(JSON.stringify(data), true)}`;
}

/**
 * Convert several question tokens to a URL-safe string. See {@link stringifyToken}.
 *
 * Tokens are joined using the `.` symbol, e.g. abc.def.ghi is a list of 3 tokens.
 */
export function stringifyTokens(tokens: QuestionToken[]): string {
  return tokens.map(stringifyToken).join('.');
}

/**
 * Converts a URL-safe token string into a question token. Undoes {@link stringifyToken}.
 *
 * Returns error object if the token was invalid (e.g. not recognized or invalid data).
 */
export function parseToken(s: string): QuestionToken | { error: string; token: string } {
  let token: QuestionToken;
  if (s.includes('~')) {
    const [uid, encodedData] = s.split('~');
    try {
      token = [uid, JSON.parse(base64ToString(encodedData, true))] as SpecificQuestion;
    } catch (e) {
      return { error: 'JSON parse error', token: s };
    }
  } else {
    token = s;
  }

  const parseResult = parseQuestion(token);
  if (typeof parseResult === 'string') {
    return { error: parseResult, token: s };
  } else {
    return token;
  }
}

export type TokenParseError = { error: string; token: string };
export function isTokenParseError(x: QuestionToken | TokenParseError): x is TokenParseError {
  return typeof x === 'object' && 'error' in x;
}
export function isQuestionToken(x: QuestionToken | TokenParseError): x is QuestionToken {
  return !isTokenParseError(x);
}

/**
 * Converts a URL-safe token string into an array of question tokens. Undoes {@link stringifyTokens}.
 *
 * Each token may be null if that token is invalid (e.g. not recognized or invalid data).
 */
export function parseTokens(s: string): (QuestionToken | TokenParseError)[] {
  return s.split('.').map(parseToken);
}

/**
 * Like {@link parseTokens}, but just keeps the valid tokens, and logs out an error for the invalid ones.
 */
export function parseTokensBestEffort(s: string): QuestionToken[] {
  const parsed = parseTokens(s);

  const valids = parsed.filter(isQuestionToken);
  if (valids.length !== parsed.length) {
    const invalids = parsed.filter(isTokenParseError);
    console.error(`Filtering out invalid tokens: ${JSON.stringify(invalids)}`);
  }
  return valids;
}
