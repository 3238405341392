import { StyleSheet, View } from 'react-native';
import { AssetSvg } from 'common/src/assets/svg';
import Text from 'common/src/components/typography/Text';
import { colors } from 'common/src/theme/colors';
import { Image } from 'expo-image';

type QuizDetailsProps = {
  // Stars achieved by the pupil
  quizStars: number;
  // Total possible stars for the quiz
  totalStars: number;
  // Has the quiz been started
  inProgress: boolean;
  // Is the quiz in infinite mode
  isInfinite: boolean;
};

/**
 * Quiz Details for the Quiz Tile
 * Includes number of stars and whether the quiz is in infinite mode or not
 */
export function QuizDetails({ quizStars, totalStars, inProgress, isInfinite }: QuizDetailsProps) {
  return (
    <View style={styles.wrapper}>
      <View style={{ flexDirection: 'row', width: '50%', padding: 10 }}>
        {inProgress && (
          <View style={[styles.score]}>
            <AssetSvg name="Star" height={30} width={32} />
            <Text
              style={[styles.labelText, styles.scoreLabel]}
            >{`${quizStars}/${totalStars}`}</Text>
          </View>
        )}
      </View>
      {isInfinite && (
        <View style={{ width: '50%', padding: 10, alignItems: 'flex-end' }}>
          <Image
            source={require('pupil-app/assets/svg/Infinity_white.svg')}
            style={{ width: 36, height: 36 }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 50
  },
  labelText: {
    color: colors.white,
    fontFamily: 'White_Rose_Noto',
    fontSize: 22,
    lineHeight: 32.5
  },
  score: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  scoreLabel: {
    paddingLeft: 4
  }
});
