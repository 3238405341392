import BaseLayout from 'common/src/components/molecules/BaseLayout';
import {
  CreateArrayOfObjects,
  CreateArrayOfObjectsWithState
} from 'common/src/components/question/representations/CreateArrayOfObjects';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { arraysHaveSameContents, filledArray } from 'common/src/utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * Number of rows
   */
  numberOfRows: number;
  /**
   * Number of columns - Will be the same amount per row
   */
  numberOfCols: number;
  /**
   * The expected dimensions of the array. Will check either orientation, e.g. passing [3, 4] will check the array is
   * either 3 wide by 4 tall, or 4 wide by 3 tall.
   */
  testCorrect: [number, number];
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: boolean[][]; answerText?: string };
  /** PDF Question Height */
  questionHeight?: number;
};

export default function QF29CreateArray({
  numberOfRows,
  numberOfCols,
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  questionHeight,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const filterSelectedCols = (array: boolean[][]) => {
    return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const width = Math.max(...testCorrect);
    const height = Math.min(...testCorrect);
    const markSchemeAnswer = filledArray(filledArray(true, width), height);
    const arrayDimens = 150;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={{ justifyContent: 'flex-start' }}
        mainPanelContents={
          <>
            <CreateArrayOfObjects
              userAnswer={
                displayMode === 'markscheme'
                  ? customMarkSchemeAnswer?.answersToDisplay ?? markSchemeAnswer
                  : undefined
              }
              numberOfRows={height}
              numberOfCols={width}
              dimens={{ width: arrayDimens * width, height: arrayDimens * height }}
            />

            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <CreateArrayOfObjectsWithState
              id="array"
              testComplete={answer => filterSelectedCols(answer).length > 0}
              testCorrect={userAnswer => {
                const expectedWidth = testCorrect[0];
                const expectedHeight = testCorrect[1];

                const firstRowWithTrue = userAnswer.find(row => row.includes(true)) ?? [];
                const allRowsWithTrue = userAnswer.filter(row => row.includes(true));

                // Check that each row with counters matches the shape of the first row with counters.
                const allRowsWithTrueMatchFirstRowWithTrue = () => {
                  return allRowsWithTrue.every(row =>
                    arraysHaveSameContents(row, firstRowWithTrue)
                  );
                };

                // Check each row has the correct number of counters:
                const allRowsHaveCorrectNumberOfCounters = () => {
                  const expectedRowCount =
                    allRowsWithTrue.length === expectedHeight ? expectedWidth : expectedHeight;
                  return allRowsWithTrue.every(
                    row => row.filter(element => element === true).length === expectedRowCount
                  );
                };

                // Check each row with counters has counters only consecutively with each other, not separated.
                const allRowsWithTrueHaveConsecutiveTrues = () => {
                  for (let i = 0; i < allRowsWithTrue.length; i++) {
                    let lastElementWithTrue = -1;
                    for (let j = 0; j < allRowsWithTrue[i].length; j++) {
                      if (
                        allRowsWithTrue[i][j] === true &&
                        (lastElementWithTrue === -1 || lastElementWithTrue === j - 1)
                      ) {
                        lastElementWithTrue = j;
                      } else if (
                        allRowsWithTrue[i][j] === true &&
                        lastElementWithTrue !== -1 &&
                        lastElementWithTrue !== j - 1
                      ) {
                        return false;
                      }
                    }
                  }
                  return true;
                };

                // Check all row with counters are consecutive with each other, not separated.
                const allRowsWithTrueAreConsecutive = () => {
                  let lastArrayWithTrue = -1;

                  for (let i = 0; i < userAnswer.length; i++) {
                    if (
                      userAnswer[i].includes(true) &&
                      (lastArrayWithTrue === -1 || lastArrayWithTrue === i - 1)
                    ) {
                      lastArrayWithTrue = i;
                    } else if (
                      userAnswer[i].includes(true) &&
                      lastArrayWithTrue !== -1 &&
                      lastArrayWithTrue !== i - 1
                    ) {
                      return false;
                    }
                  }
                  return true;
                };

                return (
                  allRowsWithTrueMatchFirstRowWithTrue() &&
                  // Check the correct number of rows are highlighted:
                  (allRowsWithTrue.length === expectedWidth ||
                    allRowsWithTrue.length === expectedHeight) &&
                  allRowsHaveCorrectNumberOfCounters() &&
                  allRowsWithTrueAreConsecutive() &&
                  allRowsWithTrueHaveConsecutiveTrues()
                );
              }}
              numberOfRows={numberOfRows}
              numberOfCols={numberOfCols}
              dimens={dimens}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
