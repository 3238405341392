import Svg, { Line, Path, Rect } from 'react-native-svg';
import { View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { colors } from '../../../theme/colors';
import { drawArrowHead } from './Number Line/curve';

export const rightAngleSVG = (
  numberOfRightAngles: 0 | 1 | 2 | 4,
  dimens: Dimens,
  isLeft: boolean
) => {
  switch (numberOfRightAngles) {
    case 0:
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={isLeft ? dimens.width * 0.5 : 10}
            y1={10}
            x2={dimens.width - 10}
            y2={10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? dimens.width * 0.5 : 10}
            y1={10}
            x2={isLeft ? 10 : dimens.width - 10}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 1:
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={10}
            x2={dimens.width - 10}
            y2={10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? 10 : dimens.width - 10}
            y1={10}
            x2={isLeft ? 10 : dimens.width - 10}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 2:
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={10}
            x2={dimens.width - 10}
            y2={10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y1={10}
            x2={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 4:
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={dimens.height / 2}
            x2={dimens.width - 10}
            y2={dimens.height / 2}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y1={10}
            x2={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
  }
};

/** This returns a svg of two lines, if you need the lines in a square grid use {@link lineSvgPoints} */
export const lineSvg = (
  dimens: Dimens,
  type:
    | 'parallel'
    | 'perpendicular'
    | 'touching'
    | 'noIntersect'
    | 'curvedCrossing'
    | 'meetingPoint'
    | 'intersect',
  isLeft: boolean
) => {
  switch (type) {
    case 'parallel':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={dimens.height * 0.3}
            x2={dimens.width - 10}
            y2={dimens.height * 0.3}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={10}
            y1={dimens.height * 0.7}
            x2={dimens.width - 10}
            y2={dimens.height * 0.7}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'perpendicular':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={10}
            x2={dimens.width - 10}
            y2={10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y1={10}
            x2={isLeft ? dimens.width * 0.3 : dimens.width * 0.7}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'touching':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={isLeft ? dimens.width * 0.5 : 10}
            y1={10}
            x2={dimens.width - 10}
            y2={10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={isLeft ? dimens.width * 0.5 : 10}
            y1={10}
            x2={isLeft ? 10 : dimens.width - 10}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'noIntersect':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={isLeft ? 10 : dimens.height - 10}
            x2={dimens.width / 2 - 20}
            y2={isLeft ? dimens.height - 10 : 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={dimens.width * 0.7 + 20}
            y1={isLeft ? dimens.height - 10 : 10}
            x2={dimens.width - 20}
            y2={isLeft ? 10 : dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'curvedCrossing':
      return (
        <View style={{ transform: isLeft ? 'rotate(180deg)' : 'rotate(0deg)' }}>
          <Svg
            width={dimens.width}
            height={dimens.height}
            viewBox={`0 0 ${dimens.width} ${dimens.height}`}
          >
            <Path
              d={`M10,${dimens.height * 0.8}
            C${dimens.width / 6},${dimens.height * 0.66} ${dimens.width * 0.66},${
              dimens.height / 3
            } ${dimens.width},${dimens.height * 0.66}`}
              stroke={colors.prussianBlue}
              strokeWidth={4}
              fill="none"
            />
            <Path
              d={`M${dimens.width},${dimens.height * 0.8} C${dimens.width / 6},${
                dimens.height * 0.6
              } ${dimens.width * 0.6},${dimens.height / 3} ${dimens.width / 2},0`}
              stroke={colors.prussianBlue}
              strokeWidth={4}
              fill="none"
            />
          </Svg>
        </View>
      );
    case 'meetingPoint':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={dimens.width / 2}
            y1={10}
            x2={dimens.width / 2}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={dimens.width / 2}
            y1={10}
            x2={isLeft ? dimens.width * 0.3 : dimens.width * 0.8}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'intersect':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={dimens.height / 2}
            x2={dimens.width - 10}
            y2={dimens.height / 2}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
          <Line
            x1={dimens.width / 2}
            y1={10}
            x2={isLeft ? dimens.width * 0.3 : dimens.width * 0.8}
            y2={dimens.height - 10}
            stroke={colors.prussianBlue}
            strokeWidth={4}
          />
        </Svg>
      );
  }
};

/** This returns the points for two svg lines (can be used with {@link GridLine} to show the lines in a square grid) */
export const lineSvgPoints = (
  xMax: number,
  yMax: number,
  type: 'parallel' | 'perpendicular' | 'touching' | 'noIntersect',
  isLeft: boolean
) => {
  switch (type) {
    case 'parallel':
      return {
        line1: [
          [0, Math.round(yMax * 0.3)],
          [xMax, Math.round(yMax * 0.3)]
        ],
        line2: [
          [0, Math.round(yMax * 0.7)],
          [xMax, Math.round(yMax * 0.7)]
        ]
      };
    case 'perpendicular':
      return {
        line1: [
          [0, yMax],
          [xMax, yMax]
        ],
        line2: [
          [isLeft ? Math.round(xMax * 0.3) : Math.round(xMax * 0.7), yMax],
          [isLeft ? Math.round(xMax * 0.3) : Math.round(xMax * 0.7), 0]
        ]
      };
    case 'touching':
      return {
        line1: [
          [isLeft ? Math.round(xMax * 0.5) : 0, yMax],
          [xMax, yMax]
        ],
        line2: [
          [isLeft ? Math.round(xMax * 0.5) : 0, yMax],
          [isLeft ? 0 : xMax, 0]
        ]
      };
    case 'noIntersect':
      return {
        line1: [
          [0, isLeft ? yMax : 0],
          [Math.round(xMax * 0.5) - 1, isLeft ? 0 : yMax]
        ],
        line2: [
          [Math.round(xMax * 0.5) + 1, isLeft ? 0 : yMax],
          [Math.round(xMax * 0.9), isLeft ? yMax : 0]
        ]
      };
  }
};

// draw a vertical arrow with given length and stroke width
export const verticalArrow = (length: number, strokeWidth = 4) => {
  return (
    <Svg
      viewBox={`0 0 ${strokeWidth * 6} ${length}`}
      fill="none"
      width={strokeWidth * 6}
      height={length}
    >
      <Rect
        x={strokeWidth * 3 - strokeWidth / 2}
        width={strokeWidth}
        height={length - 5}
        rx="2"
        fill="#6D7F93"
      />

      <Path
        d={drawArrowHead(strokeWidth * 3, length, strokeWidth * 3, 4 * strokeWidth)}
        fill="#6D7F93"
      />
    </Svg>
  );
};

// draw a horizontal arrow with given length and stroke width
export const horizontalArrow = (length: number, strokeWidth = 4) => {
  return (
    <Svg
      viewBox={`0 0 ${length} ${strokeWidth * 6}`}
      fill="none"
      width={length}
      height={strokeWidth * 6}
    >
      <Rect
        y={strokeWidth * 3 - strokeWidth / 2}
        width={length - 5}
        height={strokeWidth}
        rx="2"
        fill={colors.greys500}
      />
      <Path
        d={drawArrowHead(length, strokeWidth * 3, strokeWidth * 3, 4 * strokeWidth)}
        fill={colors.greys500}
        transform={`rotate(270, ${length - (3 * strokeWidth) / 2}, ${
          (strokeWidth * 3) / 2
        }) translate(-${strokeWidth * 3})`}
      />
    </Svg>
  );
};

/** This returns a svg of two lines, if you need the lines in a square grid use {@link lineSvgPoints} */
export const horizontalDoubleEndedArrow = (length: number, strokeWidth = 4) => {
  return (
    <Svg
      viewBox={`0 0 ${length} ${strokeWidth * 6}`}
      fill="none"
      width={length}
      height={strokeWidth * 6}
    >
      <Path
        d={drawArrowHead(0, strokeWidth * 3, strokeWidth * 3, 4 * strokeWidth)}
        fill={colors.greys500}
        transform={`rotate(90, ${length - (3 * strokeWidth) / 2}, ${
          (strokeWidth * 3) / 2
        }) translate(-${strokeWidth * 3})`}
      />
      <Path
        d={`M${4 * strokeWidth} ${strokeWidth * 6}L${0} ${strokeWidth * 3}L${4 * strokeWidth} 0 Z`}
        fill={colors.greys500}
      />
      <Rect
        y={strokeWidth * 3 - strokeWidth / 2}
        x={strokeWidth * 3}
        width={length - strokeWidth * 4}
        height={strokeWidth}
        rx="2"
        fill={colors.greys500}
      />
      <Path
        d={drawArrowHead(length, strokeWidth * 3, strokeWidth * 3, 4 * strokeWidth)}
        fill={colors.greys500}
        transform={`rotate(270, ${length - (3 * strokeWidth) / 2}, ${
          (strokeWidth * 3) / 2
        }) translate(-${strokeWidth * 3})`}
      />
    </Svg>
  );
};
