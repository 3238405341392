import { View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { Line, Svg } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from '../../../utils/random';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * @param shape Chosen shape to display line of symmetry on.
   */
  shape: 'square' | 'rectangleVertial' | 'rectangleHorizontal';
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  lineOfSymmetry: 'horizontal' | 'vertical' | 'diagonal';
  /* a decimal multiplier to move the line of symmetry */
  offset?: number;
};

/**
 * This component renders a 2D image with lines of symmetry.
 * If you want a false line of symmetry use the optional offset prop.
 */
export const LinesOfSymmetry = (props: Props) => {
  const { shape, dimens, lineOfSymmetry, offset = 1 } = props;
  const displayMode = useContext(DisplayMode);
  const lineWidth = 8;
  const array =
    shape === 'square'
      ? ([
          'Square/square_blue',
          'Square/square_green',
          'Square/square_grey',
          'Square/square_pink',
          'Square/square_yellow'
        ] as const)
      : ([
          'Rectangle/rectangle_blue',
          'Rectangle/rectangle_green',
          'Rectangle/rectangle_pink',
          'Rectangle/rectangle_yellow'
        ] as const);

  const height = dimens.height;

  const svgName = getRandomFromArray(array, { random: seededRandom(props.shape) });

  // go vertical rectangle
  const info = getSvgInfo(svgName);
  const shapeWidth = height * info.aspectRatio;

  let svgHeight;
  let svgWidth;

  const lineOverhang = 15;
  let x1 = 0;
  let x2 = 0;
  let y1 = 0;
  let y2 = 0;

  switch (lineOfSymmetry) {
    case 'horizontal': {
      x1 = 0;
      x2 = shapeWidth + 2 * lineOverhang;
      y1 = (height / 2) * offset;
      y2 = (height / 2) * offset;
      svgHeight = height;
      svgWidth = shapeWidth + 2 * lineOverhang;
      break;
    }
    case 'vertical': {
      x1 = (shapeWidth / 2) * offset;
      x2 = (shapeWidth / 2) * offset;
      y1 = 0;
      y2 = height + 2 * lineOverhang;
      svgHeight = height + 2 * lineOverhang;
      svgWidth = shapeWidth;
      break;
    }
    case 'diagonal': {
      const randomDirection = getRandomFromArray(['left', 'right'], {
        random: seededRandom(props.shape)
      });
      x1 = offset !== 1 ? 100 * offset : 0;
      x2 = offset !== 1 ? shapeWidth + lineOverhang + 100 * offset : shapeWidth + lineOverhang;
      y1 = randomDirection === 'left' ? 0 : height + lineOverhang;
      y2 = randomDirection === 'left' ? height + lineOverhang : 0;
      svgHeight = height + lineOverhang;
      svgWidth = shapeWidth + lineOverhang;
      break;
    }
    default:
      break;
  }

  // Top line
  const symmetryLine = (
    <Svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      pointerEvents={'none'}
    >
      <Line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
        strokeWidth={lineWidth}
        key={'symmetry'}
      />
    </Svg>
  );

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <AssetSvg name={svgName} height={height} />
      <View style={{ position: 'absolute' }}>{symmetryLine}</View>
    </View>
  );
};
