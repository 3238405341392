import z from 'zod';

import { type SvgName } from '../assets/svg';
import { throwError } from './flowControl';
import { zodEnumFromObjKeys } from './zod';

export const multiLinkCubeColors = ['Orange', 'Blue', 'Purple', 'Green', 'Red'] as const;

export const MultiLinkCubeColourSchema = z.enum(multiLinkCubeColors);

export type MultiLinkCubeName = (typeof multiLinkCubeColors)[number];

/**
 * Object contain all the names of SVGs related to multi link cubes.
 */
const multiLinkCubeNames: Record<MultiLinkCubeName, (SvgName | undefined)[]> = {
  Orange: [
    // 3
    'Multi_link_cubes/column_3_orange',
    // 4
    'Multi_link_cubes/column_4_orange',
    // 5
    'Multi_link_cubes/column_5_orange',
    // 6
    'Multi_link_cubes/column_6_orange',
    // 7
    'Multi_link_cubes/column_7_orange',
    // 8
    'Multi_link_cubes/column_8_orange'
  ],
  Blue: [
    // 3
    'Multi_link_cubes/column_3_blue',
    // 4
    'Multi_link_cubes/column_4_blue',
    // 5
    'Multi_link_cubes/column_5_blue',
    // 6
    'Multi_link_cubes/column_6_blue',
    // 7
    'Multi_link_cubes/column_7_blue',
    // 8
    'Multi_link_cubes/column_8_blue'
  ],
  Purple: [
    // 3
    'Multi_link_cubes/column_3_purple',
    // 4
    'Multi_link_cubes/column_4_purple',
    // 5
    'Multi_link_cubes/column_5_purple',
    // 6
    'Multi_link_cubes/column_6_purple',
    // 7
    'Multi_link_cubes/column_7_purple',
    // 8
    'Multi_link_cubes/column_8_purple'
  ],
  Green: [
    // 3
    'Multi_link_cubes/column_3_green',
    // 4
    'Multi_link_cubes/column_4_green',
    // 5
    'Multi_link_cubes/column_5_green',
    // 6
    'Multi_link_cubes/column_6_green',
    // 7
    'Multi_link_cubes/column_7_green',
    // 8
    'Multi_link_cubes/column_8_green'
  ],
  Red: [
    // 3
    'Multi_link_cubes/column_3_red',
    // 4
    'Multi_link_cubes/column_4_red',
    // 5
    'Multi_link_cubes/column_5_red',
    // 6
    'Multi_link_cubes/column_6_red',
    // 7
    'Multi_link_cubes/column_7_red',
    // 8
    'Multi_link_cubes/column_8_red'
  ]
};

/**
 * Get multi link cubes object SVG name.
 * Requires a quantity from 3 to 8.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getMultiLinkCubeSvgName = (colour: MultiLinkCubeName, quantity: number): SvgName => {
  if (quantity > 8 || quantity < 3) {
    throw new Error(`${quantity} ${colour} does not exist.`);
  }
  return (
    multiLinkCubeNames[colour][quantity - 3] ??
    throwError(`Couldn't find foodFractionObject image: ${quantity} ${colour}`)
  );
};

type MultiShapeInfo = {
  svg: SvgName;
  numberOfCubes: number;
  hasDepth: boolean;
  isIrregular: boolean;
  width: number;
  height: number;
  depth: number;
  isCube: boolean;
};

export const multilinkShapes: Record<string, MultiShapeInfo> = {
  Multilink_Shape_1: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids1',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 8,
    width: 2,
    height: 2,
    depth: 2,
    isCube: true
  },
  Multilink_Shape_2: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids2',
    isIrregular: false,
    hasDepth: false,
    numberOfCubes: 3,
    width: 3,
    height: 1,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_3: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids3',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 27,
    width: 3,
    height: 3,
    depth: 3,
    isCube: true
  },
  Multilink_Shape_4: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids4',
    isIrregular: false,
    hasDepth: false,
    numberOfCubes: 10,
    width: 2,
    height: 5,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_5: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids5',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 12,
    width: 2,
    height: 3,
    depth: 2,
    isCube: false
  },
  Multilink_Shape_6: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids6',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 8,
    width: 4,
    height: 1,
    depth: 2,
    isCube: false
  },
  Multilink_Shape_7: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids7',
    isIrregular: false,
    hasDepth: false,
    numberOfCubes: 4,
    width: 1,
    height: 4,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_8: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids8',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 64,
    width: 4,
    height: 4,
    depth: 4,
    isCube: true
  },
  Multilink_Shape_9: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids9',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 24,
    width: 3,
    height: 2,
    depth: 4,
    isCube: false
  },
  Multilink_Shape_10: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids10',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 2,
    width: 1,
    height: 1,
    depth: 2,
    isCube: false
  },
  Multilink_Shape_11: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids11',
    isIrregular: false,
    hasDepth: false,
    numberOfCubes: 1,
    width: 1,
    height: 1,
    depth: 1,
    isCube: true
  },
  Multilink_Shape_12: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids12',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 12,
    width: 6,
    height: 1,
    depth: 2,
    isCube: false
  },
  Multilink_Shape_13: {
    svg: 'Multilink_shapes/Multilink_cubes_and_cuboids13',
    isIrregular: false,
    hasDepth: true,
    numberOfCubes: 24,
    width: 3,
    height: 4,
    depth: 2,
    isCube: false
  },
  Multilink_Shape_14: {
    svg: 'Multilink_shapes/Multilink_shape1_4cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 4,
    width: 2,
    height: 3,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_15: {
    svg: 'Multilink_shapes/Multilink_shape1_6cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 6,
    width: 2,
    height: 4,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_16: {
    svg: 'Multilink_shapes/Multilink_shape1_8cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 8,
    width: 2,
    height: 5,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_17: {
    svg: 'Multilink_shapes/Multilink_shape1_10cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 10,
    width: 3,
    height: 4,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_18: {
    svg: 'Multilink_shapes/Multilink_shape1_12cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 12,
    width: 4,
    height: 4,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_19: {
    svg: 'Multilink_shapes/Multilink_shape2_4cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 4,
    width: 3,
    height: 2,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_20: {
    svg: 'Multilink_shapes/Multilink_shape2_7cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 7,
    width: 4,
    height: 2,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_21: {
    svg: 'Multilink_shapes/Multilink_shape2_8cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 8,
    width: 5,
    height: 2,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_22: {
    svg: 'Multilink_shapes/Multilink_shape2_10cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 10,
    width: 5,
    height: 3,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_23: {
    svg: 'Multilink_shapes/Multilink_shape2_12cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 12,
    width: 5,
    height: 3,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_24: {
    svg: 'Multilink_shapes/Multilink_shape3_4cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 4,
    width: 2,
    height: 3,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_25: {
    svg: 'Multilink_shapes/Multilink_shape3_6cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 6,
    width: 2,
    height: 5,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_26: {
    svg: 'Multilink_shapes/Multilink_shape3_8cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 8,
    width: 3,
    height: 4,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_27: {
    svg: 'Multilink_shapes/Multilink_shape3_10cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 10,
    width: 3,
    height: 5,
    depth: 1,
    isCube: false
  },
  Multilink_Shape_28: {
    svg: 'Multilink_shapes/Multilink_shape3_12cubes',
    isIrregular: true,
    hasDepth: false,
    numberOfCubes: 12,
    width: 3,
    height: 5,
    depth: 1,
    isCube: false
  }
};

/**
 * Pixel values of different parts of a multilink cube.
 */
const multiLinkVals = {
  // Pixel width of the right side of the image depicting the 'depth', that isn't the final part.
  rightDepthNonFinal: 41.75773,
  // Pixel width of the right side of the image depicting the 'depth', that is the final part.
  // This part has an extra bump that sticks out, which is why this part is slightly wider.
  rightDepthFinal: 62.30656,
  // Pixel height of the top side of the image depicting the 'depth', that isn't the final part.
  topDepthNonFinal: 36.44934,
  // Pixel height of the top side of the image depicting the 'depth', that is the final part.
  topDepthFinal: 39.50253,
  // Width of the front face of a multilink cube.
  width: 111.57759,
  // Height of the front face of a multilink cube.
  height: 111.57759
};

/**
 * Function to quickly calculate the pixel width and height of a multilink image,
 * based on the number of cubes wide, tall and deep the image is.
 */
const calcMultilinkDimens = (shape: string) => {
  const data = multilinkShapes[shape];

  const totalWidth =
    data.width * multiLinkVals.width +
    (data.depth - 1) * multiLinkVals.rightDepthNonFinal +
    multiLinkVals.rightDepthFinal;

  const totalHeight =
    data.height * multiLinkVals.height +
    (data.depth - 1) * multiLinkVals.topDepthNonFinal +
    multiLinkVals.topDepthFinal;

  return { totalWidth, totalHeight };
};

/**
 * Function to produce a set of scales by which to size an array of multilink images.
 * The 'biggest' image will have a scale of 1,
 * while smaller images will have a scale less than 1 to shrink these down proportionally.
 * Scales are returned in the same order as the image order passed.
 */
export const scaleMultilinkShapes = (shapes: string[]) => {
  // Calculate dimensions for each shape
  const dimensions = shapes.map(shape => calcMultilinkDimens(shape));

  // Find the maximum width and height among the shapes
  const maxWidth = Math.max(...dimensions.map(d => d.totalWidth));
  const maxHeight = Math.max(...dimensions.map(d => d.totalHeight));

  // Calculate scale factors for each shape
  const scaledShapes = dimensions.map(d => {
    const currentGreatestDimen = Math.max(d.totalWidth, d.totalHeight);

    const scale = Math.min(currentGreatestDimen / maxWidth, currentGreatestDimen / maxHeight);

    return scale;
  });

  return scaledShapes;
};

export type MultiLinkedShapeNames = keyof typeof multilinkShapes;
export const MultilinkShapesSchema = zodEnumFromObjKeys(multilinkShapes);

export function filterMultilinkShapes({
  numberOfCubes,
  hasDepth,
  isIrregular,
  isCube
}: {
  /** numberOfCubes filters by range [lowest, highest] the range is [1,64] */
  numberOfCubes?: [number, number];
  hasDepth?: boolean;
  isIrregular?: boolean;
  isCube?: boolean;
}): MultiLinkedShapeNames[] {
  return Object.entries(multilinkShapes)
    .filter(([_, info]) => {
      const cubes =
        numberOfCubes === undefined
          ? true
          : info.numberOfCubes >= numberOfCubes[0] && info.numberOfCubes <= numberOfCubes[1];
      const irregular = isIrregular === undefined ? true : info.isIrregular === isIrregular;
      const flat = hasDepth === undefined ? true : info.hasDepth === hasDepth;
      const cube = isCube === undefined ? true : info.isCube === isCube;

      return cubes && irregular && flat && cube;
    })
    .map(([name]) => name);
}
