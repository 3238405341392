import { filledArray, sumNumberArray } from '../../utils/collections';

export type DraggableVariant =
  | 'square'
  | 'largeSquare'
  | 'pdfSquare'
  | 'rectangle'
  | 'shortRectangle'
  | 'tallRectangle';

export const DRAGGABLE_DIMENS = {
  square: { width: 96, height: 96 },
  largeSquare: { width: 212, height: 212 },
  pdfSquare: { width: 150, height: 150 },
  rectangle: { width: 320, height: 96 },
  shortRectangle: { width: 160, height: 96 },
  tallRectangle: { width: 320, height: 150 }
} as const;

/**
 * Converts the drag and drop state `number[][]`, i.e. a list of drop zones, each with a list of ids of draggables
 * within them, to a list of _values_ (which the QF writer understands).
 */
export function getValuesTransformer<DragValue>(
  items: { component: string | JSX.Element; value: DragValue }[],
  moveOrCopy: 'move' | 'copy'
) {
  return {
    transform: (state: number[][]): DragValue[][] =>
      state.map(container => container.map(itemId => items[itemId]?.value)),
    /** Best effort to place the values using the corresponding draggable IDs. */
    untransform: (transformedState: readonly DragValue[][]): number[][] => {
      const itemsCopy: ({ component: string | JSX.Element; value: DragValue } | undefined)[] = [
        ...items
      ];

      return transformedState.map(container =>
        container.flatMap(value => {
          // Try to find an item with this value
          const id = itemsCopy.findIndex(item => item?.value === value);
          if (id === -1) {
            console.warn(`Not enough draggable items for value ${value} found!`);
            return [];
          }

          // If 'move', we can only have this item in one place so remove it from our list.
          if (moveOrCopy === 'move') {
            itemsCopy[id] = undefined;
          }
          return [id];
        })
      );
    },
    untransformIndex: (target: number) => target
  };
}

/**
 * In the case where all the drop zones have at most one element, this converts each drop zone's array to a single
 * values (or undefiend).
 * Here, T is a single item's value or ID.
 */
export const singleZonesTransformer = {
  transform: <T>(state: T[][]): (T | undefined)[] => state.map(it => it[0]),
  untransform: <T>(transformedState: (T | undefined)[]): T[][] =>
    transformedState.map(it => (it === undefined ? [] : [it])),
  untransformIndex: (target: number) => target
};

/**
 * Sometimes the QF would prefer to arrange the drop zones as a 2D grid. This transforms a 1D array into a 2D array,
 * using information about the numbers of columns in each row.
 *
 * Here, T is the contents of an entire drop zone.
 *
 * @param columnsPerRow The number of columns allowed in each row.
 */
export function getGridTransformer(columnsPerRow: number[]) {
  const emptyGrid: readonly undefined[][] = columnsPerRow.map(columnsThisRow =>
    filledArray(undefined, columnsThisRow)
  );

  return {
    transform: <T>(state: T[]): T[][] => {
      let index = 0;
      return emptyGrid.map(sentence => sentence.map(() => state[index++]));
    },
    untransform: <T>(transformedState: T[][]): T[] => transformedState.flatMap(it => it),
    untransformIndex: (targetRow: number, targetColumn: number): number => {
      const startingIndex = sumNumberArray(
        emptyGrid.filter((_, index) => index < targetRow).map(row => row.length)
      );
      return startingIndex + targetColumn;
    }
  };
}
