import { z } from 'zod';
import { type Expand } from 'common/src/utils/types';
import { isHttpSuccess, postRequestQuery, putRequestQuery } from './requests';
import { type QuestionResultsInfo } from '../storage/useQuestionQueueStore';
import { type QuizSessionInfo } from '../storage/useQuizSessionStore';
import Logger from '../utils/logger';

/** Request data for creating a new quiz session. */
export type getQuizQuestionsPayload = {
  learningGroupShareCode: string;
  quizVersionShareShareCode: string;
};

/** Schema for a Question, according to the API. */
const questionApiSchema = z.object({
  '@id': z.string(),
  displayOrder: z.number().int(),
  parameters: z.record(z.string(), z.unknown()).optional(),
  questionType: z.object({
    uid: z.string()
  })
});

/** A Question, according to the API. */
type QuestionApiEntity = Expand<z.infer<typeof questionApiSchema>>;

/** Schema for a Quiz Session, according to the API. */
const quizSessionApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  year: z.string(),
  randomiseQuestionParameters: z.boolean(),
  questions: questionApiSchema.array(),
  quizSounds: z.boolean().optional(),
  expiresAt: z.string().datetime({ offset: true })
});

/** A Quiz Session, according to the API. */
type QuizSessionApiEntity = Expand<z.infer<typeof quizSessionApiSchema>>;

/** Create a Quiz Session for a shared quiz. */
export const getQuizQuestions = async (
  payload: getQuizQuestionsPayload
): Promise<
  | QuizSessionInfo
  | 'network error'
  | 'http error'
  | 'not found'
  | 'quiz locked'
  | 'invalid response'
  | 'unknown error'
> => {
  const endpoint = '/web/infinity/quiz-sessions';

  const result = await postRequestQuery(endpoint, payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', 'getQuizQuestions', 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        switch (result.response.status) {
          case 404:
            Logger.captureEvent('error', 'getQuizQuestions', 'QUIZ_NOT_FOUND', {
              additionalMsg: payload.quizVersionShareShareCode,
              eventData: payload
            });

            return 'not found';
          case 403:
            Logger.captureEvent('warning', 'getQuizQuestions', 'QUIZ_LOCKED', {
              additionalMsg: payload.quizVersionShareShareCode,
              eventData: payload
            });

            // Quiz locked due to expiry date or max uses exceeded
            return 'quiz locked';
          default:
            Logger.captureEvent('error', 'getQuizQuestions', 'HTTP_ERROR', { eventData: result });

            return 'http error';
        }
      case 'unknown':
        Logger.captureEvent('error', 'getQuizQuestions', 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        Logger.captureEvent('fatal', 'getQuizQuestions', 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  const parseResults = quizSessionApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', 'getQuizQuestions', 'PARSE_ERROR', { eventData: parseResults });

    return 'invalid response';
  }

  // Validation success
  const parsedData: QuizSessionApiEntity = parseResults.data;

  // Check if the quiz has expired. We'd expect to get a 403 response and fail above, but just to be safe we double
  // check.
  const expiresAt = new Date(parsedData.expiresAt);
  const now = new Date();
  if (expiresAt < now) {
    // Quiz has expired
    Logger.captureEvent('info', 'getQuizQuestions', 'QUIZ_EXPIRED', {
      additionalMsg: payload.quizVersionShareShareCode,
      eventData: payload
    });

    return 'quiz locked';
  }

  return {
    id: parsedData.id,
    name: parsedData.name,
    randomiseQuestionParameters: parsedData.randomiseQuestionParameters,
    learningGroupShareCode: payload.learningGroupShareCode,
    quizVersionShareShareCode: payload.quizVersionShareShareCode,
    questions: parsedData.questions.map((question: QuestionApiEntity) => ({
      id: question['@id'],
      uid: question.questionType.uid,
      displayOrder: question.displayOrder,
      parameters: JSON.stringify(question.parameters)
    })),
    quizSounds: parsedData.quizSounds
  };
};

/** Submit some question results to a Quiz Session. */
export const updateQuizResults = async (
  quizSessionId: string,
  payload: { questionResults: QuestionResultsInfo[] }
): Promise<void | 'network error' | 'http error' | 'unknown error'> => {
  const url = `/web/infinity/quiz-sessions/${quizSessionId}`;
  const result = await putRequestQuery(url, payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', 'updateQuizResults', 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        Logger.captureEvent('error', 'updateQuizResults', 'HTTP_ERROR', { eventData: result });

        return 'http error';
      case 'unknown':
        Logger.captureEvent('error', 'updateQuizResults', 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }

  // Success
  return;
};
