import Text from 'common/src/components/typography/Text';
import { View, StyleSheet, ImageBackground, FlatList } from 'react-native';
import { useEffect, useState } from 'react';
import { RootStackProps } from '../navigation/types';
import { useI18nContext } from '../i18n/i18n-react';
import { StudentQuestion } from '../utils/studentData';
import { colors } from 'common/src/theme/colors';
import Spinner from 'common/src/components/molecules/Spinner';
import HomeMenu from '../components/HomeMenu';
import QuizList from '../components/QuizList';
import { getStudentQuizzes } from '../network/studentQuizzes';
import BaseScreen from 'common/src/components/screens/BaseScreen';
import useLoginStore from '../storage/useLoginStore';
import FilledButton from '../components/FilledButton';
import { Image } from 'expo-image';
import { checkPermissions } from '../utils/permissions';
import useBreakpoints from '../hooks/useBreakpoints';

export default function PupilHomeScreen({ navigation }: RootStackProps<'PupilHome'>) {
  const [studentQuizzes, setStudentQuizzes] = useState<StudentQuestion[]>([]);
  const [studentQuizzesTotalLength, setStudentQuizzesTotalLength] = useState(0);
  const [quizzesPageNum, setQuizzesPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const translate = useI18nContext().LL;

  const { loggedInUser } = useLoginStore();
  const { resize } = useBreakpoints();

  // Get Student Quizzes
  // GET API request to retrieve student quizzes
  const getQuizzes = async (pageNum: number) => {
    setLoading(true);

    // API request - Get student quizzes
    const result = await getStudentQuizzes(pageNum);

    // Handle errors
    if (result === 'http error' || result === 'network error' || result === 'unknown error') {
      setLoading(false);
      return setError(result);
    }

    // Once data has been retrieved, disable loading state
    setLoading(false);

    // Update student quizzes state with new quizzes
    setStudentQuizzes([...studentQuizzes, ...result.quiz]);

    // Set total amount of quizzes, to be used to calculate when to stop loading more data when scrolling
    setStudentQuizzesTotalLength(result.quizLength);
  };

  // Get more student quizzes
  const getMoreStudentQuizzes = () => {
    if (
      !loading &&
      studentQuizzes.length % 32 === 0 &&
      studentQuizzes.length < studentQuizzesTotalLength
    ) {
      setQuizzesPageNum(quizzesPageNum + 1);
    }
  };

  // Permissions
  const handlePermissions = () => {
    (async () => {
      await checkPermissions(navigation);
    })();
  };

  const { school } = useLoginStore();

  useEffect(() => {
    getQuizzes(quizzesPageNum);
  }, [quizzesPageNum]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <BaseScreen>
          {/* Menu header */}
          <HomeMenu
            menuActive={() => navigation.navigate('PupilMenu')}
            stopLoading={() => setLoading(false)}
          />

          {/* Intro */}
          <Text
            style={[styles.intro, studentQuizzesTotalLength === 0 && styles.introNoQuizzes]}
            testID="PUPIL_HOME_INTRO_TEXT"
          >
            {studentQuizzesTotalLength === 0
              ? translate.dashboard.hiNameYouHaveNoQuizzesAssigned(loggedInUser?.profile.firstName)
              : // TODO - Update with API data of quizzes to be completed
                // Hi {loggedInUser?.profile?.firstName}, you have 8 quizzes not yet completed.
                ''}
          </Text>

          {/* Errors */}
          <>
            {error && (
              <View testID="STUDENT_QUIZZES_ERROR_MESSAGE">
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 32
                  }}
                >
                  {translate.dashboard.studentQuizzesError()}
                </Text>
              </View>
            )}
          </>

          {/* Student Quizzes */}
          {studentQuizzesTotalLength > 0 ? (
            <FlatList
              numColumns={4}
              data={studentQuizzes}
              contentContainerStyle={{
                rowGap: 48,
                alignItems: 'center'
              }}
              showsVerticalScrollIndicator={false}
              renderItem={QuizList}
              keyExtractor={(_item, index) => 'key' + index}
              onEndReachedThreshold={0.01}
              onEndReached={() => getMoreStudentQuizzes()}
            />
          ) : (
            <View style={styles.menuActionsContainer}>
              <FilledButton
                icon={() => (
                  <Image
                    source={require('pupil-app/assets/svg/QRCodeIcon.svg')}
                    style={{ width: 48 * resize, height: 48 * resize }}
                  />
                )}
                buttonWidth={377}
                onPress={handlePermissions}
                testID="PUPIL_HOME_NO_QUIZ_SCAN_BUTTON"
              >
                {translate.home.scanQrCode()}
              </FilledButton>
              <FilledButton
                icon={() => (
                  <Image
                    source={require('pupil-app/assets/svg/Plus.svg')}
                    style={{ width: 48 * resize, height: 48 * resize }}
                  />
                )}
                buttonWidth={377}
                onPress={
                  school !== undefined
                    ? () => {
                        // Need to push to the navigation stack to ensure the stack has an entry to return to if the user clicks 'Back'
                        // on the Enter Quiz Pin Screen. Otherwise, refreshing on Pupil Home and then navigating to Enter Quiz Pin Screen, then trying to go back
                        // will not work.
                        navigation.push('PupilHome');
                        navigation.replace('EnterQuizPIN');
                      }
                    : () => {
                        // Navigation push needed here too for the same reason as mentioned above.
                        navigation.push('PupilHome');
                        navigation.replace('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' });
                      }
                }
                testID="PUPIL_HOME_NO_QUIZ_ENTER_PIN_BUTTON"
              >
                {translate.home.enterCode()}
              </FilledButton>
            </View>
          )}

          {/* Loading spinner */}
          {loading && (
            <View
              key="loading"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View>
                <Spinner height={156} />
                <Text
                  variant="WRN400"
                  style={{ color: colors.white, fontSize: 32, lineHeight: 48 }}
                >
                  {translate.loadingEllipsis()}
                </Text>
              </View>
            </View>
          )}
        </BaseScreen>
      </ImageBackground>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    fontSize: 32
  },
  menuContainer: {
    backgroundColor: '#00254f',
    flex: 1,
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%'
  },
  menuActionsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    rowGap: 32
  },
  intro: {
    color: colors.white,
    fontWeight: '700',
    paddingBottom: 32,
    fontSize: 32
  },
  introNoQuizzes: {
    alignSelf: 'flex-start',
    paddingLeft: 24,
    paddingBottom: 0
  }
});
