import { StyleSheet, View } from 'react-native';
import { colors } from 'common/src/theme/colors';

type ProgressBarProps = {
  // Progress given as a percentage
  progress: number;
};

/**
 * Progress bar to display given progress percentage
 */
export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <View style={styles.wrapper}>
      <View style={styles.totalBar} />
      <View style={[styles.progressBar, { width: `${progress}%` }]} />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    width: '100%'
  },
  totalBar: {
    backgroundColor: colors.greys200,
    width: '100%',
    height: 13,
    borderRadius: 21
  },
  progressBar: {
    position: 'absolute',
    backgroundColor: colors.fieryRose,
    height: 13,
    borderRadius: 21,
    zIndex: 2
  }
});
