import { View } from 'react-native';
import { SvgName } from '../../assets/svg';
import { AssetSvg } from 'common/src/assets/svg';
import { ScaleObjectName, scaleObjects } from '../../utils/objects';
import { getMinTriangularNumber } from '../../utils/math';
import { countRange } from '../../utils/collections';

export type PyramidObject = {
  icon: ScaleObjectName;
  amount: number;
  iconWidth: number;
  pyramidWidth: number;
  alignLeft?: boolean;
};

/**
 * @param maxLength - The maximum amount of icons the pyramid will span wide from the bottom.
 */
const PyramidOfObjects: React.FC<PyramidObject> = ({
  icon,
  amount,
  iconWidth,
  pyramidWidth,
  alignLeft = false
}) => {
  // Generate the pyramid structure
  const pyramid: (SvgName | '')[][] = [];
  let currentAmount = amount;

  const minTriangularNumber = getMinTriangularNumber(amount);
  const formation = countRange(minTriangularNumber, 1).reverse();
  const { topOffset, name, leftOffset, width } = scaleObjects[icon];

  const scale = iconWidth / width;
  const scaledTopOffset = topOffset * scale;
  const scaledLeftOffset = leftOffset ? leftOffset * scale : 0;

  // Iterate through levels
  formation.forEach(length => {
    if (currentAmount > 0) {
      let level;
      if (currentAmount >= length) {
        level = Array(length).fill(name);
      } else {
        level = Array(currentAmount).fill(name);
        let remainder = length - currentAmount;
        while (remainder > 0) {
          level.push('');
          remainder = -1;
        }
      }
      pyramid.unshift(level); // Add each level to the beginning of the pyramid array
      currentAmount -= length;
    }
  });

  // Render the pyramid
  return (
    <View
      style={{
        width: pyramidWidth,
        justifyContent: 'flex-end',
        left: -scaledLeftOffset
      }}
    >
      <View
        style={{
          alignItems: 'center',
          alignSelf: alignLeft ? 'flex-start' : 'flex-end'
        }}
      >
        {pyramid.map((level, index) => (
          <View
            key={index}
            style={{
              flexDirection: 'row',
              columnGap: 1,
              top: scaledTopOffset * (pyramid.length - index - 1),
              left:
                index === pyramid.length - 1
                  ? 0
                  : (scaledLeftOffset * (pyramid.length - 1 - index)) / 2
            }}
          >
            {level.map((icon, idx) => (
              <View
                key={idx}
                style={{
                  width: iconWidth,
                  left: scaledLeftOffset * (level.length - idx - 1)
                }}
              >
                {icon === '' ? null : <AssetSvg name={icon} width={iconWidth} />}
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default PyramidOfObjects;
