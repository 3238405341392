import { StatusBar } from 'expo-status-bar';
import { RootStackProps } from '../navigation/types';
import { showErrorToast, showInfoToast } from '../components/Toast';
import { useCallback, useMemo, useState } from 'react';
import { ImageBackground } from 'react-native';
import QuizAndResultsScreen from 'common/src/components/screens/QuizAndResultsScreen';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import useQuestionQueueStore from '../storage/useQuestionQueueStore';
import { updateQuizResults } from '../network/quizSession';
import { QuestionToken } from 'common/src/SchemeOfLearning';
import { useI18nContext } from '../i18n/i18n-react';
import useBreakpoints from '../hooks/useBreakpoints';
import DebugModeContext from 'common/src/contexts/DebugModeContext';
import ENV from '../ENV';

/** Combined Quiz and Results screen. */
export default function QuizScreen({ navigation }: RootStackProps<'Quiz'>) {
  const translate = useI18nContext().LL;

  const [quizKey, setQuizKey] = useState(0);
  const [error, setError] = useState('');

  const quizSession = useQuizSessionStore(state => state.quizSession);
  const addQuestionQueue = useQuestionQueueStore(state => state.addQuestionQueue);
  const clearQuizSessionQuestions = useQuestionQueueStore(state => state.clearQuizSessionQuestions);
  const queueBySessionId = useQuestionQueueStore(state => state.getQueueBySessionId);
  const setQuizReadyToSend = useQuestionQueueStore(state => state.setQuizReadyToSend);

  const { resize } = useBreakpoints();

  // Just throw if the quizSession isn't defined. This should be impossible since we only navigate to this screen
  // immediately after setting the quizSession.
  if (quizSession === undefined) {
    throw new Error('Quiz Session is undefined, this should not be possible');
  }

  const tokens = useMemo(
    () =>
      quizSession.questions.map(
        (question): QuestionToken =>
          quizSession.randomiseQuestionParameters || question.parameters === undefined
            ? question.uid
            : [question.uid, JSON.parse(question.parameters)]
      ),
    [quizSession.questions, quizSession.randomiseQuestionParameters]
  );

  if (error !== '') {
    showErrorToast({
      title: error,
      message: translate.errorModals.cannotLoadQuizQuestions(),
      extraDuration: 1000,
      resize: resize
    });
    setError('');
    navigation.navigate('Home');
  }

  const onTokensInvalid = useCallback(
    (invalidTokens: QuestionToken[]) => {
      if (invalidTokens.length === tokens.length) {
        // All the questions were invalid! Show a toast and navigate back to home.
        showErrorToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.cannotLoadQuizQuestions(),
          extraDuration: 1000,
          resize: resize
        });

        navigation.navigate('Home');
      } else {
        // Some, but not all of the questions were invalid. The QuizAndResultsScreen is just using the valid ones, so
        // we don't need to do anything other than show a toast.
        showInfoToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.xQuestionsCouldNotBeLoaded(invalidTokens.length),
          extraDuration: 1000,
          delay: 300,
          resize: resize
        });
      }
    },
    [navigation, resize, tokens.length, translate.errorModals]
  );

  const onAnswer = useCallback(
    (
      questionIndex: number,
      answer: string,
      isCorrect: boolean,
      timeTaken: number,
      attemptNumber: number,
      parameters: Record<string, unknown>
    ) => {
      if (quizSession) {
        // Skip over dev QR Codes
        if (!quizSession.isLegacyQuiz) {
          const questionId = quizSession.questions[questionIndex].id;
          const questionResult = {
            question: questionId,
            answer,
            isCorrect,
            timeTaken,
            attemptNumber,
            parameters
          };
          addQuestionQueue(quizSession.id, questionResult);
          // TODO: for plus customers we will also want to call sendResults herex
        }
      }
    },
    [addQuestionQueue, quizSession]
  );

  const onQuizEnd = useCallback(async () => {
    setQuizReadyToSend(quizSession.id);
    const sessionQueue = queueBySessionId(quizSession.id);
    const unsentResults = sessionQueue.map(val => val.questionResult);
    if (unsentResults.length > 0) {
      const payload = { questionResults: unsentResults };
      const result = await updateQuizResults(quizSession.id, payload);
      if (typeof result !== 'string') {
        // Success
        clearQuizSessionQuestions(quizSession.id);
      } else {
        // Failure
        // TODO: check with UI/UX if we want a toast here? Probably just for plus.
      }
    }
  }, [clearQuizSessionQuestions, queueBySessionId, quizSession.id, setQuizReadyToSend]);

  return (
    <>
      <StatusBar translucent style="light" />
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{
          flex: 1
        }}
      >
        <DebugModeContext.Provider value={ENV.IS_E2E}>
          <QuizAndResultsScreen
            key={quizKey}
            quizName={quizSession.name}
            tokens={tokens}
            loadingTextColor="white"
            onTokensInvalid={onTokensInvalid}
            onExitQuiz={navigation.goBack}
            onRetryQuiz={() => {
              setQuizKey(old => old + 1);
              navigation.navigate('QuizLoading');
            }}
            onReturnToHome={navigation.goBack}
            onAnswer={onAnswer}
            onQuizEnd={onQuizEnd}
          />
        </DebugModeContext.Provider>
      </ImageBackground>
    </>
  );
}
