import {
  CheapObjectName,
  ExpensiveObjectName,
  ObjectName,
  lessThanOnePoundObjectName
} from './objects';
import { throwError } from './flowControl';
import { AssetSvg, type SvgName } from '../assets/svg';

/** Object contain all the names of SVGs related to objects. */
const objectImages: Record<ObjectName, SvgName[]> = {
  Apple: [
    'Equal_groups/Apples0',
    'Equal_groups/Apples1',
    'Equal_groups/Apples2',
    'Equal_groups/Apples3',
    'Equal_groups/Apples4',
    'Equal_groups/Apples5',
    'Equal_groups/Apples6'
  ],
  Cookie: [
    'Equal_groups/Cookies0',
    'Equal_groups/Cookies1',
    'Equal_groups/Cookies2',
    'Equal_groups/Cookies3',
    'Equal_groups/Cookies4',
    'Equal_groups/Cookies5',
    'Equal_groups/Cookies6'
  ],
  Egg: [
    'Equal_groups/Eggs0',
    'Equal_groups/Eggs1',
    'Equal_groups/Eggs2',
    'Equal_groups/Eggs3',
    'Equal_groups/Eggs4',
    'Equal_groups/Eggs5',
    'Equal_groups/Eggs6'
  ],
  Flower: [
    'Equal_groups/Flowers0',
    'Equal_groups/Flowers1',
    'Equal_groups/Flowers2',
    'Equal_groups/Flowers3',
    'Equal_groups/Flowers4',
    'Equal_groups/Flowers5',
    'Equal_groups/Flowers6'
  ],
  Pencil: [
    'Equal_groups/Pencils0',
    'Equal_groups/Pencils1',
    'Equal_groups/Pencils2',
    'Equal_groups/Pencils3',
    'Equal_groups/Pencils4',
    'Equal_groups/Pencils5',
    'Equal_groups/Pencils6'
  ]
};

const lollyStickImages: Record<'square' | 'pentagon', SvgName[]> = {
  square: [
    'Lolly_sticks/SingleLollyStickSquare',
    'Lolly_sticks/TwoLollyStickSquare',
    'Lolly_sticks/ThreeLollyStickSquare',
    'Lolly_sticks/FourLollyStickSquare'
  ],
  pentagon: [
    'Lolly_sticks/SingleLollyStickPentagon',
    'Lolly_sticks/TwoLollyStickPentagon',
    'Lolly_sticks/ThreeLollyStickPentagon',
    'Lolly_sticks/FourLollyStickPentagon',
    'Lolly_sticks/FiveLollyStickPentagon'
  ]
};

const lessThanOnePoundObjects: Record<lessThanOnePoundObjectName, SvgName> = {
  Apple: 'Array_objects/AppleGreen',
  Banana: 'Array_objects/Banana',
  Pear: 'Array_objects/Pear'
};

const cheapObjects: Record<CheapObjectName, SvgName> = {
  Book: 'BookRed',
  Balloon: 'BalloonBlue',
  BreadLoaf: 'Bread_loaf',
  CrayonBox: 'CrayonBox',
  FizzyDrink: 'Can_of_fizzy_drink',
  JuiceBottle: 'Juice',
  LemonadeBottle: 'Lemonade_bottle',
  Mug: 'Mug',
  PencilCase: 'Pencil_case',
  Kettle: 'Kettle'
};

const expensiveObjects: Record<ExpensiveObjectName, SvgName> = {
  Bike: 'Bike',
  'Games console': 'GamesConsole',
  Laptop: 'Laptop',
  Phone: 'Phone',
  Rucksack: 'Rucksack',
  Scooter: 'Scooter',
  Television: 'Television'
};

/**
 * Get object SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getObjectImage} instead.
 */
export const getObjectSvgName = (object: ObjectName, amount: number): SvgName => {
  return objectImages[object][amount] ?? throwError(`No image for ${amount} of ${object}`);
};

/**
 * Get lolly sticks SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getObjectImage} instead.
 */
export const getLollySticksSvgName = (shape: 'square' | 'pentagon', amount: number): SvgName => {
  return (
    lollyStickImages[shape][amount - 1] ?? throwError(`No image for ${amount} sticks in a ${shape}`)
  );
};

/**
 * Get object image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getObjectSvgName} directly, for better customization.
 */
export const getObjectImage = (
  object: ObjectName,
  amount: number,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getObjectSvgName(object, amount)} height={height} width={width} />;
};

/**
 * Get lessThanOnePoundObject SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getLessThanOnePoundObjectImage} instead.
 */
export const getLessThanOnePoundObjectSvgName = (object: lessThanOnePoundObjectName): SvgName => {
  return lessThanOnePoundObjects[object];
};

/**
 * Get lessThanOnePoundObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getLessThanOnePoundObjectSvgName} directly, for better customization.
 */
export const getLessThanOnePoundObjectImage = (
  object: lessThanOnePoundObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getLessThanOnePoundObjectSvgName(object)} height={height} width={width} />;
};

/**
 * Get cheapObject SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getCheapObjectImage} instead.
 */
export const getCheapObjectSvgName = (object: CheapObjectName): SvgName => {
  return cheapObjects[object];
};

/**
 * Get cheapObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getCheapObjectSvgName} directly, for better customization.
 */
export const getCheapObjectImage = (
  object: CheapObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getCheapObjectSvgName(object)} height={height} width={width} />;
};

/**
 * Get expensiveObject SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getExpensiveObjectImage} instead.
 */
export const getExpensiveObjectSvgName = (object: ExpensiveObjectName): SvgName => {
  return expensiveObjects[object];
};

/**
 * Get expensiveObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getExpensiveObjectSvgName} directly, for better customization.
 */
export const getExpensiveObjectImage = (
  object: ExpensiveObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getExpensiveObjectSvgName(object)} height={height} width={width} />;
};
