import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

// Fruits
export const fruitNames = [
  'Apple',
  'Orange',
  'Pear',
  'Plum',
  'Banana',
  'Lemon',
  'Lime',
  'Mango'
] as const;

export const fruitNamesLC = [
  'apple',
  'orange',
  'pear',
  'plum',
  'banana',
  'lemon',
  'lime',
  'mango'
] as const;

export const fruitsSchema = z.enum(fruitNames);

export type fruitName = (typeof fruitNames)[number];
export type FruitNameLC = (typeof fruitNamesLC)[number];

export const fruitAsWord = (
  object: fruitName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Apple':
      return isPlural ? translate.fruits.Apples() : translate.fruits.Apple();
    case 'Orange':
      return isPlural ? translate.fruits.Oranges() : translate.fruits.Orange();
    case 'Pear':
      return isPlural ? translate.fruits.Pears() : translate.fruits.Pear();
    case 'Plum':
      return isPlural ? translate.fruits.Plums() : translate.fruits.Plum();
    case 'Banana':
      return isPlural ? translate.fruits.Bananas() : translate.fruits.Banana();
    case 'Lemon':
      return isPlural ? translate.fruits.Lemons() : translate.fruits.Lemon();
    case 'Lime':
      return isPlural ? translate.fruits.Limes() : translate.fruits.Lime();
    case 'Mango':
      return isPlural ? translate.fruits.Mangos() : translate.fruits.Mango();
  }
};

export function getRandomFruit() {
  return getRandomFromArray(fruitNames);
}
export function getRandomUniqueFruits(numberOfFruits: number) {
  return getRandomSubArrayFromArray([...fruitNames] as const, numberOfFruits);
}

// Fruits in sentence
export const fruitNamesInSentence = [
  'An apple',
  'An orange',
  'A pear',
  'A plum',
  'A banana',
  'A lemon',
  'A lime',
  'A mango'
] as const;

export const fruitsSentenceSchema = z.enum(fruitNamesInSentence);

export type fruitSentenceName = (typeof fruitNamesInSentence)[number];

export const fruitAsSentence = (object: fruitSentenceName, translate: TranslationFunctions) => {
  switch (object) {
    case 'An apple':
      return translate.fruits.anApple();
    case 'An orange':
      return translate.fruits.anOrange();
    case 'A pear':
      return translate.fruits.aPear();
    case 'A plum':
      return translate.fruits.aPlum();
    case 'A banana':
      return translate.fruits.aBanana();
    case 'A lemon':
      return translate.fruits.aLemon();
    case 'A lime':
      return translate.fruits.aLime();
    case 'A mango':
      return translate.fruits.aMango();
  }
};

export function getRandomFruitSentence() {
  return getRandomFromArray(fruitNamesInSentence);
}
export function getRandomUniqueFruitsSentence(numberOfFruits: number) {
  return getRandomSubArrayFromArray([...fruitNamesInSentence] as const, numberOfFruits);
}
