import { useContext, useMemo } from 'react';
import Svg, { Line, Path } from 'react-native-svg';
import { StateTreeLeaf } from '../../../stateTree';
import { isEqual } from '../../../utils/matchers';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import Grid, { GridSvgChildren } from '../representations/Coordinates/Grid';
import SelectableSquareDots from '../representations/Coordinates/SelectableSquareDots';
import SquareDottedPaper from '../representations/Coordinates/SquareDottedPaper';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import Button from '../../atoms/Button';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { type SetState } from 'common/src/utils/react';
import { MeasureView } from '../../atoms/MeasureView';
import { StyleSheet, View } from 'react-native';
import { AssetSvg } from '../../../assets/svg';
import { countRange } from '../../../utils/collections';
import Text from '../../typography/Text';

const CELL_PIXELS = 80;
const PDF_DOT_RADIUS = 5;

type AnswerState = {
  x: number;
  y: number;
}[];

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /**
   * Array of pre-drawn fixed points.
   */
  fixedPoints?: { x: number; y: number }[];
  /**
   * Pre-drawn shape.
   */
  gridChildrenPoints?: { x: number; y: number }[];
  closeShape?: boolean;
  hideLines?: boolean;
  /**
   * Number of points to be selected
   */
  numPoints?: number;
  testCorrect: { x: number; y: number }[] | ((points: { x: number; y: number }[]) => boolean);

  gridVariant?: 'dotted' | 'grid';
  /**
   * When present it shows red dashed line on the required axis with gridVariant grid  */
  symmetryLine?: 'X' | 'Y' | 'Y=X';
  /**
   * When true, selected points will join to the first and last gridChildren point
   * Default: false
   */
  joinToGridChild?: boolean;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: { x: number; y: number }[]; answerText?: string };
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  /**
   * How wide the square dotted version of the grid should be (in number of square, not number of dots).
   * This prop is only used with the 'dotted' variant'. Widths wider than 10 will not fit correctly and should be avoided.
   */
  squareDottedWidth?: number;
  /**
   * How tall the square dotted version of the grid should be (in number of square, not number of dots).
   * This prop is only used with the 'dotted' variant'. Heights taller than 5 will not fit correctly and should be avoided.
   */
  squareDottedHeight?: number;
  /**
   * JSX element to display to the left of the grid. NOTE: Currently only configured to work with the square dotted paper representation.
   */
  elementToLeft?: JSX.Element;
};

export default function QF45aDrawShapeOnSquareDottedPaper({
  title,
  pdfTitle,
  fixedPoints,
  gridChildrenPoints,
  closeShape = false,
  hideLines = false,
  numPoints,
  gridVariant = 'dotted',
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  symmetryLine,
  joinToGridChild = false,
  cellSizeLabel,
  squareDottedWidth = 10,
  squareDottedHeight = 5,
  elementToLeft
}: Props) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';
  const styles = useStyles();

  // This is the largest grid that fits within mainPanel area
  const xMax = symmetryLine ? (symmetryLine === 'X' ? 8 : 5) : 10;
  const yMax = symmetryLine ? (symmetryLine === 'X' ? 4 : 5) : 5;
  const xMin = symmetryLine === 'Y' ? -5 : 0;
  const yMin = 0;

  // Need to calculate where the selectable dots should be if we are reflecting diagonally
  const dotsArray: (null | undefined)[][] = countRange(xMax + 1).map(i =>
    countRange(yMax + 1).map(j => (j <= i ? null : undefined))
  );

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  function renderShape(
    shape: { x: number; y: number }[],
    mathToSvgX: (x: number) => number,
    mathToSvgY: (y: number) => number,
    closeShape: boolean,
    isInteractive: boolean
  ) {
    {
      const lineColour = isInteractive ? colors.burntSienna : colors.prussianBlue;
      return shape?.map((point, i) => {
        if (i < shape.length - 1) {
          return renderLine(
            point,
            shape[i + 1],
            mathToSvgX,
            mathToSvgY,
            !isInteractive,
            lineColour
          );
        } else if (closeShape) {
          return renderLine(point, shape[0], mathToSvgX, mathToSvgY, !isInteractive, lineColour);
        }
      });
    }
  }

  function renderLine(
    { x: x1, y: y1 }: { x: number; y: number },
    { x: x2, y: y2 }: { x: number; y: number },
    mathToSvgX: (x: number) => number,
    mathToSvgY: (y: number) => number,
    fixed: boolean,
    strokeColor: string,
    strokeDasharray?: number[],
    pdfStrokeColor?: string,
    strokeWidth = 4
  ) {
    return (
      <Line
        key={`(${x1},${y1})->(${x2},${y2})`}
        pointerEvents="none"
        x1={mathToSvgX(x1)}
        y1={mathToSvgY(y1)}
        x2={mathToSvgX(x2)}
        y2={mathToSvgY(y2)}
        stroke={isPdf ? pdfStrokeColor ?? 'black' : strokeColor}
        strokeWidth={isPdf ? strokeWidth * 2 : strokeWidth}
        strokeLinecap="round"
        strokeDasharray={isPdf && !fixed ? '6 10' : strokeDasharray}
      />
    );
  }

  function renderSymmetryLine(
    mathToSvgX: (x: number) => number,
    mathToSvgY: (y: number) => number,
    symmetry: 'X' | 'Y' | 'Y=X'
  ) {
    const point1 =
      symmetry === 'Y'
        ? { x: 0, y: yMin }
        : symmetry === 'X'
        ? { x: xMin, y: yMax / 2 }
        : { x: 0, y: 0 };

    const point2 =
      symmetry === 'Y'
        ? { x: 0, y: yMax }
        : symmetry === 'X'
        ? { x: xMax, y: yMax / 2 }
        : { x: xMax, y: yMax };

    return renderLine(
      point1,
      point2,
      mathToSvgX,
      mathToSvgY,
      false,
      colors.red,
      [15, 15],
      colors.red,
      isPdf ? 6 : undefined
    );
  }

  function pdfContent(mathToSvgX: (x: number) => number, mathToSvgY: (x: number) => number) {
    const state =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay || []
        : testCorrectProp;

    let linesToDraw: AnswerState = state;
    if (joinToGridChild && gridChildrenPoints) {
      if (numPoints === undefined || (numPoints && state.length < numPoints)) {
        linesToDraw = [gridChildrenPoints[0], ...state];
      } else if (state.length === numPoints) {
        linesToDraw = [
          gridChildrenPoints[0],
          ...state,
          gridChildrenPoints[gridChildrenPoints.length - 1]
        ];
      }
    }
    return (
      <>
        <GridSvgChildren>
          {symmetryLine && renderSymmetryLine(mathToSvgX, mathToSvgY, symmetryLine)}
          {gridChildrenPoints &&
            renderShape(gridChildrenPoints, mathToSvgX, mathToSvgY, closeShape, false)}
          {displayMode === 'markscheme' &&
            renderShape(linesToDraw, mathToSvgX, mathToSvgY, closeShape, true)}
        </GridSvgChildren>
      </>
    );
  }

  function digitalContent(
    mathToSvgX: (x: number) => number,
    mathToSvgY: (x: number) => number,
    state: AnswerState,
    setState: SetState<AnswerState>,
    gridVariant: 'dotted' | 'grid'
  ) {
    // when we have a line of symmetry we want to join up to the grid children from the start and for the end.
    // else lets just draw the tapped squares i.e. the state.
    let linesToDraw: AnswerState = state;
    if (joinToGridChild && gridChildrenPoints) {
      if (numPoints === undefined || (numPoints && state.length < numPoints)) {
        linesToDraw = [gridChildrenPoints[0], ...state];
      } else if (state.length === numPoints) {
        linesToDraw = [
          gridChildrenPoints[0],
          ...state,
          gridChildrenPoints[gridChildrenPoints.length - 1]
        ];
      }
    }

    return (
      <>
        {gridChildrenPoints && (
          <GridSvgChildren>
            {renderShape(gridChildrenPoints, mathToSvgX, mathToSvgY, closeShape, false)}
          </GridSvgChildren>
        )}
        {symmetryLine && (
          <GridSvgChildren>
            {renderSymmetryLine(mathToSvgX, mathToSvgY, symmetryLine)}
          </GridSvgChildren>
        )}
        <>
          {/* when we use grid we want the lines under the dots and vice versa when its dotted */}
          <View style={{ zIndex: gridVariant === 'grid' ? 2 : -1 }}>
            <SelectableSquareDots
              state={state}
              setState={
                numPoints === undefined || (numPoints && state.length < numPoints)
                  ? setState
                  : undefined
              }
              fixedSelected={fixedPoints}
              multiSelect
              noDeselect
              unselectedFill={gridVariant === 'grid' ? 'none' : undefined}
              yMin={symmetryLine === 'X' ? 2 : undefined}
              dotsArray={symmetryLine === 'Y=X' ? dotsArray : undefined}
            />
          </View>
          {!hideLines && (
            <GridSvgChildren>
              {linesToDraw.length > 1 &&
                renderShape(linesToDraw, mathToSvgX, mathToSvgY, closeShape, true)}
            </GridSvgChildren>
          )}
        </>
      </>
    );
  }

  if (displayMode === 'markscheme' || displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={{ alignItems: 'center', justifyContent: 'space-between' }}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            {gridVariant === 'dotted' ? (
              <View style={{ flexDirection: 'row', width: '100%' }}>
                {elementToLeft}
                <View style={{ alignSelf: 'center', width: `${squareDottedWidth * 10}%` }}>
                  <SquareDottedPaper
                    // This is the largest grid that fits within mainPanel area
                    mathDimens={{ xLength: squareDottedWidth, yLength: squareDottedHeight }}
                    cellSize={CELL_PIXELS}
                    dotColor={colors.greys700}
                    dotRadius={PDF_DOT_RADIUS}
                    cellSizeLabel={cellSizeLabel}
                  >
                    {({ mathToSvgX, mathToSvgY }) => pdfContent(mathToSvgX, mathToSvgY)}
                  </SquareDottedPaper>
                </View>
              </View>
            ) : (
              <MeasureView>
                {dimens => {
                  // Firstly, calculate various layout dimensions.
                  const maxWidth = dimens.width * 0.9;
                  const maxHeight = dimens.height * 0.9;
                  const squareDimens = Math.min(maxHeight / 5, maxWidth / 10);
                  return (
                    <View>
                      {cellSizeLabel && (
                        <View
                          style={{
                            alignSelf: 'flex-end',
                            alignItems: 'flex-end',
                            right: displayMode === 'pdf' ? squareDimens * 1.1 : squareDimens * 1.4
                          }}
                        >
                          <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
                          <Svg width={squareDimens} height={20}>
                            <Path
                              d={'M0,10 L10,5 L10,15 Z'}
                              fill={isPdf ? colors.black : colors.prussianBlue}
                            />
                            <Path
                              d={`M5,10 L${squareDimens - 5},10`}
                              stroke={isPdf ? colors.black : colors.prussianBlue}
                              strokeWidth={isPdf ? 4 : 2}
                            />
                            <Path
                              d={`M${squareDimens},10
                                  L${squareDimens - 10},5
                                  L${squareDimens - 10},15
                                  Z`}
                              fill={isPdf ? colors.black : colors.prussianBlue}
                            />
                          </Svg>
                        </View>
                      )}
                      <View style={{ flexDirection: 'row' }}>
                        <Grid
                          width={dimens.width * 0.9}
                          height={dimens.height * 0.9}
                          xMax={xMax}
                          xMin={xMin}
                          yMax={yMax}
                          yMin={yMin}
                          squareGrid
                          hideContinuationLines
                          xAxis={null}
                          yAxis={null}
                        >
                          {({ mathToSvgX, mathToSvgY }) => pdfContent(mathToSvgX, mathToSvgY)}
                        </Grid>
                        {cellSizeLabel && (
                          <View
                            style={{
                              flexDirection: 'row',
                              alignSelf: 'flex-start'
                            }}
                          >
                            <Svg width={20} height={squareDimens}>
                              <Path
                                d={'M10,0 L5,10 L15,10 Z'}
                                fill={isPdf ? colors.black : colors.prussianBlue}
                              />
                              <Path
                                d={`M10,5 L10,${squareDimens - 5}`}
                                stroke={isPdf ? colors.black : colors.prussianBlue}
                                strokeWidth={isPdf ? 4 : 2}
                              />
                              <Path
                                d={`M10,${squareDimens}
                                  L5,${squareDimens - 10}
                                  L15,${squareDimens - 10}
                                  Z`}
                                fill={isPdf ? colors.black : colors.prussianBlue}
                              />
                            </Svg>
                            <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
                          </View>
                        )}
                      </View>
                    </View>
                  );
                }}
              </MeasureView>
            )}
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
      />
    );
  }

  return (
    <StateTreeLeaf<{ x: number; y: number }[]>
      id="selectabledots"
      defaultState={[]}
      testComplete={state => state.length !== 0}
      testCorrect={testCorrect}
    >
      {({ state, setState }) => (
        <BaseLayout
          title={title}
          actionPanelContents={
            <Button
              variant="circle"
              style={{ padding: 16 }}
              onPress={() => setState(old => old.slice(0, old.length - 1))}
            >
              <AssetSvg name="zest/rotate-left" />
            </Button>
          }
          mainPanelContents={
            <>
              {gridVariant === 'dotted' ? (
                <View
                  style={{
                    flexDirection: 'row'
                  }}
                >
                  {elementToLeft}
                  <View style={{ alignSelf: 'center', width: `${squareDottedWidth * 10}%` }}>
                    <SquareDottedPaper
                      // This is the largest grid that fits within mainPanel area
                      mathDimens={{ xLength: squareDottedWidth, yLength: squareDottedHeight }}
                      cellSize={CELL_PIXELS}
                      dotColor="transparent"
                      style={{ alignSelf: 'center' }}
                      cellSizeLabel={cellSizeLabel}
                    >
                      {({ mathToSvgX, mathToSvgY }) =>
                        digitalContent(mathToSvgX, mathToSvgY, state, setState, 'dotted')
                      }
                    </SquareDottedPaper>
                  </View>
                </View>
              ) : (
                <MeasureView>
                  {dimens => {
                    // Firstly, calculate various layout dimensions.
                    const maxWidth = dimens.width * 0.9;
                    const maxHeight = dimens.height * 0.9;
                    const squareDimens = Math.min(maxHeight / 5, maxWidth / 10);

                    return (
                      <View style={{ alignSelf: 'center' }}>
                        {cellSizeLabel && (
                          <View
                            style={{
                              alignSelf: 'flex-end',
                              alignItems: 'flex-end',
                              right: (11 * squareDimens) / 16
                            }}
                          >
                            <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
                            <Svg width={squareDimens} height={20}>
                              <Path
                                d={'M0,10 L10,5 L10,15 Z'}
                                fill={isPdf ? colors.black : colors.prussianBlue}
                              />
                              <Path
                                d={`M5,10 L${squareDimens - 5},10`}
                                stroke={isPdf ? colors.black : colors.prussianBlue}
                                strokeWidth={isPdf ? 4 : 2}
                              />
                              <Path
                                d={`M${squareDimens},10
                                    L${squareDimens - 10},5
                                    L${squareDimens - 10},15
                                    Z`}
                                fill={isPdf ? colors.black : colors.prussianBlue}
                              />
                            </Svg>
                          </View>
                        )}
                        <View style={{ flexDirection: 'row' }}>
                          <Grid
                            width={dimens.width * 0.9}
                            height={dimens.height * 0.9}
                            xMax={xMax}
                            xMin={xMin}
                            yMax={yMax}
                            yMin={yMin}
                            squareGrid
                            hideContinuationLines
                            xAxis={null}
                            yAxis={null}
                          >
                            {({ mathToSvgX, mathToSvgY }) =>
                              digitalContent(mathToSvgX, mathToSvgY, state, setState, 'grid')
                            }
                          </Grid>
                          {cellSizeLabel && (
                            <View
                              style={{
                                flexDirection: 'row',
                                alignSelf: 'flex-start'
                              }}
                            >
                              <Svg width={20} height={squareDimens}>
                                <Path
                                  d={'M10,0 L5,10 L15,10 Z'}
                                  fill={isPdf ? colors.black : colors.prussianBlue}
                                />
                                <Path
                                  d={`M10,5 L10,${squareDimens - 5}`}
                                  stroke={isPdf ? colors.black : colors.prussianBlue}
                                  strokeWidth={isPdf ? 4 : 2}
                                />
                                <Path
                                  d={`M10,${squareDimens}
                                    L5,${squareDimens - 10}
                                    L15,${squareDimens - 10}
                                    Z`}
                                  fill={isPdf ? colors.black : colors.prussianBlue}
                                />
                              </Svg>
                              <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
                            </View>
                          )}
                        </View>
                      </View>
                    );
                  }}
                </MeasureView>
              )}
            </>
          }
        />
      )}
    </StateTreeLeaf>
  );
}

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return StyleSheet.create({
    cellLabel: {
      fontSize: displayMode === 'digital' ? 20 : 40,
      alignSelf: 'center',
      lineHeight: displayMode === 'digital' ? 20 : 40
    }
  });
};
