import { TermContent } from './Term';
import { yearColors } from '../theme/colors';
import { YearKey } from '../i18n/custom-types';

export type YearId = { year: YearKey };

export interface YearContent {
  year: YearKey;
  terms: TermContent[];
}

export function newYearContent(params: { year: YearKey; terms: TermContent[] }): YearContent {
  return params;
}

export const YEAR_COLORS = new Map([
  ['Year3', yearColors.year3],
  ['Year4', yearColors.year4],
  ['Year5', yearColors.year5],
  ['Year6', yearColors.year6]
]);
